import React from 'react';
import { Button, Container, Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {
    saveFellowshipApplication,
    getFellowshipApplicationCount,
    getFellowshipApplicationById,
    updateFellowshipApplication,
    getFellowshipApplicationByRegNo
} from './services';
import Spinner from './Spinner';

function getInitialState() {
    return {
        fellowshipProgramme: "",
        applicantName: '',
        gender: '',
        dateofbirth: '',
        age: '',
        community: '',
        religion: '',
        nationality: '',
        fatherName: '',
        residentialaddress: '',
        officeaddress: '',
        aadharNo: '',
        mobileNo: '',
        mailid: '',
        officialmailid: '',
        officialphoneno: '',
        submit: false,
        userName: '',
        paymentStatus: '',
        applicationStatus: '',
        applicationNo: '',
        academicQualification: [
            {
                qualification: '',
                school_college: '',
                board_university: '',
                month_year_passing: '',
                subject: '',
                percentagemark: '',
                class_rank: ''
            }
        ],
        teachingExperience: [
            {
                teachExpDesignation: '',
                teachExpInstitution: '',
                teachExpFromDate: '',
                teachExpToDate: ''

            }
        ],
        uploadDocuments: [
            {
                documentName: '',
                filePath: ''
            }
        ],
        masterDegreeQualifiedSubject: '',
        degreeAwardedBy: '',
        othersDegreeAwardedBy: '',
        spinner: false,
        edit: false
    }
};

function FellowshipApplication() {
    const navigate = useNavigate();
    const [state, setState] = React.useState(getInitialState());
    const [file, Setfile] = React.useState();
    const [photoSrc, setPhotoSrc] = React.useState('');
    const regiNoBegin = 1000;

    const goToDashboard = () => {
        localStorage.removeItem("_id");
        localStorage.removeItem("regNo");
        navigate("/dashboard");
    };

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    React.useEffect(() => {
        const id = localStorage.getItem('_id');
        if (id) {
            getApplicationDetails(id);
        }
    }, []);

    async function getApplicationDetails(id) {
        const result = await getFellowshipApplicationById({ "_id": id });
        setState(result.data);
        setState((prevState) => ({
            ...prevState,
            edit: true
        }));
    };

    async function getApplicationDetailsByRegNo(regNo) {
        const result = await getFellowshipApplicationByRegNo(regNo);
        setState(result.data);
        setState((prevState) => ({
            ...prevState,
            edit: true
        }));
    };

    React.useEffect(() => {
        const regNo = localStorage.getItem('regNo');
        if (regNo) {
            getApplicationDetailsByRegNo({ "regNo": regNo });
        }
    }, []);

    const onChangeInput = (event) => {
        const { target: { name, value } } = event;

        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const onChangeAcademicQualification = (index, event) => {
        const { target: { name, value } } = event;
        setState((prevState) => {
            const updateAcademicQualification = [...prevState.academicQualification];
            updateAcademicQualification[index][name] = value;

            return {
                ...prevState,
                academicQualification: updateAcademicQualification
            };
        });
    };

    const addNewRow = () => {
        const newRow = {
            qualification: '',
            school_college: '',
            board_university: '',
            month_year_passing: '',
            subject: '',
            percentagemark: '',
            class_rank: ''
        };

        setState((prevState) => ({
            ...prevState,
            academicQualification: [...prevState.academicQualification, newRow]
        }));
    };

    const deleteRow = (index) => {
        const deleteRow = [...state.academicQualification];
        deleteRow.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            academicQualification: deleteRow
        }));
    };

    const onChangeTeachingExperience = (index, event) => {
        const { target: { name, value } } = event;
        setState((prevState) => {
            const updateteachingExperience = [...prevState.teachingExperience];
            updateteachingExperience[index][name] = value;

            return {
                ...prevState,
                teachingExperience: updateteachingExperience
            };
        });
    };


    const addNewTeachingExperienceRow = () => {
        const newRow = {
            teachExpDesignation: '',
            teachExpInstitution: '',
            teachExpFromDate: '',
            teachExpToDate: ''
        };

        setState((prevState) => ({
            ...prevState,
            teachingExperience: [...prevState.teachingExperience, newRow]
        }));
    };

    const deleteTeachingExperienceRow = (index) => {
        const deleteRow = [...state.teachingExperience];
        deleteRow.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            teachingExperience: deleteRow
        }));
    };

    const handlePhotoInputChange = (event) => {
        const { target: { files } } = event;
        if (!files[0]) {
            return;
        }

        Setfile(files[0]);
    };

    React.useEffect(() => {
        if (file) {
            setPhotoSrc(URL.createObjectURL(file));
        }
    }, [file]);

    React.useEffect(() => {
        getAgeAsOn31stDec();
    }, [state.dateofbirth]);

    function getAgeAsOn31stDec() {
        const dec31st = new Date(2024, 11, 31, 23, 59, 59, 999);
        const dobDate = new Date(state.dateofbirth);
        const diffInMs = dec31st.getTime() - dobDate.getTime();
        const diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365);
        const calculateAge = Math.floor(diffInYears);
        setState((prevState) => ({
            ...prevState,
            age: calculateAge
        }));
    };

    const SaveandNext = async () => {
        try {
            if (state.edit === false) {
                const count = await getFellowshipApplicationCount();
                setState((prevState) => ({
                    ...prevState,
                    userName: localStorage.getItem("username"),
                    paymentStatus: "Pending",
                    applicationStatus: "Pending",
                    applicationNo: `F${regiNoBegin + count.result + 1}`,
                    submit: true,
                    spinner: true
                }));
            }
            if (state.edit === true) {
                setState((prevState) => ({
                    ...prevState,
                    _id: localStorage.getItem('_id'),
                    submit: true,
                    spinner: true
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        if (state.submit) {
            if (state.edit === false) {
                save();
            }
            if (state.edit === true) {
                update();
            };
        }
    }, [state.submit]);

    const save = async () => {
        const formData = new FormData();
        formData.append('details', JSON.stringify(state));
        formData.append('file', file);
        await saveFellowshipApplication(formData);
        localStorage.setItem("regNo", state.applicationNo);
        setState((prevState) => ({
            ...prevState,
            submit: false,
            spinner: false
        }));
        navigate("/fellowshipdocumentsupload");
    };

    const update = async () => {
        try {
            await updateFellowshipApplication(state);
            setState((prevState) => ({
                ...prevState,
                submit: false,
                spinner: false
            }));
            navigate("/fellowshipdocumentsupload");
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div>
                {state.spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row className='mt-4' style={{ width: "100%" }}>
                    <Col>
                        <Button variant="primary" type="button"
                            onClick={goToDashboard}
                        >
                            Go To Dashboard
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                        <Button variant="primary" type="button"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-4 mb-5' >
                    <Card style={{ width: "100%", padding: 16 }}>
                        <Form>
                            <Row style={{ textAlign: "center", color: '#fd7e14' }}>
                                <Col><h5>APPLICATION FOR ADMISSION TO FELLOWSHIP IN ULTRASOUND GUIDED REGIONAL ANAESTHESIA PROGRAMME 2024</h5></Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm={10}>
                                    <Row className='mt-4'>
                                        <Col xs={12} sm={6}><strong>Fellowship Programme</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select name='fellowshipProgramme' onChange={onChangeInput} value={state.fellowshipProgramme}>
                                                <option value="Fellowship in Ultrasound Guided Regional Anaesthesia">Fellowship in Ultrasound Guided Regional Anaesthesia</option>
                                                <option value="Observership in Ultrasound Guided Regional Anaesthesia">Observership in Ultrasound Guided Regional Anaesthesia</option>
                                            </Form.Select>

                                        </Col>
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col xs={12} sm={6}><strong>Name of the Applicant</strong><br />
                                            <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(in P.G. Degree Certificate)</span>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                name='applicantName'
                                                value={state.applicantName}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={2}>
                                    <div style={{ width: "112px", height: "152px", border: "1px solid" }}>
                                        <img src={photoSrc} width={110} height={150} alt='photo'></img>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col><strong>Photo</strong></Col>
                                <Col>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handlePhotoInputChange}
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Gender</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="Male"
                                            type="radio"
                                            id="option-1"
                                            name="gender"
                                            value="Male"
                                            checked={state.gender === 'Male'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Female"
                                            type="radio"
                                            id="option-2"
                                            name="gender"
                                            value="Female"
                                            checked={state.gender === 'Female'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Third Gender"
                                            type="radio"
                                            id="option-2"
                                            name="gender"
                                            value="Third Gender"
                                            checked={state.gender === 'Third Gender'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Date of Birth</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='Date'
                                        name='dateofbirth'
                                        value={state.dateofbirth}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Age</strong><br />
                                    <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(as on 31 December)</span>
                                </Col>
                                <Col xs={12} sm={6}>{state.age}</Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Community</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="SC"
                                            type="radio"
                                            name="community"
                                            value="SC"
                                            checked={state.community === "SC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="ST"
                                            type="radio"
                                            name="community"
                                            value="ST"
                                            checked={state.community === "ST"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="MBC"
                                            type="radio"
                                            name="community"
                                            value="MBC"
                                            checked={state.community === "MBC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="BC"
                                            type="radio"
                                            name="community"
                                            value="BC"
                                            checked={state.community === "BC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="OBC"
                                            type="radio"
                                            name="community"
                                            value="OBC"
                                            checked={state.community === "OBC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="OC"
                                            type="radio"
                                            name="community"
                                            value="OC"
                                            checked={state.community === "OC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Religion</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select name='religion' onChange={onChangeInput} value={state.religion}>
                                        <option value="">Select Religion</option>
                                        <option value="Hindu">Hindu</option>
                                        <option value="Muslim">Muslim</option>
                                        <option value="Christian">Christian</option>
                                        <option value="Buddhist">Buddhist</option>
                                        <option value="Jain">Jain</option>
                                        <option value="Sikh">Sikh</option>
                                        <option value="Others">Others</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Nationality</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='nationality'
                                        value={state.nationality}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Name of Father / Guardian</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='fatherName'
                                        value={state.fatherName}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Residential Address</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='residentialaddress'
                                        as="textarea"
                                        value={state.residentialaddress}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Office/Institution Address</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='officeaddress'
                                        as="textarea"
                                        value={state.officeaddress}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Aadhar No</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='aadharNo'
                                        value={state.aadharNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Mobile No</strong></Col>
                                <Col xs={12} sm={6}>
                                    <InputGroup>
                                        <InputGroup.Text id="mobile-prefix">+91</InputGroup.Text>
                                        <Form.Control
                                            name='mobileNo'
                                            value={state.mobileNo}
                                            type="tel"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            maxLength="10"
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </InputGroup>

                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Mail Id</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='email'
                                        name='mailid'
                                        value={state.mailid}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Official Mail Id</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='email'
                                        name='officialmailid'
                                        value={state.officialmailid}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>Official Phone No</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='officialphoneno'
                                        value={state.officialphoneno}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col><strong>Academic Qualification (UG to M.Phil)</strong></Col>
                            </Row>

                            <Row className="mt-3" >
                                <Col>Qualification</Col>
                                <Col>School/College</Col>
                                <Col>Board/University</Col>
                                <Col>Month & Year of Passing</Col>
                                <Col>subject</Col>
                                <Col>% of Mark</Col>
                                <Col>Class/Rank</Col>
                                <Col></Col>
                            </Row>
                            {
                                state.academicQualification.map((row, index,) => (
                                    <Row className="mt-3" key={`${index}_phd`}>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='qualification'
                                                value={row.qualification}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='school_college'
                                                value={row.school_college}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='board_university'
                                                value={row.board_university}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='month_year_passing'
                                                value={row.month_year_passing}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='subject'
                                                value={row.subject}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='percentagemark'
                                                value={row.percentagemark}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='class_rank'
                                                value={row.class_rank}
                                                onChange={(event) => onChangeAcademicQualification(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            {
                                                (state.academicQualification.length - 1) === index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', width: '90px' }}
                                                        className="w-70"
                                                        onClick={addNewRow}
                                                    >
                                                        + Row
                                                    </Button>
                                                )
                                            }
                                            {
                                                (state.academicQualification.length - 1) !== index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', backgroundColor: 'red', width: '90px' }}
                                                        className="w-70"
                                                        onClick={() => deleteRow(index)}
                                                    >
                                                        - Row
                                                    </Button>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                ))
                            }

                            <Row className="mt-3">
                                <Col><strong>Professional/Teaching Experience</strong></Col>
                            </Row>

                            <Row className="mt-3" >
                                <Col>Designation</Col>
                                <Col>Institution</Col>
                                <Col>From Date</Col>
                                <Col>To Date</Col>
                                <Col></Col>
                            </Row>
                            {
                                state.teachingExperience.map((row, index) => (
                                    <Row className="mt-3" key={`${index}_teachexp`}>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='teachExpDesignation'
                                                value={row.teachExpDesignation}
                                                onChange={(event) => onChangeTeachingExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='text'
                                                name='teachExpInstitution'
                                                value={row.teachExpInstitution}
                                                onChange={(event) => onChangeTeachingExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='date'
                                                name='teachExpFromDate'
                                                value={row.teachExpFromDate}
                                                onChange={(event) => onChangeTeachingExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='date'
                                                name='teachExpToDate'
                                                value={row.teachExpToDate}
                                                onChange={(event) => onChangeTeachingExperience(index, event)}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            {
                                                (state.teachingExperience.length - 1) === index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', width: '130px' }}
                                                        className="w-70"
                                                        onClick={addNewTeachingExperienceRow}
                                                    >
                                                        Add New Row
                                                    </Button>
                                                )
                                            }
                                            {
                                                (state.teachingExperience.length - 1) !== index && (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        style={{ alignItems: 'center', backgroundColor: 'red', width: '130px' }}
                                                        className="w-70"
                                                        onClick={() => deleteTeachingExperienceRow(index)}
                                                    >
                                                        Delete Row
                                                    </Button>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                ))
                            }

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>The subject in which the candidate has qualified for the</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select onChange={onChangeInput} name='masterDegreeQualifiedSubject' value={state.masterDegreeQualifiedSubject}>
                                        <option value="">Please Select</option>
                                        <option value="Master's Degree">Master's Degree</option>
                                        <option value="Doctoral Degree">Doctoral Degree</option>
                                        <option value="Post Doctoral Degree">Post Doctoral Degree</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col xs={12} sm={6}><strong>The above master's Degree was awarded by</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select name='degreeAwardedBy' onChange={onChangeInput} value={state.degreeAwardedBy}>
                                        <option value="">Please Select</option>
                                        <option value="Faculty of Management">Faculty of Management</option>
                                        <option value="Faculty of Engineering">Faculty of Engineering</option>
                                        <option value="Faculty of Dentistry">Faculty of Dentistry</option>
                                        <option value="Faculty of Nursing">Faculty of Nursing</option>
                                        <option value="Faculty of Science">Faculty of Science</option>
                                        <option value="Faculty of Allied Health Sciences">Faculty of Allied Health Sciences</option>
                                        <option value="Faculty of Medicine">Faculty of Medicine</option>
                                        <option value="Others">Others</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            {
                                state.degreeAwardedBy === "Others" &&
                                <Row className="mt-3">
                                    <Col xs={12} sm={6}><strong>If Others, Mention degree awarded by</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='othersDegreeAwardedBy'
                                            value={state.othersDegreeAwardedBy}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }
                            <Row className='mt-3'>
                                <Col style={{ textAlign: "center" }}>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        style={{ alignItems: 'center', width: '150px' }}
                                        className="w-70"
                                        onClick={SaveandNext}
                                    >
                                        Save&Next
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default FellowshipApplication