import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import Card from 'react-bootstrap/Card';
import swal from 'sweetalert';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Spinner from './Spinner';
import {
    ApplicationList,
    DownloadApplication,
    DownloadHallTicket,
    getCourseName,
    getPhdApplicationList,
    getFellowshipApplicationList,
    getMBBSandBDSapplicationList,
    getMDMSapplicationList,
    getStudentRegistrationListForDashboard,
    downloadAllDeclarationsInPDF,
    getEDPapplicationListForDashboard,
    downloadEDPapplicationForm,
    get_MD_MS_MDS_StudentRegistrationList,
    get_MD_MCh_StudentRegistrationList,
    get_AHS_StudentRegistrationList,
    get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList,
    get_BPharm_StudentRegistrationList,
    get_BPT_MPT_StudentRegistrationList,
    get_MSc_Medical_MBA_MPH_StudentRegistrationList,
    studentRegistration_AHS_DownloadDeclaration,
    studentRegistration_BPharm_DownloadDeclaration,
    studentRegistrationMD_MS_MDS_DownloadDeclaration,
    studentRegistration_DM_MCh_DownloadDeclaration,
    studentRegistration_BSc_PBBSc_MSc_NPCC_DownloadDeclaration,
    studentRegistration_BPT_MPT_DownloadDeclaration,
    studentRegistration_MSc_Medical_MBA_MPH_downloadAllDeclarations

} from './services';
import Table from 'react-bootstrap/Table';
const callLetterCourses =
    [
        "Poat Graduate Diploma In Music Therapy",
        "M.Sc.Medical Music Therapy",
        "Post Graduate Certificate In Yoga Therapy",
        "Post Graduate Diploma In Yoga Therapy",
        "M.Sc.Yoga Therapy",
        "Masters In Health Professions Education",
        "B.Sc. in Biomedical Sciences",
        "B.Sc.(Hons) in Biomedical Sciences",
        "M.Sc. in Biomedical Sciences",
        "M.Sc. + Ph.D. in Biomedical Sciences",
        "PG Diploma in Bio-Industrial Applications",
        "M.Sc.Medical MicroBiology",
        "M.Sc.Medical Biochemistry",
        "M.Sc.Nurse Practitioner In Critical Care Nursing",
        "Post Graduate Certificate In Health  Science Library Information Technology",
        "Post Graduate Diploma In Pharmacogenomics",
        "Post Graduate Diploma In Good Clinic Laboratory Practices",
        "M.Sc.Medical Laboratory Technology In Clinical Chemistry",
        "M.Sc.Clinical Nutrition",
        "M.Sc.Medical Laboratory Technology In Pathology",
        "M.Sc.Echocardiography",
        "M.Sc.Cardiac Catheterization And Intervention",
        "M.Sc.Medical Imaging Technology"
    ];

function getInitialState() {
    return {
        applicationList: [],
        phdApplicationList: [],
        fellowshipApplicationList: [],
        MBBSandBDSapplicationList: [],
        MDMSapplicationList: [],
        loading: true,
        StudentRegistrationList: [],
        EDPapplicationList: [],
        MD_MS_MDS_StudentRegistrationList: [],
        DM_MCh_StudentRegistrationList: [],
        AHS_StudentRegistrationList: [],
        BSc_PBBSc_MSc_NPCC_StudentRegistrationList: [],
        BPharm_StudentRegistrationList: [],
        BPT_MPT_StudentRegistrationList: [],
        MSc_Medical_MBA_MPH_StudentRegistrationList: []
    }
}

function Dashboard() {
    const navigate = useNavigate();
    const [state, setState] = React.useState(getInitialState());
    const [spinner, setspinner] = React.useState(false);

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const goToWebsite = () => {
        window.open('https://sbvu.ac.in/provisional-selection-list/', '_blank');
    };

    async function getApplicationList() {
        try {
            const result = await ApplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    applicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function GetPhdApplicationList() {
        try {
            const result = await getPhdApplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    phdApplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const GetMBBSandBDSapplicationList = async () => {
        try {
            const result = await getMBBSandBDSapplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    MBBSandBDSapplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function GetFellowshipApplicationList() {
        try {
            const result = await getFellowshipApplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    fellowshipApplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function GetMDMSApplicationList() {
        try {
            const result = await getMDMSapplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    MDMSapplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function GetStudentRegistrationList() {
        try {
            const result = await getStudentRegistrationListForDashboard({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const getEDPapplicationList = async () => {
        try {
            const result = await getEDPapplicationListForDashboard({ "username": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    EDPapplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        var user = localStorage.getItem('username');
        if (!user) {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/');
        }
        else {
            getApplicationList();
            GetPhdApplicationList();
            GetFellowshipApplicationList();
            GetMBBSandBDSapplicationList();
            GetMDMSApplicationList();
            GetStudentRegistrationList();
            getEDPapplicationList();
            Get_MD_MS_MDS_StudentRegistrationList();
            Get_MD_MCh_StudentRegistrationList();
            Get_AHS_StudentRegistrationList();
            Get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList();
            Get_BPharm_StudentRegistrationList();
            Get_BPT_MPT_StudentRegistrationList();
            Get_MSc_Medical_MBA_MPH_StudentRegistrationList();
        }
    }, []);

    const Get_MD_MS_MDS_StudentRegistrationList = async () => {
        try {
            const result = await get_MD_MS_MDS_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    MD_MS_MDS_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_MD_MCh_StudentRegistrationList = async () => {
        try {
            const result = await get_MD_MCh_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    DM_MCh_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_AHS_StudentRegistrationList = async () => {
        try {
            const result = await get_AHS_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    AHS_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList = async () => {
        try {
            const result = await get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    BSc_PBBSc_MSc_NPCC_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_BPharm_StudentRegistrationList = async () => {
        try {
            const result = await get_BPharm_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    BPharm_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_BPT_MPT_StudentRegistrationList = async () => {
        try {
            const result = await get_BPT_MPT_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    BPT_MPT_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_MSc_Medical_MBA_MPH_StudentRegistrationList = async () => {
        try {
            const result = await get_MSc_Medical_MBA_MPH_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    MSc_Medical_MBA_MPH_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const ApplicationEdit = (data) => {
        localStorage.setItem("_id", data._id);
        navigate("/form");
    };

    const newApplication = () => {
        navigate("/applicationselection");
    };

    const ViewApplication = (data) => {
        localStorage.setItem("_id", data._id);
        navigate("/viewapplication");
    };

    const downloadApplicationData = async (data) => {
        try {
            const response = await DownloadApplication(data);
            const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', data.applicationNo + '.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        catch (err) {
            console.log(err);
        }

    };

    const downloadHallTicket = async (data) => {
        try {
            const course = await getCourseName({ "_id": data._id });
            if (callLetterCourses.indexOf(course.courseName) > -1) {

                const response = await DownloadHallTicket({ "_id": data._id });
                const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Call Letter.pdf');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
            else {

                const response = await DownloadHallTicket({ "_id": data._id });
                const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Hall Ticket.pdf');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await downloadAllDeclarationsInPDF({ "registrationNo": data.registrationNo });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${data.registrationNo}.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    if (state.loading) {
        return (
            <Row style={{ width: '100%', textAlign: 'center' }}>
                <Col>
                    Please wait...
                </Col>
            </Row>
        );
    };

    const phdApplicationEdit = (object) => {
        localStorage.setItem("_id", object._id);
        localStorage.setItem("regNo", object.applicationNo);
        navigate("/phdapplication");
    };

    const fellowshipApplicationEdit = (object) => {
        localStorage.setItem("_id", object._id);
        localStorage.setItem("regNo", object.applicationNo);
        navigate("/fellowshipapplication");
    };

    const MBBSandBDSapplicationEdit = (object) => {
        localStorage.setItem("applicationNo", object.applicationNo);
        localStorage.setItem("institute", object.institute);
        navigate("/mbbsandbdsapplication");
    };

    const MDMSapplicationEdit = (object) => {
        localStorage.setItem("applicationNo", object.applicationNo);
        localStorage.setItem("institute", object.institute);
        navigate("/mdmsapplication");
    };

    const goToPay = (id) => {
        localStorage.setItem('id', id);
        navigate("/EDP_fellowship_payment");
    }

    const downloadEDPapplication = async (data) => {
        try {
            setspinner(true);
            const response = await downloadEDPapplicationForm({ 'id': data._id });
            const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', data.applicationNo + '.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    const MD_MS_MDS_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistrationMD_MS_MDS_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const MD_MS_MDS_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/studentregistration_MD_MS_MDS");
    };

    const DM_MCH_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/studentregistration_DM_MCh");
    };

    const AHS_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_AHS");
    };

    const BSc_PBBSc_MSc_NPCC_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_BSc_PBBSc_MSc_NPCC_nursing");
    };

    const BPharm_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_BPharm");
    };

    const BPT_MPT_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_BPT_MPT");
    };

    const MSc_Medical_MBA_MPH_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_MSc_Medical_MBA_MPH");
    };

    const DM_MCH_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_DM_MCh_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const BPT_AHS_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_AHS_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const BSc_PBBSc_MSc_NPCC_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_BSc_PBBSc_MSc_NPCC_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const BPharm_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_BPharm_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const BPT_MPT_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_BPT_MPT_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const MSc_Medical_MBA_MPH_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_MSc_Medical_MBA_MPH_downloadAllDeclarations({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
            localStorage.removeItem("id");
            localStorage.removeItem("updateDoc");
            navigate("/dashboard");
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row style={{ width: '100%' }} className='mt-2'>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ textAlign: 'start' }}>
                        {/*      <Button variant="primary" type="button"
                            style={{ marginTop: '3px', marginRight: 0 }}
                            onClick={goToWebsite}
                        >
                            Provisional Selection List
                        </Button> */}
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ textAlign: 'end' }}>
                        <Button variant="primary" type="button"
                            style={{ marginTop: '3px', marginRight: 0 }}
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row>
            </Container>

            <div>
                <Card style={{ width: '80%', margin: '20px 10% 20px 10%', overflowX: 'scroll' }}>
                    <Container>
                        {
                            state.applicationList.length === 0 && state.phdApplicationList.length === 0 &&
                            state.fellowshipApplicationList.length === 0 && state.MDMSapplicationList.length === 0 &&
                            state.MBBSandBDSapplicationList.length === 0 && state.EDPapplicationList.length === 0 &&
                            state.MD_MS_MDS_StudentRegistrationList.length === 0 &&
                            state.DM_MCh_StudentRegistrationList.length === 0 && state.AHS_StudentRegistrationList.length === 0 &&
                            state.BSc_PBBSc_MSc_NPCC_StudentRegistrationList.length === 0 && state.BPharm_StudentRegistrationList.length === 0 &&
                            state.BPT_MPT_StudentRegistrationList.length === 0 && state.MSc_Medical_MBA_MPH_StudentRegistrationList.length === 0
                            &&
                            <div>
                                <Row className="justify-content-center" style={{ marginTop: '75px' }}>
                                    <h1 className="text-center font-weight-bold mb-4">Welcome to Sri Balaji Vidyapeeth</h1>
                                    <Button variant="primary" href="/applicationselection"
                                        className="rounded-pill"
                                        style={{ width: '200px' }}>
                                        Apply Online
                                    </Button>
                                </Row>
                            </div>
                        }
                        {
                            state.applicationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Application List</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Application No</th>
                                            <th>Verification Status</th>
                                            <th>Payment Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{
                                        state.applicationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.applicationNo}</td>
                                                <td>{obj.applicationStatus}</td>
                                                <td>{obj.paymentStatus}</td>
                                                <td>
                                                    <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => ApplicationEdit(obj)}
                                                    >Edit
                                                    </Button>
                                                    <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => ViewApplication(obj)}
                                                    >View
                                                    </Button>
                                                    <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        disabled={obj.paymentStatus === "Pending"}
                                                        onClick={() => downloadApplicationData(obj)}
                                                    >Download
                                                    </Button>
                                                    <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        disabled={!obj.venue}
                                                        onClick={() => downloadHallTicket(obj)}
                                                    >Hall Ticket / Call Letter
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            state.phdApplicationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>phD Application List</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Application No</th>
                                            <th>Verification Status</th>
                                            <th>Payment Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{
                                        state.phdApplicationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.applicationNo}</td>
                                                <td>{obj.applicationStatus}</td>
                                                <td>{obj.paymentStatus}</td>
                                                <td>
                                                    <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => phdApplicationEdit(obj)}
                                                    >Edit
                                                    </Button>
                                                    {/*         <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => ViewApplication(obj)}
                                                    >View
                                                    </Button> */}
                                                    {/*       <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => downloadApplicationData(obj)}
                                                    >Download
                                                    </Button> */}
                                                    {/*         <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        disabled={true}
                                                        onClick={() => downloadHallTicket(obj)}
                                                    >Hall Ticket
                                                    </Button> */}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            state.fellowshipApplicationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Fellowship Application List</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Application No</th>
                                            <th>Verification Status</th>
                                            <th>Payment Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{
                                        state.fellowshipApplicationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.applicationNo}</td>
                                                <td>{obj.applicationStatus}</td>
                                                <td>{obj.paymentStatus}</td>
                                                <td>
                                                    <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => fellowshipApplicationEdit(obj)}
                                                    >Edit
                                                    </Button>
                                                    {/*        <Button variant="primary"
                                                                type="button"
                                                                size='sm'
                                                                style={{ marginRight: '10px' }}
                                                                disabled={true}
                                                            //onClick={() => ViewApplication(obj)}
                                                            >View
                                                            </Button>
                                                            <Button variant="primary"
                                                                type="button"
                                                                size='sm'
                                                                style={{ marginRight: '10px' }}
                                                                disabled={true}
                                                            //onClick={() => downloadApplicationData(obj)}
                                                            >Download
                                                            </Button>
                                                            <Button variant="primary"
                                                                type="button"
                                                                size='sm'
                                                                disabled={true}
                                                            //onClick={() => downloadHallTicket(obj)}
                                                            >Hall Ticket
                                                            </Button> */}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            state.MBBSandBDSapplicationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>MBBS and BDS Application List</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Application No</th>
                                            <th>Verification Status</th>
                                            <th>Payment Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{
                                        state.MBBSandBDSapplicationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.applicationNo}</td>
                                                <td>{obj.applicationStatus}</td>
                                                <td>{obj.paymentStatus}</td>
                                                <td>
                                                    {/*      <Button variant="primary"
                                                                type="button"
                                                                size='sm'
                                                                style={{ marginRight: '10px' }}
                                                                onClick={() => MBBSandBDSapplicationEdit(obj)}
                                                            >Edit
                                                            </Button> */}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            state.MDMSapplicationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>MDMS Application List</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Application No</th>
                                            <th>Verification Status</th>
                                            <th>Payment Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>{
                                        state.MDMSapplicationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.applicationNo}</td>
                                                <td>{obj.applicationStatus}</td>
                                                <td>{obj.paymentStatus}</td>
                                                <td>
                                                    {/*        <Button variant="primary"
                                                                type="button"
                                                                size='sm'
                                                                style={{ marginRight: '10px' }}
                                                                onClick={() => MDMSapplicationEdit(obj)}
                                                            >Edit
                                                            </Button> */}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            </div>

                        }
                        {
                            state.StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>MBBS and BDS Student Registration Application</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.registrationNo}</td>
                                                <td>
                                                    <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            state.EDPapplicationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>EDP / Fellowship Application</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Application No</th>
                                            <th>Payment Status</th>
                                            <th>Application Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.EDPapplicationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.applicationNo}</td>
                                                <td>{obj.paymentStatus}</td>
                                                <td>{obj.applicationStatus}</td>
                                                <td style={{ width: "100px" }}>
                                                    <Row>
                                                        <Col>
                                                            <Icon.CashCoin
                                                                color={obj.paymentStatus === "Success" ? "gray" : "blue"}
                                                                size={25}
                                                                type="button"
                                                                onClick={obj.paymentStatus === "Success" ? null : () => goToPay(obj._id)}
                                                                disabled={obj.paymentStatus === "Success"}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Pay"
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Icon.Download
                                                                color="blue"
                                                                size={25}
                                                                type="button"
                                                                onClick={() => downloadEDPapplication(obj)}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Download Form"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.MD_MS_MDS_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for MD / MS / MDS</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.MD_MS_MDS_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => MD_MS_MDS_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => MD_MS_MDS_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.DM_MCh_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>DM / MCH Student Registration Application</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.DM_MCh_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => DM_MCH_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => DM_MCH_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.AHS_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for AHS</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.AHS_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => BPT_AHS_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => AHS_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }


                        {
                            state.BSc_PBBSc_MSc_NPCC_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for B.Sc / P.B.B.Sc / M.Sc / NPCC</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.BSc_PBBSc_MSc_NPCC_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => BSc_PBBSc_MSc_NPCC_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => BSc_PBBSc_MSc_NPCC_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.BPharm_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for BPharm</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.BPharm_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => BPharm_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => BPharm_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.BPT_MPT_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for Biomedical Sciences and BPT / MPT</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.BPT_MPT_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => BPT_MPT_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => BPT_MPT_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.MSc_Medical_MBA_MPH_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for M.Sc (Medical) / MBA / MPH / Music & Yoga Therapy.</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.MSc_Medical_MBA_MPH_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => MSc_Medical_MBA_MPH_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => MSc_Medical_MBA_MPH_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {(state.applicationList.length > 0 || state.phdApplicationList.length > 0 ||
                            state.fellowshipApplicationList.length > 0 || state.MDMSapplicationList.length > 0 ||
                            state.MBBSandBDSapplicationList.length > 0 || state.StudentRegistrationList.length > 0 ||
                            state.EDPapplicationList.length > 0 || state.MD_MS_MDS_StudentRegistrationList.length > 0 ||
                            state.DM_MCh_StudentRegistrationList.length > 0 || state.AHS_StudentRegistrationList.length > 0 ||
                            state.BSc_PBBSc_MSc_NPCC_StudentRegistrationList.length > 0 || state.BPharm_StudentRegistrationList.length > 0 ||
                            state.BPT_MPT_StudentRegistrationList.length > 0 || state.MSc_Medical_MBA_MPH_StudentRegistrationList.length > 0) &&
                            <Row className='mt-4'>
                                <Col style={{ textAlign: 'end' }}>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        size='sm'
                                        style={{ width: '180px' }}
                                        onClick={newApplication}
                                    >Start New Application
                                    </Button>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                {/* <p><strong>For any query:</strong>&nbsp;Contact 0413-2616700  Ext.No: 151, 122 Or mail to <a href="mailto:ar@sbvu.ac.in">ar@sbvu.ac.in</a></p> */}
                                <p><strong>For any query:</strong>&nbsp;Contact 0413 – 2611807, 808, 809, 99942 67920 or mail to <a href="mailto:admissions@sbvu.ac.in">admissions@sbvu.ac.in</a></p>
                                <p><strong>For any technical query:</strong>&nbsp; Contact 0413 2616707 or mail to <a href="mailto:programmer@mgmcri.ac.in">programmer@mgmcri.ac.in</a></p>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </div>
        </>
    )
}

export default Dashboard