import './App.css';
import Login from './Login';
import Register from './Register';
import BasicDetails from './BasicDetails';
import Dashboard from './Dashboard';
import { Routes, Route, } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewApplication from './ViewApplication';
import AdminLogin from './AdminLogin';
import ApplicationApprove from './ApplicationApprove';
import ExamSchedule from './ExamSchedule';
import Forgotpassword from './Forgotpassword';
import ApplicationSelection from './ApplicationSelection';
import PhdApplication from './PhdApplication';
import FellowshipApplication from './fellowshipApplication';
import PhdDocumentsUpload from './PhdDocumentsUpload';
import PhdPayment from './PhdPayment';
import FellowshipDocumentsUpload from './FellowshipDocumentsUpload';
import FellowshipPayment from './FellowshipPayment';
import PhdAdmissionController from './PhdAdmissionController';
import FellowshipAdmissionController from './FellowshipAdmissionController';
import ApplicationController from './ApplicationController';
import MBBSandBDSapplication from './MBBSandBDSapplication';
import MBBSandBDSdocumentsUpload from './MBBS_BDS_DocumentsUpload';
import MBBSandBDSPayment from './MBBSandBDSPayment';
import MDMSapplication from './MDMSapplication';
import MDMSdocumentsUpload from './MDMSdocumentsUpload';
import MDMSpayment from './MDMSpayment';
import MBBSandBDSadmissionController from './MBBSandBDSadmissionController';
import MDMSadmissionController from './MDMSadmissionController';
import MBBSandBDSstudentRegistration from './MBBSandBDSstudentRegistration';
import MBBSandBDSstudentRegistrationDocumentsUpload from './MBBSandBDSstudentRegistrationDocumentsUpload';
import DownloadAllDeclarations from './DownloadAllDeclarations';
import EDP_FellowshipApplication from './EDP_FellowshipApplication';
import EDP_FellowshipDocumentsUpload from './EDP_FellowshipDocumentsUpload';
import EDP_FellowshipPayment from './EDP_FellowshipPayment';
import EDP_FellowshipAdmissionController from './EDP_FellowshipAdmissionController';
import ApplicationsBulkDownload from './applicationsBulkDownload';
import StudentRegistration_MD_MS_MDS from '../src/StudentRegistration_MD_MS_MDS';
import StudentRegistration_DM_MCh from '../src/StudentRegistration_DM_MCh';
import StudentRegistration_BSc_PBBSc_MSc_NPCC_Nursing from './StudentRegistration_B.Sc_P.B.B.Sc_M.Sc_NPCC_Nursing';
import StudentRegistration_BPharm from './StudentRegistration_B.Pharm';
import StudentRegistration_AHS from './StudentRegistration_AHS';
import StudentRegistration_BPT_MPT from './StudentRegistration_BPT_MPT';
import PaymentReminder from './PaymentReminder';
import AdminDashboard from './AdminDashboard';
import StudentRegistration_MSc_Medical_MBA_MPH from './StudentRegistration_MSc_Medical_MBA_MPH';


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/register" element={<Register />} />
        <Route path="/form" element={<BasicDetails />} />
        <Route path="/viewapplication" element={<ViewApplication />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/applicationapprove" element={<ApplicationApprove />} />
        <Route path="/examschedule" element={<ExamSchedule />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/applicationselection" element={<ApplicationSelection />} />
        <Route path="/phdapplication" element={<PhdApplication />} />
        <Route path="/fellowshipapplication" element={<FellowshipApplication />} />
        <Route path="/phdDocumentsUpload" element={<PhdDocumentsUpload />} />
        <Route path="/phdPayment" element={<PhdPayment />} />
        <Route path="/fellowshipdocumentsupload" element={<FellowshipDocumentsUpload />} />
        <Route path="/fellowshippayment" element={<FellowshipPayment />} />
        <Route path="/phdadmissioncontroller" element={<PhdAdmissionController />} />
        <Route path="/fellowshipadmissioncontroller" element={<FellowshipAdmissionController />} />
        <Route path="/applicationcontroller" element={<ApplicationController />} />
        <Route path="/mbbsandbdsapplication" element={<MBBSandBDSapplication />} />
        <Route path="/mbbsandbdsdocumentsupload" element={<MBBSandBDSdocumentsUpload />} />
        <Route path="/mbbsandbdspayment" element={<MBBSandBDSPayment />} />
        <Route path="/mdmsapplication" element={<MDMSapplication />} />
        <Route path="/mdmsdocumentsupload" element={<MDMSdocumentsUpload />} />
        <Route path="/mdmspayment" element={<MDMSpayment />} />
        <Route path="/mbbsandbdsadmissioncontroller" element={<MBBSandBDSadmissionController />} />
        <Route path="/mdmsadmissioncontroller" element={<MDMSadmissionController />} />
        <Route path="/mbbsandbdsstudentregistration" element={<MBBSandBDSstudentRegistration />} />
        <Route path="/mbbsandbdsstudentregistrationdocumentsupload" element={<MBBSandBDSstudentRegistrationDocumentsUpload />} />
        <Route path="/downloadAllDeclarationsinPDF" element={<DownloadAllDeclarations />} />
        <Route path="/EDP_fellowship_application" element={<EDP_FellowshipApplication />} />
        <Route path="/EDP_fellowship_documents_upload" element={<EDP_FellowshipDocumentsUpload />} />
        <Route path="/EDP_fellowship_payment" element={<EDP_FellowshipPayment />} />
        <Route path="/edpfellowshipapplicationcontroller" element={<EDP_FellowshipAdmissionController />} />
        <Route path="/applicationsbulkdownload" element={<ApplicationsBulkDownload />} />
        <Route path="/studentregistration_MD_MS_MDS" element={<StudentRegistration_MD_MS_MDS />} />
        <Route path="/studentregistration_DM_MCh" element={< StudentRegistration_DM_MCh />} />
        <Route path="/student_registration_BSc_PBBSc_MSc_NPCC_nursing" element={< StudentRegistration_BSc_PBBSc_MSc_NPCC_Nursing />} />
        <Route path="/student_registration_BPharm" element={< StudentRegistration_BPharm />} />
        <Route path="/student_registration_AHS" element={< StudentRegistration_AHS />} />
        <Route path="/student_registration_BPT_MPT" element={< StudentRegistration_BPT_MPT />} />
        <Route path="/paymentReminder" element={< PaymentReminder />} />
        <Route path="/adminDashboard" element={< AdminDashboard />} />
        <Route path="/student_registration_MSc_Medical_MBA_MPH" element={< StudentRegistration_MSc_Medical_MBA_MPH />} />
      </Routes>
    </>
  );
}

export default App;
