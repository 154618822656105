import axios from 'axios';
const api = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_URL,
});

api.interceptors.response.use((response) => response.data);

const registerUser = (data) => api.post('/ahsadmission/register', data);

const login = (data) => api.post('/ahsadmission/login', data);

const ApplicationBasicDetails = (data) => api.post('/application/basicdetails', data);

const GetLastRecord = (data) => api.post('/application/get/last/record', data);

const UpdateAcademicData = (data) => api.post('/application/update/academic/data', data);

const updateBasicData = (data) => api.post('/update/basic/details', data);

const DocumentsUpload = (data) => api.post('/upload/documents', data);

const GetUploadedDocuments = (data) => api.post('/get/uploaded/documents', data);

const DeleteDocument = (data) => api.post('/delete/perticular/document', data);

const ApplicationList = (data) => api.post('/get/application/list', data);

const ApplicationData = (data) => api.post('/get/application/data', data);

const DownloadApplication = (data) => api.post('/download/application', data, { responseType: 'blob' });

const createRazorpayOrder = (data) => api.post('/create/razorpay/order', data);

const PaymentSuccessResponse = (data) => api.post('/payment/success/response', data);

const PaymentErrorResponse = (data) => api.post('/payment/error/response', data);

const GetApplicationsByInstitute = (data) => api.post('/get/application/by/institute', data);

const GetAllApplications = (data) => api.post('/get/all/applications', data);

const ApplicationVerification = (data) => api.post('/application/verification', data);

const GetAllVerifiedCount = () => api.post('/get/all/verified/count');

const InstituteWiseVerifiedCount = (data) => api.post('/institute/wise/verification/count', data);

const DataInXL = (data) => api.post('/download/data/xl', data);

const GetCourselistwithCount = (data) => api.post('/course/list/count', data);

const ExamScheduleUpdate = (data) => api.post('/exam/schedule', data);

const GetUnscheduledCount = (data) => api.post('/get/unscheduled/count', data);

const SaveRemark = (data) => api.post('/save/remark', data);

const ReminderMail = (data) => api.post('/send/reminder/mail', data);

const DownloadHallTicket = (data) => api.post('/download/hall/ticket', data, { responseType: 'blob' });

const getCourseName = (data) => api.post('/get/course/name', data);

const getInstitutionName = () => api.post('/get/institution/name');

const applicationRejected = (data) => api.post('/application/rejected', data);

const applicationNotEligible = (data) => api.post('/application/not/eligible', data);

const forgotpassword = (data) => api.post('/forgot/password', data);

const savePhdApplication = (data) => api.post('/save/phd/application', data);

const saveFellowshipApplication = (data) => api.post('/save/fellowfhip/application', data);

const getPhdApplicationCount = () => api.post('/get/phd/application/count');

const phdApplicationDocumentsUpload = (data) => api.post('/phd/application/documents/upload', data);

const getPhdUploadDocuments = (data) => api.post('/get/phd/upload/documents', data);

const deletePhdApplicationDoc = (data) => api.post('/delete/phd/application/doc', data);

const phdApplicationPaymentStatus = (data) => api.post('/phd/application/payment/status', data);

const getPhdApplicationData = (data) => api.post('/get/phd/application/data', data);

const phdPaymentSuccess = (data) => api.post('/phd/payment/success/response', data);

const phdPaymentError = (data) => api.post('/phd/payment/error/response', data);

const phdApplicationDownload = (data) => api.post('/phd/application/download/pdf', data, { responseType: 'blob' });

const getFellowshipApplicationCount = () => api.post('/get/fellowship/application/count');

const fellowshipApplicationDocumentsUpload = (data) => api.post('/fellowship/application/documents/upload', data);

const getFellowshipUploadedDocuments = (data) => api.post('/get/fellowship/uploaded/documents', data);

const deleteFellowshipApplicationDoc = (data) => api.post('/delete/fellowship/application/doc', data);

const fellowshipApplicationPaymentStatus = (data) => api.post('/fellowship/application/payment/status', data);

const getFellowshipApplicationData = (data) => api.post('/get/fellowship/application/data', data);

const fellowshipPaymentSuccess = (data) => api.post('/fellowship/payment/success', data);

const fellowshipPaymentError = (data) => api.post('/fellowship/payment/error', data);

const getPhdApplicationList = (data) => api.post('/get/phd/application/list', data);

const getFellowshipApplicationList = (data) => api.post('/get/fellowship/application/list', data);

const getPhdApplicationById = (data) => api.post('/get/phd/application/by/id', data);

const updatePhdApplication = (data) => api.post('/update/phd/application', data);

const getFellowshipApplicationById = (data) => api.post('/get/fellowship/application/by/id', data);

const updateFellowshipApplication = (data) => api.post('/update/fellowship/application', data);

const getFellowshipApplicationByRegNo = (data) => api.post('/get/fellowship/applicatiom/by/regno', data);

const getPhdApplicationByRegNo = (data) => api.post('/get/phd/application/by/regno', data);

const getPhdApplicationsData = () => api.post('/get/phd/applications');

const sentPhdApplicationRemark = (data) => api.post('/sent/phd/application/remark', data);

const phdApplicationRejected = (data) => api.post('/phd/application/rejected', data);

const phdApplicationNotEligible = (data) => api.post('/phd/application/not/eligible', data);

const phdApplicationVerified = (data) => api.post('/phd/application/verified', data);

const getAllFellowshipApplicationData = () => api.post('/get/all/fellowship/application/data');

const sentFellowshipApplicationRemark = (data) => api.post('/sent/fellowship/application/remark', data);

const fellowshipApplicationRejected = (data) => api.post('/fellowship/application/rejected', data);

const fellowshipApplicationNotEligible = (data) => api.post('/fellowship/application/not/eligible', data);

const fellowshipApplicationVerified = (data) => api.post('/fellowship/application/verified', data);

const fellowshipApplicationDownload = (data) => api.post('/fellowship/application/download/pdf', data, { responseType: 'blob' });

const getPaymentCustomerData = (data) => api.post('/get/payment/customer/data', data);

const updateApplicationPosition = (data) => api.post('/update/application/position', data);

const getAhsApplicationPosition = (data) => api.post('/get/ahs/application/position', data);

const getPhdApplicationPosition = (data) => api.post('/get/phd/application/position', data);

const getFellowshipApplicationPosition = (data) => api.post('/get/fellowship/application/position', data);

const exportFellowshipApplications = () => api.post('/export/fellowship/applications');

const exportPhdApplications = () => api.post('/export/phd/applications');

const saveMBBS_BDS_Application = (data) => api.post('/save/mbbs/bds/application', data);

const MBBS_BDS_DocumentsUpload = (data) => api.post('/mbbs/bds/documents/upload', data);

const MBBS_application_count_MGMCRI = () => api.post('/mbbs/application/count/mgmcri');

const MBBS_application_count_SSSMCRI = () => api.post('/mbbs/application/count/sssmcri');

const MBBS_application_count_IGIDS = () => api.post('/mbbs/application/count/igids');

const get_MBBS_uploaded_documents = (data) => api.post('/get/mbbs/uploaded/documents', data);

const deleteMBBSDoc = (data) => api.post('/delete/mbbs/doc', data);

const getUploadedIdProofName = (data) => api.post('/get/uploaded/id/proof/name', data);

const deleteMBBSPaymentDoc = (data) => api.post('/delete/mbbs/payment/doc/and/transactionNo', data);

const getMBBSandBDSapplicationList = (data) => api.post('/get/mbbs/bds/application/list', data);

const getMBBSandBDSapplicationDetailsByApplicationNo = (data) => api.post('/get/mbbs/bds/application/details/by/application/no', data);

const updateMBBSandBDSapplication = (data) => api.post('/update/mbbs/bds/application', data);

const saveMDMSapplication = (data) => api.post('/save/mdms/application', data);

const MDMS_application_count_MGMCRI = (data) => api.post('/mdms/application/count/mgmcri', data);

const MDMS_application_count_SSSMCRI = (data) => api.post('/mdms/application/count/sssmcri', data);

const MDMS_application_count_IGIDS = (data) => api.post('/mdms/application/count/igids', data);

const MDMS_Documents_Upload = (data) => api.post('/mdms/documents/upload', data);

const get_MDMS_uploaded_documents = (data) => api.post('/get/mdms/uploaded/documents', data);

const deleteMDMSdocuments = (data) => api.post('/delete/mdms/documents', data);

const getMDMSapplicationList = (data) => api.post('/get/mdms/application/list', data);

const getMDMSapplicationDetailsByApplicationNo = (data) => api.post('/get/mdms/application/details/by/applicationNo', data);

const updateMDMSapplication = (data) => api.post('/update/mdms/application', data);

const getMDMSuploadedIdProofName = (data) => api.post('/mdms/uploaded/idproof/name', data);

const deleteMDMSpaymentDoc = (data) => api.post('/delete/mdms/payment/document', data);

const getAllMBBSandBDSapplications = () => api.post('/get/all/mbbs/and/bds/applications');

const submitMBBSandBDSremark = (data) => api.post('/submit/mbbs/bds/remark', data);

const MBBSandBDSapplicationRejected = (data) => api.post('/mbbs/bds/application/rejected', data);

const MBBSandBDSapplicationNotEligible = (data) => api.post('/mbbs/bds/application/noteligible', data);

const MBBSandBDSapplicationVerified = (data) => api.post('/mbbs/bds/application/verified', data);

const getAllMDMSapplications = () => api.post('/get/all/mdms/applications');

const submitMDMSapplicationRemark = (data) => api.post('/submit/mdms/application/remark', data);

const MDMSApplicationRejected = (data) => api.post('/mdms/application/rejected', data);

const MDMSApplicationNotEligible = (data) => api.post('/mdms/application/noteligible', data);

const MDMSApplicationVerified = (data) => api.post('/mdms/application/verified', data);

const ExportMBBSandBDSapplications = (data) => api.post('/export/mbbs/bds/applications/as/excel', data);

const ExportMDMSapplications = (data) => api.post('/export/mdms/applications/as/excel', data);

const MDMSapplicationdownload = (data) => api.post('/mdms/application/download/pdf', data, { responseType: 'blob' });

const MBBSandBDSapplicationdownload = (data) => api.post('/mbbs/and/bds/application/download/pdf', data, { responseType: 'blob' });

const getStudentDataByTemporaryUIN = (data) => api.post('/get/student/data/by/temporary/UIN', data);

const RegisterMBBSandBDSstudent = (data) => api.post('/register/MBBS/BDS/students', data);

const MBBSandBDSregistrationCount = (data) => api.post('/MBBS/BDS/registration/count', data);

const UpdateMBBSandBDSregistrationData = (data) => api.post('/update/MBBS/BDS/registration/data', data);

const getMBBSandBDSregistrationUploadedDocs = (data) => api.post('/get/MBBS/BDS/registration/uploaded/docs', data);

const deleteMBBSandBDSregistrationUploadedDocs = (data) => api.post('/delete/MBBS/BDS/registration/uploaded/docs', data);

const uploadMBBSandBDSregistrationDocs = (data) => api.post('/upload/MBBS/BDS/registration/docs', data);

const getRegistrationDataByRegNo = (data) => api.post('/get/registration/data/by/regNo', data);

const downloadAllDeclarationsInPDF = (data) => api.post('/download/all/declarations/in/pdf', data, { responseType: 'blob' });

const getStudentRegistrationListForDashboard = (data) => api.post('/get/student/registration/list/for/dashboard', data);

const saveEDPapplicationData = (data) => api.post('/save/edp/application/data', data);

const EDPapplicationDocUpload = (data) => api.post('/edp/application/doc/upload', data);

const getEDPapplicationUploadedDocs = (data) => api.post('/get/edp/application/uploaded/docs', data);

const deleteEDPapplicationUploadedDoc = (data) => api.post('/delete/edp/application/uploaded/doc', data);

const getEDPapplicationDataById = (data) => api.post('/get/edp/application/data/by/id', data);

const EDPapplicationPaymentSuccess = (data) => api.post('/edp/application/payment/success', data);

const EDPapplicationPaymentError = (data) => api.post('/edp/application/payment/error', data);

const getEDPapplicationListForDashboard = (data) => api.post('/get/edp/application/list/for/dashboard', data);

const downloadEDPapplicationForm = (data) => api.post('/download/edp/application/form', data, { responseType: 'blob' });

const getAllEDPapplications = (data) => api.post('/get/all/edp/applications', data);

const sentEDPapplicationRemark = (data) => api.post('/sent/edp/application/remark', data);

const EDPapplicationAccepted = (data) => api.post('/edp/application/accepted', data);

const EDPapplicationRejected = (data) => api.post('/edp/application/rejected', data);

const EDPapplicationNotEligible = (data) => api.post('/edp/application/not/eligible', data);

const exportEDPapplications = (data) => api.post('/export/edp/applications/as/excel', data);

const downloadBulkApplications = (data) => api.post('/download/bulk/applications', data, { responseType: 'blob' });

const getInstitute_BatchforBulkDownload = (data) => api.post('/get/institute/batch/for/bulk/download', data);

const checkMobileNoExistinAHSapplication = (data) => api.post('/check/mobile/no/exist/inAHS/application', data);

const studentRegistrationMD_MS_MDS = (data) => api.post('/student/registration/md/ms/mds', data);

const studentRegistrationMD_MS_MDS_DocUpload = (data) => api.post('/student/registration/md/ms/mds/doc/upload', data);

const studentRegistrationMD_MS_MDS_UploadedDocList = (data) => api.post('/student/registration/md/ms/mds/get/uploaded/doclist', data);

const studentRegistrationMD_MS_MDS_DeleteDoc = (data) => api.post('/student/registration/md/ms/mds/delete/doc', data);

const studentRegistrationMD_MS_MDS_DownloadDeclaration = (data) => api.post('/student/registration/md/ms/mds/download/declaration', data, { responseType: 'blob' });

const studentRegistrationMD_MS_MDS_CheckDuplicateReg = (data) => api.post('/student/registration/md/ms/mds/check/duplicate/reg', data);

const studentRegistration_DM_MCh_CheckDuplicateReg = (data) => api.post('/student/registration/dm/mch/check/duplicate/reg', data);

const studentRegistration_DM_MCh = (data) => api.post('/student/registration/dm/mch', data);

const studentRegistration_DM_MCh_DocUpload = (data) => api.post('/student/registration/dm/mch/doc/upload', data);

const studentRegistration_DM_MCh_UploadedDocList = (data) => api.post('/student/registration/dm/mch/get/uploaded/doclist', data);

const studentRegistration_DM_MCh_DeleteDoc = (data) => api.post('/student/registration/dm/mch/delete/doc', data);

const studentRegistration_DM_MCh_DownloadDeclaration = (data) => api.post('/student/registration/dm/mch/download/declaration', data, { responseType: 'blob' });

const studentRegistration_BSc_PBBSc_MSc_NPCC_CheckDuplicateReg = (data) => api.post('/student/registration/bsc/pbbsc/msc/npcc/check/duplicate/reg', data);

const studentRegistration_BSc_PBBSc_MSc_NPCC = (data) => api.post('/student/registration/bsc/pbbsc/msc/npcc', data);

const studentRegistration_BSc_PBBSc_MSc_NPCC_DocUpload = (data) => api.post('/student/registration/bsc/pbbsc/msc/npcc/doc/upload', data);

const studentRegistration_BSc_PBBSc_MSc_NPCC_UploadedDocList = (data) => api.post('/student/registration/bsc/pbbsc/msc/npcc/get/uploaded/doclist', data);

const studentRegistration_BSc_PBBSc_MSc_NPCC_DeleteDoc = (data) => api.post('/student/registration/bsc/pbbsc/msc/npcc/delete/doc', data);

const studentRegistration_BSc_PBBSc_MSc_NPCC_DownloadDeclaration = (data) => api.post('/student/registration/bsc/pbbsc/msc/npcc/download/declaration', data, { responseType: 'blob' });

const studentRegistration_BPharm_CheckDuplicateReg = (data) => api.post('/student/registration/BPharm/check/duplicate/reg', data);

const studentRegistration_BPharm = (data) => api.post('/student/registration/BPharm', data);

const studentRegistration_BPharm_DocUpload = (data) => api.post('/student/registration/BPharm/DocUpload', data);

const studentRegistration_BPharm_UploadedDocList = (data) => api.post('/student/registration/BPharm/get/uploaded/doclist', data);

const studentRegistration_BPharm_DeleteDoc = (data) => api.post('/student/registration/BPharm/delete/doc', data);

const studentRegistration_BPharm_DownloadDeclaration = (data) => api.post('/student/registration/BPharm/download/declaration', data, { responseType: 'blob' });

const studentRegistration_AHS_CheckDuplicateReg = (data) => api.post('/student/registration/AHS/check/duplicate/reg', data);

const studentRegistration_AHS = (data) => api.post('/student/registration/AHS', data);

const studentRegistration_AHS_DocUpload = (data) => api.post('/student/registration/AHS/DocUpload', data);

const studentRegistration_AHS_UploadedDocList = (data) => api.post('/student/registration/AHS/get/uploaded/doclist', data);

const studentRegistration_AHS_DeleteDoc = (data) => api.post('/student/registration/AHS/delete/doc', data);

const studentRegistration_AHS_DownloadDeclaration = (data) => api.post('/student/registration/AHS/download/declaration', data, { responseType: 'blob' });

const get_MD_MS_MDS_StudentRegistrationList = (data) => api.post('/get/MD/MS/MDS/Student/Registration/List', data);

const get_MD_MCh_StudentRegistrationList = (data) => api.post('/get/MD/MCh/Student/Registration/List', data);

const get_AHS_StudentRegistrationList = (data) => api.post('/get/AHS/Student/Registration/List', data);

const get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList = (data) => api.post('/get/BSc/PBBSc/MSc/NPCC/Student/Registration/List', data);

const get_BPharm_StudentRegistrationList = (data) => api.post('/get/BPharm/Student/Registration/List', data);

const studentRegistration_BPT_MPT_CheckDuplicateReg = (data) => api.post('/student/registration/BPT/MPT/check/duplicate/reg', data);

const studentRegistration_BPT_MPT = (data) => api.post('/student/registration/BPT/MPT', data);

const studentRegistration_BPT_MPT_DocUpload = (data) => api.post('/student/registration/BPT/MPT/DocUpload', data);

const studentRegistration_BPT_MPT_UploadedDocList = (data) => api.post('/student/registration/BPT/MPT/get/uploaded/doclist', data);

const studentRegistration_BPT_MPT_DeleteDoc = (data) => api.post('/student/registration/BPT/MPT/delete/doc', data);

const studentRegistration_BPT_MPT_DownloadDeclaration = (data) => api.post('/student/registration/BPT/MPT/download/declaration', data, { responseType: 'blob' });

const get_BPT_MPT_StudentRegistrationList = (data) => api.post('/get/BPT/MPT/Student/Registration/List', data);

const getBatchByApplicationName = (data) => api.post('/get/batch/by/application/name', data);

const sendPaymentReminderByApplicationWithBatch = (data) => api.post('/send/payment/reminder/by/application/with/batch', data);

const addInstitute = (data) => api.post('/add/institute', data);

const getAllInstituteData = (data) => api.post('/get/all/institute/data', data);

const updateInstituteData = (data) => api.post('/update/institute/data', data);

const addCourse = (data) => api.post('/add/course', data);

const updateCourse = (data) => api.post('/update/course', data);

const getCourse = (data) => api.post('/get/course', data);

const Coursedelete = (data) => api.post('/delete/course', data);

const getOpen_Close_ApplicationStatus = () => api.post('/get/open/close/application/status');

const getInstituteforApplication = (data) => api.post('/get/institute/for/application', data);

const getCourseforApplication = (data) => api.post('/get/course/for/application', data);

const getAHSapplicationDataforUpdatePaymentStatus = (data) => api.post('/get/application/data/for/update/payment/status', data);

const UpdateAHSapplicationPaymentStatus = (data) => api.post('/update/ahs/application/payment/status', data);

const studentRegistration_MSc_Medical_MBA_MPH_CheckDuplicateReg = (data) => api.post('/student/registration/MSc/Medical/MBA/MPH/check/duplicate/reg', data);

const studentRegistration_MSc_Medical_MBA_MPH = (data) => api.post('/student/registration/MSc/Medical/MBA/MPH', data);

const studentRegistration_MSc_Medical_MBA_MPH_DocUpload = (data) => api.post('/student/registration/MSc/Medical/MBA/MPH/DocUpload', data);

const studentRegistration_MSc_Medical_MBA_MPH_uploadedDocList = (data) => api.post('/student/registration/MSc/Medical/MBA/MPH/uploaded/doc/list', data);

const studentRegistration_MSc_Medical_MBA_MPH_deleteDoc = (data) => api.post('/student/registration/MSc/Medical/MBA/MPH/delete/doc', data);

const studentRegistration_MSc_Medical_MBA_MPH_downloadAllDeclarations = (data) => api.post('/student/registration/MSc/Medical/MBA/MPH/doenload/all/declarations', data, { responseType: 'blob' });

const get_MSc_Medical_MBA_MPH_StudentRegistrationList = (data) => api.post('/get/MSc/Medical/MBA/MPH/Student/Registration/List', data);




export {
    registerUser,
    login,
    ApplicationBasicDetails,
    GetLastRecord,
    UpdateAcademicData,
    updateBasicData,
    DocumentsUpload,
    GetUploadedDocuments,
    DeleteDocument,
    ApplicationList,
    ApplicationData,
    DownloadApplication,
    createRazorpayOrder,
    PaymentSuccessResponse,
    PaymentErrorResponse,
    GetApplicationsByInstitute,
    GetAllApplications,
    ApplicationVerification,
    GetAllVerifiedCount,
    InstituteWiseVerifiedCount,
    DataInXL,
    GetCourselistwithCount,
    ExamScheduleUpdate,
    GetUnscheduledCount,
    SaveRemark,
    ReminderMail,
    DownloadHallTicket,
    getCourseName,
    getInstitutionName,
    applicationRejected,
    applicationNotEligible,
    forgotpassword,
    savePhdApplication,
    saveFellowshipApplication,
    getPhdApplicationCount,
    phdApplicationDocumentsUpload,
    getPhdUploadDocuments,
    deletePhdApplicationDoc,
    phdApplicationPaymentStatus,
    getPhdApplicationData,
    phdPaymentSuccess,
    phdPaymentError,
    getFellowshipApplicationCount,
    fellowshipApplicationDocumentsUpload,
    getFellowshipUploadedDocuments,
    deleteFellowshipApplicationDoc,
    fellowshipApplicationPaymentStatus,
    getFellowshipApplicationData,
    fellowshipPaymentSuccess,
    fellowshipPaymentError,
    getPhdApplicationList,
    getFellowshipApplicationList,
    getPhdApplicationById,
    updatePhdApplication,
    getFellowshipApplicationById,
    updateFellowshipApplication,
    getFellowshipApplicationByRegNo,
    getPhdApplicationByRegNo,
    getPhdApplicationsData,
    sentPhdApplicationRemark,
    phdApplicationRejected,
    phdApplicationNotEligible,
    phdApplicationVerified,
    getAllFellowshipApplicationData,
    sentFellowshipApplicationRemark,
    fellowshipApplicationRejected,
    fellowshipApplicationNotEligible,
    fellowshipApplicationVerified,
    getPaymentCustomerData,
    updateApplicationPosition,
    getAhsApplicationPosition,
    getPhdApplicationPosition,
    getFellowshipApplicationPosition,
    exportFellowshipApplications,
    exportPhdApplications,
    MBBS_BDS_DocumentsUpload,
    saveMBBS_BDS_Application,
    MBBS_application_count_MGMCRI,
    MBBS_application_count_SSSMCRI,
    MBBS_application_count_IGIDS,
    get_MBBS_uploaded_documents,
    deleteMBBSDoc,
    getUploadedIdProofName,
    deleteMBBSPaymentDoc,
    getMBBSandBDSapplicationList,
    getMBBSandBDSapplicationDetailsByApplicationNo,
    updateMBBSandBDSapplication,
    phdApplicationDownload,
    fellowshipApplicationDownload,
    saveMDMSapplication,
    MDMS_application_count_MGMCRI,
    MDMS_application_count_SSSMCRI,
    MDMS_application_count_IGIDS,
    MDMS_Documents_Upload,
    get_MDMS_uploaded_documents,
    deleteMDMSdocuments,
    getMDMSapplicationList,
    getMDMSapplicationDetailsByApplicationNo,
    updateMDMSapplication,
    getMDMSuploadedIdProofName,
    deleteMDMSpaymentDoc,
    getAllMBBSandBDSapplications,
    submitMBBSandBDSremark,
    MBBSandBDSapplicationRejected,
    MBBSandBDSapplicationNotEligible,
    MBBSandBDSapplicationVerified,
    getAllMDMSapplications,
    submitMDMSapplicationRemark,
    MDMSApplicationRejected,
    MDMSApplicationNotEligible,
    MDMSApplicationVerified,
    ExportMBBSandBDSapplications,
    ExportMDMSapplications,
    MDMSapplicationdownload,
    MBBSandBDSapplicationdownload,
    getStudentDataByTemporaryUIN,
    RegisterMBBSandBDSstudent,
    MBBSandBDSregistrationCount,
    UpdateMBBSandBDSregistrationData,
    getMBBSandBDSregistrationUploadedDocs,
    deleteMBBSandBDSregistrationUploadedDocs,
    uploadMBBSandBDSregistrationDocs,
    getRegistrationDataByRegNo,
    downloadAllDeclarationsInPDF,
    getStudentRegistrationListForDashboard,
    saveEDPapplicationData,
    EDPapplicationDocUpload,
    getEDPapplicationUploadedDocs,
    deleteEDPapplicationUploadedDoc,
    getEDPapplicationDataById,
    EDPapplicationPaymentSuccess,
    EDPapplicationPaymentError,
    getEDPapplicationListForDashboard,
    downloadEDPapplicationForm,
    getAllEDPapplications,
    sentEDPapplicationRemark,
    EDPapplicationAccepted,
    EDPapplicationRejected,
    EDPapplicationNotEligible,
    exportEDPapplications,
    downloadBulkApplications,
    getInstitute_BatchforBulkDownload,
    checkMobileNoExistinAHSapplication,
    studentRegistrationMD_MS_MDS,
    studentRegistrationMD_MS_MDS_DocUpload,
    studentRegistrationMD_MS_MDS_UploadedDocList,
    studentRegistrationMD_MS_MDS_DeleteDoc,
    studentRegistrationMD_MS_MDS_DownloadDeclaration,
    studentRegistrationMD_MS_MDS_CheckDuplicateReg,
    studentRegistration_DM_MCh_CheckDuplicateReg,
    studentRegistration_DM_MCh,
    studentRegistration_DM_MCh_DocUpload,
    studentRegistration_DM_MCh_DeleteDoc,
    studentRegistration_DM_MCh_UploadedDocList,
    studentRegistration_DM_MCh_DownloadDeclaration,
    studentRegistration_BSc_PBBSc_MSc_NPCC_CheckDuplicateReg,
    studentRegistration_BSc_PBBSc_MSc_NPCC,
    studentRegistration_BSc_PBBSc_MSc_NPCC_DocUpload,
    studentRegistration_BSc_PBBSc_MSc_NPCC_UploadedDocList,
    studentRegistration_BSc_PBBSc_MSc_NPCC_DeleteDoc,
    studentRegistration_BSc_PBBSc_MSc_NPCC_DownloadDeclaration,
    studentRegistration_BPharm_CheckDuplicateReg,
    studentRegistration_BPharm,
    studentRegistration_BPharm_DocUpload,
    studentRegistration_BPharm_UploadedDocList,
    studentRegistration_BPharm_DeleteDoc,
    studentRegistration_BPharm_DownloadDeclaration,
    studentRegistration_AHS_CheckDuplicateReg,
    studentRegistration_AHS,
    studentRegistration_AHS_DocUpload,
    studentRegistration_AHS_UploadedDocList,
    studentRegistration_AHS_DeleteDoc,
    studentRegistration_AHS_DownloadDeclaration,
    get_MD_MS_MDS_StudentRegistrationList,
    get_MD_MCh_StudentRegistrationList,
    get_AHS_StudentRegistrationList,
    get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList,
    get_BPharm_StudentRegistrationList,
    studentRegistration_BPT_MPT_CheckDuplicateReg,
    studentRegistration_BPT_MPT,
    studentRegistration_BPT_MPT_DocUpload,
    studentRegistration_BPT_MPT_UploadedDocList,
    studentRegistration_BPT_MPT_DeleteDoc,
    studentRegistration_BPT_MPT_DownloadDeclaration,
    get_BPT_MPT_StudentRegistrationList,
    getBatchByApplicationName,
    sendPaymentReminderByApplicationWithBatch,
    addInstitute,
    getAllInstituteData,
    updateInstituteData,
    addCourse,
    updateCourse,
    getCourse,
    Coursedelete,
    getOpen_Close_ApplicationStatus,
    getInstituteforApplication,
    getCourseforApplication,
    getAHSapplicationDataforUpdatePaymentStatus,
    UpdateAHSapplicationPaymentStatus,
    studentRegistration_MSc_Medical_MBA_MPH_CheckDuplicateReg,
    studentRegistration_MSc_Medical_MBA_MPH,
    studentRegistration_MSc_Medical_MBA_MPH_DocUpload,
    studentRegistration_MSc_Medical_MBA_MPH_uploadedDocList,
    studentRegistration_MSc_Medical_MBA_MPH_deleteDoc,
    studentRegistration_MSc_Medical_MBA_MPH_downloadAllDeclarations,
    get_MSc_Medical_MBA_MPH_StudentRegistrationList

};