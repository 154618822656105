import React from 'react';
import { Button, Container, Row, Col, Card, Form, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Spinner from './Spinner';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import prerequest from './Prerequeste.jpg';
import {
    getStudentDataByTemporaryUIN,
    RegisterMBBSandBDSstudent,
    MBBSandBDSregistrationCount,
    UpdateMBBSandBDSregistrationData
} from './services';
function getInitialState() {
    return {
        applicantName: '',
        institute: '',
        course: '',
        mobileNo: '',
        mailid: '',
        gender: '',
        dateofbirth: '',
        parentName: '',
        address: '',
        NEETRollNo: '',
        NEETRank: '',
        NEETMark: '',
        haveTemporaryUIN: 'No',
        registrationNo: '',
        programmes: '',
        religion: '',
        nationality: '',
        community: '',
        motherName: '',
        placeOfBirth: '',
        communicationAddress: '',
        aadharNo: '',
        HSCboard: '',
        HSCexaminationName: '',
        HSCmonthandyearofpassing: '',
        HSCnameandaddress: '',
        HSCgrade: '',
        HSCattempt: '',
        LanguageSubjectName: '',
        OtherSubjectName1: '',
        OtherSubjectName2: '',
        LanguageMarkObtained: 0,
        LanguageMaxMark: 0,
        EnglishMarkObtained: 0,
        EnglishMaxMark: 0,
        PhysicsMarkObtained: 0,
        PhysicsMaxMark: 0,
        ChemistryMarkObtained: 0,
        ChemistryMaxMark: 0,
        BiologyMarkObtained: 0,
        BiologyMaxMark: 0,
        BotanyMarkObtained: 0,
        BotanyMaxMark: 0,
        ZoologyMarkObtained: 0,
        ZoologyMaxMark: 0,
        MathematicsMarkObtained: 0,
        MathematicsMaxMark: 0,
        OtherSubject1MarkObtained: 0,
        OtherSubject2MarkObtained: 0,
        OtherSubject1MaxMark: 0,
        OtherSubject2MaxMark: 0,
        TotalMarks: 0,
        TotalPercentageofMarks: 0,
        PCBpercentage: 0,
        firstGraduate: '',
        fatherOccupation: '',
        motherOccupation: '',
        annualFamilyIncome: '',
        noOfAttempts: '',
        DGHSallotment: '',
        declarationAgree: '',
        antiraggingRefNo: '',
        ABCid: '',
        residentialStatus: '',
        dietaryPreference: '',
        busStops: '',
        motherMailId: '',
        fatherMailId: '',
        motherLandlineNo: '',
        fatherLandlineNo: '',
        motherMobileNo: '',
        fatherMobileNo: '',
        fatherOfficeAddress: '',
        motherOfficeAddress: '',
        localGuardianAddress: '',
        localGuardianMailId: '',
        localGuardianMobileNo: '',
        localGuardianName: '',
        submit: false,
        edit: false
    }
};

function MBBSandBDSstudentRegistration() {
    const [spinner, setspinner] = React.useState(false);
    const [state, setState] = React.useState(getInitialState());
    const [disabled, setdisabled] = React.useState('');
    const [BiologyDisplay, setBiologyDisplay] = React.useState('');
    const navigate = useNavigate();
    const regiNoBegin = 1000;
    const IGIDSregnobegin = 2000;
    const [showRemarkModal, setshowRemarkModal] = React.useState(false);
    const [errors, setErrors] = React.useState({});

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const goToDashboard = () => {
        localStorage.removeItem("registrationNo");
        navigate("/dashboard");
    };

    const onChangeInput = (event) => {
        var { target: { name, value, checked } } = event;
        //var empty = /^\s*$/.test(value);

        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    React.useEffect(() => {
        if (state.BiologyMarkObtained > 0) {
            setdisabled('none');
        }
        if (state.BiologyMarkObtained === 0) {
            setdisabled('');
        }
    }, [state.BiologyMarkObtained]);

    React.useEffect(() => {
        if (state.BotanyMarkObtained > 0) {
            setBiologyDisplay('none');
        }
        if (state.BotanyMarkObtained === 0) {
            setBiologyDisplay('');
        }
        if (state.ZoologyMarkObtained > 0) {
            setBiologyDisplay('none');
        }
        if (state.ZoologyMarkObtained === 0) {
            setBiologyDisplay('');
        }
    }, [state.BotanyMarkObtained, state.ZoologyMarkObtained]);

    const GetDataByTemporaryUIN = async () => {
        try {
            if (state.registrationNo) {
                setspinner(true);
                const Result = await getStudentDataByTemporaryUIN({ "TemporaryUIN": state.registrationNo });
                setState((prevState) => ({
                    ...prevState,
                    ...Result
                }));
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const SaveandNext = async () => {
        try {
            if (state.declarationAgree === "on") {
                if (state.registrationNo) {
                    if (state.edit) {
                        setspinner(true);
                        await UpdateMBBSandBDSregistrationData(state);
                        setspinner(false);
                        localStorage.setItem("registrationNo", state.registrationNo);
                        navigate("/mbbsandbdsstudentregistrationdocumentsupload");
                    }
                    else {
                        setspinner(true);
                        await RegisterMBBSandBDSstudent(state);
                        setspinner(false);
                        localStorage.setItem("registrationNo", state.registrationNo);
                        navigate("/mbbsandbdsstudentregistrationdocumentsupload");
                    }
                }
                else {
                    const validationErrors = validate();
                    if (Object.keys(validationErrors).length > 0) {
                        setErrors(validationErrors);
                    }
                    if (state.applicantName.trim() === '' || state.institute.trim() === '' ||
                        state.course.trim() === '' || state.mobileNo.trim() === '' ||
                        state.mailid.trim() === '' || state.gender.trim() === '' ||
                        state.dateofbirth.trim() === '' || state.parentName.trim() === '' ||
                        state.address.trim() === '' || state.programmes.trim() === '' ||
                        state.nationality.trim() === '' || state.motherName.trim() === '' ||
                        state.motherMobileNo.trim() === '' || state.fatherMobileNo.trim() === '' ||
                        state.fatherOccupation.trim() === '' || state.motherOccupation.trim() === '' ||
                        state.communicationAddress.trim() === '' || state.aadharNo.trim() === '' ||
                        state.HSCboard.trim() === '' || state.HSCexaminationName.trim() === '' ||
                        state.HSCmonthandyearofpassing.trim() === '' || state.HSCnameandaddress.trim() === '' ||
                        state.HSCattempt.trim() === '' || state.firstGraduate.trim() === '' ||
                        state.annualFamilyIncome.trim() === '' || state.noOfAttempts.trim() === '' ||
                        state.DGHSallotment.trim() === '' || state.antiraggingRefNo.trim() === '' ||
                        state.ABCid.trim() === '' || state.residentialStatus.trim() === '' ||
                        state.NEETRollNo.trim() === '' || state.NEETRank.trim() === '' ||
                        state.NEETMark.trim() === '' || state.community.trim() === '' || state.religion.trim() === '') {
                        swal({
                            title: "Please Fill All Required Fields",
                            icon: 'info'
                        });
                    }
                    else {
                        const count = await MBBSandBDSregistrationCount({ "institute": state.institute });
                        if (state.institute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI") {
                            setState((prevState) => ({
                                ...prevState,
                                registrationNo: `T23M0${regiNoBegin + count.result + 1}`,
                                userName: localStorage.getItem("username"),
                                submit: true
                            }))
                        }
                        if (state.institute === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {
                            setState((prevState) => ({
                                ...prevState,
                                registrationNo: `T23S0${regiNoBegin + count.result + 1}`,
                                userName: localStorage.getItem("username"),
                                submit: true
                            }))
                        }
                        if (state.institute === "Indira Gandhi Institute Of Dental Sciences - IGIDS") {
                            setState((prevState) => ({
                                ...prevState,
                                registrationNo: `T23I0${IGIDSregnobegin + count.result + 1}`,
                                userName: localStorage.getItem("username"),
                                submit: true
                            }))
                        }
                    }
                }
            }
            else {
                swal({
                    title: "Please check the declaration",
                    icon: 'info'
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        if (state.submit) {
            saveData();
        }
    }, [state.submit]);

    async function saveData() {
        setspinner(true);
        await RegisterMBBSandBDSstudent(state);
        Swal.fire({
            title: 'Please Note Your Temporary UIN',
            html: '<h4 style="color:green;">' + state.registrationNo + '</h4>',
            icon: 'success',
        });
        setState((prevState) => ({
            ...prevState,
            submit: false
        }))
        setspinner(false);
        localStorage.setItem("registrationNo", state.registrationNo);
        navigate("/mbbsandbdsstudentregistrationdocumentsupload");
    };

    React.useEffect(() => {
        calculateTotalMarks();
    },
        [
            state.EnglishMarkObtained,
            state.PhysicsMarkObtained,
            state.ChemistryMarkObtained,
            state.BiologyMarkObtained,
            state.BotanyMarkObtained,
            state.ZoologyMarkObtained,
            state.LanguageMarkObtained,
            state.MathematicsMarkObtained,
            state.OtherSubject1MarkObtained,
            state.OtherSubject2MarkObtained
        ]
    );

    function calculateTotalMarks() {
        if (/^\s*$/.test(state.EnglishMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                EnglishMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.PhysicsMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                PhysicsMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.ChemistryMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                ChemistryMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.BiologyMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                BiologyMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.BotanyMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                BotanyMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.ZoologyMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                ZoologyMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.LanguageMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                LanguageMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.MathematicsMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                MathematicsMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.OtherSubject1MarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                OtherSubject1MarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.OtherSubject2MarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                OtherSubject2MarkObtained: 0
            }))
        }
        var Tmark = parseInt(state.LanguageMarkObtained) + parseInt(state.EnglishMarkObtained) +
            parseInt(state.PhysicsMarkObtained) + parseInt(state.ChemistryMarkObtained) +
            parseInt(state.BiologyMarkObtained) + parseInt(state.BotanyMarkObtained) +
            parseInt(state.ZoologyMarkObtained) + parseInt(state.MathematicsMarkObtained) +
            parseInt(state.OtherSubject1MarkObtained) + parseInt(state.OtherSubject2MarkObtained);
        setState((prevState) => ({
            ...prevState,
            TotalMarks: Tmark
        }))
    };

    React.useEffect(() => {

        calculateTotalPercentage();

    },
        [
            state.EnglishMarkObtained,
            state.PhysicsMarkObtained,
            state.ChemistryMarkObtained,
            state.BiologyMarkObtained,
            state.BotanyMarkObtained,
            state.ZoologyMarkObtained,
            state.EnglishMaxMark,
            state.PhysicsMaxMark,
            state.ChemistryMaxMark,
            state.BiologyMaxMark,
            state.BotanyMaxMark,
            state.ZoologyMaxMark,
            state.LanguageMarkObtained,
            state.LanguageMaxMark,
            state.MathematicsMarkObtained,
            state.MathematicsMaxMark,
            state.OtherSubject1MarkObtained,
            state.OtherSubject1MaxMark,
            state.OtherSubject2MarkObtained,
            state.OtherSubject2MaxMark
        ]
    );

    function calculateTotalPercentage() {
        if (/^\s*$/.test(state.EnglishMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                EnglishMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.PhysicsMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                PhysicsMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.ChemistryMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                ChemistryMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.BiologyMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                BiologyMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.BotanyMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                BotanyMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.ZoologyMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                ZoologyMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.LanguageMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                LanguageMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.MathematicsMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                MathematicsMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.OtherSubject1MarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                OtherSubject1MarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.OtherSubject2MarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                OtherSubject2MarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.EnglishMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                EnglishMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.PhysicsMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                PhysicsMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.ChemistryMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                ChemistryMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.BiologyMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                BiologyMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.BotanyMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                BotanyMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.ZoologyMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                ZoologyMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.LanguageMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                LanguageMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.MathematicsMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                MathematicsMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.OtherSubject1MaxMark)) {
            setState((prevState) => ({
                ...prevState,
                OtherSubject1MaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.OtherSubject2MaxMark)) {
            setState((prevState) => ({
                ...prevState,
                OtherSubject2MaxMark: 0
            }))
        }
        var TmaxMarks = parseInt(state.LanguageMaxMark) + parseInt(state.EnglishMaxMark) +
            parseInt(state.PhysicsMaxMark) + parseInt(state.ChemistryMaxMark) + parseInt(state.BiologyMaxMark) +
            parseInt(state.BotanyMaxMark) + parseInt(state.ZoologyMaxMark) + parseInt(state.MathematicsMaxMark) +
            parseInt(state.OtherSubject1MaxMark) + parseInt(state.OtherSubject2MaxMark);

        var Tobtain = parseInt(state.LanguageMarkObtained) + parseInt(state.EnglishMarkObtained) +
            parseInt(state.PhysicsMarkObtained) + parseInt(state.ChemistryMarkObtained) + parseInt(state.BiologyMarkObtained) +
            parseInt(state.BotanyMarkObtained) + parseInt(state.ZoologyMarkObtained) + parseInt(state.MathematicsMarkObtained) +
            parseInt(state.OtherSubject1MarkObtained) + parseInt(state.OtherSubject2MarkObtained);

        var Tpercentage = (Tobtain / TmaxMarks) * 100;
        setState((prevState) => ({
            ...prevState,
            TotalPercentageofMarks: Tpercentage.toFixed(2)
        }))
    };

    React.useEffect(() => {
        if (state.PhysicsMarkObtained !== 0 &&
            state.ChemistryMarkObtained !== 0 &&
            state.PhysicsMaxMark !== 0 &&
            state.ChemistryMaxMark !== 0
        ) {
            calculatePCBpercentage();
        }
    },
        [
            state.PhysicsMarkObtained,
            state.ChemistryMarkObtained,
            state.BiologyMarkObtained,
            state.ZoologyMarkObtained,
            state.BotanyMarkObtained,
            state.PhysicsMaxMark,
            state.ChemistryMaxMark,
            state.BiologyMaxMark,
            state.ZoologyMaxMark,
            state.BotanyMaxMark
        ]
    );

    function calculatePCBpercentage() {
        if (/^\s*$/.test(state.EnglishMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                EnglishMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.PhysicsMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                PhysicsMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.ChemistryMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                ChemistryMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.BiologyMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                BiologyMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.BotanyMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                BotanyMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.ZoologyMarkObtained)) {
            setState((prevState) => ({
                ...prevState,
                ZoologyMarkObtained: 0
            }))
        }
        if (/^\s*$/.test(state.EnglishMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                EnglishMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.PhysicsMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                PhysicsMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.ChemistryMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                ChemistryMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.BiologyMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                BiologyMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.BotanyMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                BotanyMaxMark: 0
            }))
        }
        if (/^\s*$/.test(state.ZoologyMaxMark)) {
            setState((prevState) => ({
                ...prevState,
                ZoologyMaxMark: 0
            }))
        }
        var PCBmax = parseInt(state.PhysicsMaxMark) + parseInt(state.ChemistryMaxMark) + parseInt(state.BiologyMaxMark) + parseInt(state.ZoologyMaxMark) + parseInt(state.BotanyMaxMark);
        var PCBobtained = parseInt(state.PhysicsMarkObtained) + parseInt(state.ChemistryMarkObtained) + parseInt(state.BiologyMarkObtained) + parseInt(state.BotanyMarkObtained) + parseInt(state.ZoologyMarkObtained);
        var PCB = (PCBobtained / PCBmax) * 100;
        setState((prevState) => ({
            ...prevState,
            PCBpercentage: PCB.toFixed(2)
        }))
    };

    const getCourseOptions = () => {
        if (state.programmes === "MBBS") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="MBBS">MBBS</option>
                </React.Fragment>
            )
        }
        if (state.programmes === "BDS") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="BDS">BDS</option>
                </React.Fragment>
            )
        }
        if (state.programmes === "MD" && state.institute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="Anatomy">Anatomy</option>
                    <option name="course" value="Biochemistry">Biochemistry</option>
                    <option name="course" value="Anaesthesiology">Anaesthesiology</option>
                    <option name="course" value="Community Medicine">Community Medicine</option>
                    <option name="course" value="Dermatology, Venerology & Leprosy">Dermatology, Venerology & Leprosy</option>
                    <option name="course" value="General Medicine">General Medicine</option>
                    <option name="course" value="Microbiology">Microbiology</option>
                    <option name="course" value="Paediatrics">Paediatrics</option>
                    <option name="course" value="Pathology">Pathology</option>
                    <option name="course" value="Psychiatry">Psychiatry</option>
                    <option name="course" value="Radio diagnosis">Radio diagnosis</option>
                    <option name="course" value="Respiratory Medicine">Respiratory Medicine</option>
                </React.Fragment>
            )
        }
        if (state.programmes === "MS" && state.institute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="Physiology">Physiology</option>
                    <option name="course" value="Pharmacology">Pharmacology</option>
                    <option name="course" value="Ophthalmology">Ophthalmology</option>
                    <option name="course" value="Otorhinolaryngology (E.N.T.)">Otorhinolaryngology (E.N.T.)</option>
                    <option name="course" value="General Surgery">General Surgery</option>
                    <option name="course" value="Orthopaedics">Orthopaedics</option>
                    <option name="course" value="Obstetrics & Gynaecology">Obstetrics & Gynaecology</option>
                </React.Fragment>
            )
        }
        if (state.programmes === "MS" && state.institute === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="Pharmacology">Pharmacology</option>
                    <option name="course" value="Ophthalmology">Ophthalmology</option>
                    <option name="course" value="Otorhinolaryngology (E.N.T.)">Otorhinolaryngology (E.N.T.)</option>
                    <option name="course" value="General Surgery">General Surgery</option>
                    <option name="course" value="Orthopaedics">Orthopaedics</option>
                    <option name="course" value="Obstetrics & Gynaecology">Obstetrics & Gynaecology</option>
                </React.Fragment>
            )
        }
        if (state.programmes === "MD" && state.institute === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="Anaesthesiology">Anaesthesiology</option>
                    <option name="course" value="Community Medicine">Community Medicine</option>
                    <option name="course" value="Dermatology, Venerology & Leprosy">Dermatology, Venerology & Leprosy</option>
                    <option name="course" value="General Medicine">General Medicine</option>
                    <option name="course" value="Forensic Medicine">Forensic Medicine</option>
                    <option name="course" value="Microbiology">Microbiology</option>
                    <option name="course" value="Paediatrics">Paediatrics</option>
                    <option name="course" value="Pathology">Pathology</option>
                    <option name="course" value="Radio diagnosis">Radio diagnosis</option>
                    <option name="course" value="Respiratory Medicine">Respiratory Medicine</option>
                </React.Fragment>
            )
        }
        if (state.programmes === "MDS" && state.institute === "Indira Gandhi Institute Of Dental Sciences - IGIDS") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="Oral Medicine & Radiology">Oral Medicine & Radiology</option>
                    <option name="course" value="Conservative Dentistry and Endodontics">Conservative Dentistry and Endodontics</option>
                    <option name="course" value="Oral and Maxillofacial Surgery">Oral and Maxillofacial Surgery</option>
                    <option name="course" value="Orthodontics & Dentofacial Orthopaedics">Orthodontics & Dentofacial Orthopaedics</option>
                    <option name="course" value="Pedodontics & Preventive Dentistry">Pedodontics & Preventive Dentistry</option>
                    <option name="course" value="Periodontology">Periodontology</option>
                    <option name="course" value="Prosthodontics and Crown & Bridge">Prosthodontics and Crown & Bridge</option>
                    <option name="course" value="Oral Pathology and Microbiology">Oral Pathology and Microbiology</option>
                    <option name="course" value="Public Health Dentistry">Public Health Dentistry</option>

                </React.Fragment>
            )
        }
    };

    const getInstituteOptions = () => {
        if (state.programmes === "MBBS" || state.programmes === "MD" || state.programmes === "MS") {
            return (
                <React.Fragment>
                    <option>Select Institute</option>
                    <option name="institute"
                        value="Mahatma Gandhi Medical College & Research Institute - MGMCRI">
                        Mahatma Gandhi Medical College & Research Institute - MGMCRI</option>
                    <option name="institute"
                        value="Shri Sathya Sai Medical College & Research Institute - SSSMCRI">
                        Shri Sathya Sai Medical College & Research Institute - SSSMCRI
                    </option>
                </React.Fragment>
            )
        }
        if (state.programmes === "BDS" || state.programmes === "MDS") {
            return (
                <React.Fragment>
                    <option>Select Institute</option>

                    <option name="institute"
                        value="Indira Gandhi Institute Of Dental Sciences - IGIDS">
                        Indira Gandhi Institute Of Dental Sciences - IGIDS
                    </option>
                </React.Fragment>
            )
        }
    };

    const handleKeyDown = (event) => {
        // Prevent changes caused by keyboard arrow keys
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
        }
    };

    const redirectSBVwelcomepage = () => {
        window.open("https://sbvu.ac.in/welcome-students", "_blank");
    };

    const CloseRemarkModal = () => {
        setshowRemarkModal(false);
    };

    React.useEffect(() => {
        setshowRemarkModal(true);
    }, []);

    const validate = () => {
        const errors = {};

        if (!state.programmes) {
            errors.programmes = 'Programmes is required';
        }
        if (!state.institute) {
            errors.institute = 'Institute is required';
        }
        if (!state.course) {
            errors.course = 'Course is required';
        }
        if (!state.applicantName) {
            errors.applicantName = 'Student Name is required';
        }
        if (!state.gender) {
            errors.gender = 'Gender is required';
        }
        if (!state.dateofbirth) {
            errors.dateofbirth = 'Date of Birth is required';
        }
        if (!state.religion) {
            errors.religion = 'Religion is required';
        }
        if (!state.nationality) {
            errors.nationality = 'Nationality is required';
        }
        if (!state.community) {
            errors.community = 'Community is required';
        }
        if (!state.parentName) {
            errors.parentName = 'Father Name is required';
        }
        if (!state.motherName) {
            errors.motherName = 'Mother Name is required';
        }
        if (!state.motherMobileNo) {
            errors.motherMobileNo = 'Mother MobileNo is required';
        }
        if (!state.fatherMobileNo) {
            errors.fatherMobileNo = 'Father MobileNo is required';
        }
        if (!state.fatherOccupation) {
            errors.fatherOccupation = 'Father Occupation is required';
        }
        if (!state.motherOccupation) {
            errors.motherOccupation = 'Mother Occupation is required';
        }
        if (!state.communicationAddress) {
            errors.communicationAddress = 'Communication Address is required';
        }
        if (!state.address) {
            errors.address = 'Permanent Address is required';
        }
        if (!state.mobileNo) {
            errors.mobileNo = 'MobileNo is required';
        }
        if (!state.mailid) {
            errors.mailid = 'Mail Id is required';
        }
        else if (!/^\S+@\S+\.\S+$/.test(state.mailid)) {
            errors.mailid = 'Invalid email format';
        }
        if (!state.aadharNo) {
            errors.aadharNo = 'AadharNo is required';
        }
        if (!state.HSCboard) {
            errors.HSCboard = 'H.S.C. Examination Board is required';
        }
        if (!state.HSCexaminationName) {
            errors.HSCexaminationName = 'H.S.C. Examination Name is required';
        }
        if (!state.HSCmonthandyearofpassing) {
            errors.HSCmonthandyearofpassing = 'H.S.C. Examination Month and Year of Passing is required';
        }
        if (!state.HSCnameandaddress) {
            errors.HSCnameandaddress = 'Name of School and Address is required';
        }
        if (!state.HSCattempt) {
            errors.HSCattempt = 'H.S.C. Examination Attempt is required';
        }
        if (!state.firstGraduate) {
            errors.firstGraduate = 'First Graduate in your family is required';
        }
        if (!state.annualFamilyIncome) {
            errors.annualFamilyIncome = 'Annual Family Income is required';
        }
        if (!state.NEETRollNo) {
            errors.NEETRollNo = 'NEET RollNo is required';
        }
        if (!state.NEETRank) {
            errors.NEETRank = 'NEET Rank is required';
        }
        if (!state.NEETMark) {
            errors.NEETMark = 'NEET Mark is required';
        }
        if (!state.DGHSallotment) {
            errors.DGHSallotment = 'DGHS Allotment is required';
        }
        if (!state.noOfAttempts) {
            errors.noOfAttempts = 'No. of Attempts in NEET is required';
        }
        if (!state.ABCid) {
            errors.ABCid = 'ABC Id is required';
        }
        if (!state.antiraggingRefNo) {
            errors.antiraggingRefNo = 'Antiragging Declaration Reference No is required';
        }
        if (!state.residentialStatus) {
            errors.residentialStatus = 'Residential Status is required';
        }

        return errors;
    }

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row className='mt-4' style={{ width: "100%" }}>
                    <Col>
                        <Button variant="primary" type="button"
                            style={{
                                alignItems: 'center',
                                width: '150px',
                                borderRadius: '50px',
                                padding: '10px 10px',
                            }}
                            onClick={goToDashboard}
                        >
                            Go To Dashboard
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                        <Button variant="primary" type="button"
                            style={{
                                alignItems: 'center',
                                width: '150px',
                                borderRadius: '50px',
                                padding: '10px 10px',
                            }}
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-4 mb-5' >
                    <Card style={{ width: "100%", padding: 16, backgroundColor: "#EADDCA" }}>
                        <Form>
                            {/*       <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>You have temporary UIN?</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="haveTemporaryUIN"
                                            value="Yes"
                                            checked={state.haveTemporaryUIN === 'Yes'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>Yes</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="haveTemporaryUIN"
                                            value="No"
                                            checked={state.haveTemporaryUIN === 'No'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>No</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {state.haveTemporaryUIN === "Yes" &&
                                <Row className="mt-2">
                                    <Col xs={12} sm={5}><strong>Enter your temporary UIN</strong></Col>
                                    <Col xs={12} sm={5}>
                                        <Form.Control
                                            type='text'
                                            name='registrationNo'
                                            value={state.registrationNo}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={2} style={{ textAlign: "center" }}>
                                        <Button variant="primary" type="button"
                                            style={{
                                                alignItems: 'center',
                                                width: '100px',
                                                borderRadius: '10px',
                                                padding: '5px 5px'
                                            }}
                                            className="w-70"
                                            onClick={GetDataByTemporaryUIN}
                                        >Submit</Button>
                                    </Col>
                                </Row>
                            } */}
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Select Programmes</strong>
                                    <span className='required'>*</span>
                                    {errors.programmes && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.programmes}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="programmes"
                                            value="MBBS"
                                            checked={state.programmes === 'MBBS'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>MBBS</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="programmes"
                                            value="BDS"
                                            checked={state.programmes === 'BDS'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>BDS</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="programmes"
                                            value="MD"
                                            checked={state.programmes === 'MD'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>MD</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="programmes"
                                            value="MS"
                                            checked={state.programmes === 'MS'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>MS</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="programmes"
                                            value="MDS"
                                            checked={state.programmes === 'MDS'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>MDS</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Select Institute</strong>
                                    <span className='required'>*</span>
                                    {errors.institute && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.institute}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select onChange={onChangeInput} name='institute' value={state.institute}>
                                        {getInstituteOptions()}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Select Course</strong>
                                    <span className='required'>*</span>
                                    {errors.course && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.course}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select onChange={onChangeInput} name='course' value={state.course}>
                                        {getCourseOptions()}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Name of the Student</strong><span className='required'>*</span><br />
                                    <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(as in School Certificate)</span>
                                    {errors.applicantName && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.applicantName}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='applicantName'
                                        value={state.applicantName}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Gender</strong>
                                    <span className='required'>*</span>
                                    {errors.gender && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.gender}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="gender"
                                            value="Male"
                                            checked={state.gender === 'Male'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>Male</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-2"
                                            name="gender"
                                            value="Female"
                                            checked={state.gender === 'Female'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>Female</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-2"
                                            name="gender"
                                            value="Third Gender"
                                            checked={state.gender === 'Third Gender'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>Third Gender</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Date of Birth</strong>
                                    <span className='required'>*</span>
                                    {errors.dateofbirth && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.dateofbirth}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='Date'
                                        name='dateofbirth'
                                        value={state.dateofbirth}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Place of Birth, District & State</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='placeOfBirth'
                                        value={state.placeOfBirth}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Nationality</strong>
                                    <span className='required'>*</span>
                                    {errors.nationality && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.nationality}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='nationality'
                                        value={state.nationality}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Religion</strong>
                                    <span className='required'>*</span>
                                    {errors.religion && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.religion}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select name='religion' onChange={onChangeInput} value={state.religion}>
                                        <option value="">Select Religion</option>
                                        <option value="Hindu">Hindu</option>
                                        <option value="Muslim">Muslim</option>
                                        <option value="Christian">Christian</option>
                                        <option value="Buddhist">Buddhist</option>
                                        <option value="Jain">Jain</option>
                                        <option value="Sikh">Sikh</option>
                                        <option value="Others">Others</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Community</strong>
                                    <span className='required'>*</span>
                                    {errors.community && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.community}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            name="community"
                                            value="SC"
                                            checked={state.community === "SC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>SC</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            name="community"
                                            value="ST"
                                            checked={state.community === "ST"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>ST</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            name="community"
                                            value="MBC"
                                            checked={state.community === "MBC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>MBC</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            name="community"
                                            value="BC"
                                            checked={state.community === "BC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>BC</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            name="community"
                                            value="OBC"
                                            checked={state.community === "OBC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>OBC</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            name="community"
                                            value="OC"
                                            checked={state.community === "OC"}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}>OC</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Name of Father / Guardian</strong>
                                    <span className='required'>*</span>
                                    {errors.parentName && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.parentName}</span>}
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='parentName'
                                        value={state.parentName}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Name of the Mother</strong><span className='required'>*</span>  {errors.motherName && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.motherName}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='motherName'
                                        value={state.motherName}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Father Mobile No</strong><span className='required'>*</span>  {errors.fatherMobileNo && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.fatherMobileNo}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='fatherMobileNo'
                                        value={state.fatherMobileNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Mother Mobile No</strong><span className='required'>*</span>  {errors.motherMobileNo && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.motherMobileNo}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='motherMobileNo'
                                        value={state.motherMobileNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Father Landline No</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='fatherLandlineNo'
                                        value={state.fatherLandlineNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Mother Landline No</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='motherLandlineNo'
                                        value={state.motherLandlineNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Father Mail Id</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='fatherMailId'
                                        value={state.fatherMailId}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Mother Mail Id</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='motherMailId'
                                        value={state.motherMailId}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Father Occupation</strong><span className='required'>*</span>  {errors.fatherOccupation && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.fatherOccupation}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='fatherOccupation'
                                        value={state.fatherOccupation}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Mother Occupation</strong><span className='required'>*</span>  {errors.motherOccupation && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.motherOccupation}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='motherOccupation'
                                        value={state.motherOccupation}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Father Office Address</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='fatherOfficeAddress'
                                        as="textarea"
                                        value={state.fatherOfficeAddress}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Mother Office Address</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='motherOfficeAddress'
                                        as="textarea"
                                        value={state.motherOfficeAddress}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Permanent Address</strong><span className='required'>*</span>  {errors.address && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.address}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='address'
                                        as="textarea"
                                        value={state.address}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Communication Address</strong><span className='required'>*</span>  {errors.communicationAddress && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.communicationAddress}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='communicationAddress'
                                        as="textarea"
                                        value={state.communicationAddress}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Mail Id</strong><span className='required'>*</span>  {errors.mailid && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.mailid}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='email'
                                        name='mailid'
                                        value={state.mailid}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Phone No</strong><span className='required'>*</span>  {errors.mobileNo && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.mobileNo}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='mobileNo'
                                        value={state.mobileNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Aadhar No</strong><span className='required'>*</span>  {errors.aadharNo && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.aadharNo}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='aadharNo'
                                        value={state.aadharNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={12}><strong>Details of H.S.C. Examination</strong></Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Board<span className='required'>*</span>  {errors.HSCboard && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.HSCboard}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='HSCboard'
                                        value={state.HSCboard}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Name of Examination<span className='required'>*</span>  {errors.HSCexaminationName && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.HSCexaminationName}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='HSCexaminationName'
                                        value={state.HSCexaminationName}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Month and Year of Passing<span className='required'>*</span>  {errors.HSCmonthandyearofpassing && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.HSCmonthandyearofpassing}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='HSCmonthandyearofpassing'
                                        value={state.HSCmonthandyearofpassing}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Name of School and Address<span className='required'>*</span>  {errors.HSCnameandaddress && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.HSCnameandaddress}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='HSCnameandaddress'
                                        as="textarea"
                                        value={state.HSCnameandaddress}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Grade (for overseas students)</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='HSCgrade'
                                        as="textarea"
                                        value={state.HSCgrade}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Attempt in which Qualifying Examination is Passed<span className='required'>*</span>  {errors.HSCattempt && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.HSCattempt}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="HSCattempt"
                                            value="First"
                                            checked={state.HSCattempt === 'First'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}> First</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="HSCattempt"
                                            value="Second"
                                            checked={state.HSCattempt === 'Second'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}> Second</span>

                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="HSCattempt"
                                            value="Third"
                                            checked={state.HSCattempt === 'Third'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}> Third</span>

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={12}><strong>Marks / Grade obtained in the qualifying examination:</strong></Col>
                            </Row>
                            <Row className='mt-2' style={{ textAlign: "center" }}>
                                <Row className='mt-2'>
                                    <Col xs={12} sm={4}><strong>Subject</strong></Col>
                                    <Col xs={12} sm={4}><strong>Marks Obtained</strong></Col>
                                    <Col xs={12} sm={4}><strong>Maximum Marks</strong></Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='text'
                                            name='LanguageSubjectName'
                                            value={state.LanguageSubjectName}
                                            placeholder='Enter Language Subject Name'
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='LanguageMarkObtained'
                                            value={state.LanguageMarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='LanguageMaxMark'
                                            value={state.LanguageMaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={12} sm={4}>English</Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='EnglishMarkObtained'
                                            value={state.EnglishMarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='EnglishMaxMark'
                                            value={state.EnglishMaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={12} sm={4}>Physics</Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='PhysicsMarkObtained'
                                            value={state.PhysicsMarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='PhysicsMaxMark'
                                            value={state.PhysicsMaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={12} sm={4}>Chemistry</Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='ChemistryMarkObtained'
                                            value={state.ChemistryMarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='ChemistryMaxMark'
                                            value={state.ChemistryMaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className='mt-2' style={{ display: BiologyDisplay }}>
                                    <Col xs={12} sm={4}>Biology</Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='BiologyMarkObtained'
                                            value={state.BiologyMarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='BiologyMaxMark'
                                            value={state.BiologyMaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>

                                <Row className='mt-2' style={{ display: disabled }}>
                                    <Col xs={12} sm={4}>Botany</Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='BotanyMarkObtained'
                                            value={state.BotanyMarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='BotanyMaxMark'
                                            value={state.BotanyMaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className='mt-2' style={{ display: disabled }}>
                                    <Col xs={12} sm={4}>Zoology</Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='ZoologyMarkObtained'
                                            value={state.ZoologyMarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='ZoologyMaxMark'
                                            value={state.ZoologyMaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={12} sm={4}>Mathematics</Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='MathematicsMarkObtained'
                                            value={state.MathematicsMarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='MathematicsMaxMark'
                                            value={state.MathematicsMaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='text'
                                            name='OtherSubjectName1'
                                            value={state.OtherSubjectName1}
                                            placeholder='Enter Other Subject Name'
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='OtherSubject1MarkObtained'
                                            value={state.OtherSubject1MarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='OtherSubject1MaxMark'
                                            value={state.OtherSubject1MaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='text'
                                            name='OtherSubjectName2'
                                            value={state.OtherSubjectName2}
                                            placeholder='Enter Other Subject Name'
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='OtherSubject2MarkObtained'
                                            value={state.OtherSubject2MarkObtained}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Form.Control
                                            type='number'
                                            name='OtherSubject2MaxMark'
                                            value={state.OtherSubject2MaxMark}
                                            onChange={onChangeInput}
                                            className="no-spin-buttons"
                                            onKeyDown={handleKeyDown}
                                            min={0}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            </Row>

                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Total Marks</Col>
                                <Col xs={12} sm={6}>
                                    {state.TotalMarks}
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Total Percentage of Marks</Col>
                                <Col xs={12} sm={6}>
                                    {state.TotalPercentageofMarks}
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>PCB Percentage</Col>
                                <Col xs={12} sm={6}>
                                    {state.PCBpercentage}
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Are you the First Graduate in your family to enroll for the UG programme</strong><span className='required'>*</span>  {errors.firstGraduate && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.firstGraduate}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="firstGraduate"
                                            value="Yes"
                                            checked={state.firstGraduate === 'Yes'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}> Yes</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="firstGraduate"
                                            value="No"
                                            checked={state.firstGraduate === 'No'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}> No</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Annual Family Income</strong><span className='required'>*</span>  {errors.annualFamilyIncome && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.annualFamilyIncome}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='number'
                                        name='annualFamilyIncome'
                                        value={state.annualFamilyIncome}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={12}><strong>NEET / DGHS Details</strong></Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Roll No<span className='required'>*</span>  {errors.NEETRollNo && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.NEETRollNo}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='NEETRollNo'
                                        value={state.NEETRollNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Marks<span className='required'>*</span>  {errors.NEETMark && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.NEETMark}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='NEETMark'
                                        value={state.NEETMark}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>AIR<span className='required'>*</span>  {errors.NEETRank && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.NEETRank}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='NEETRank'
                                        value={state.NEETRank}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>No. of Attempts in NEET Already Made<span className='required'>*</span>  {errors.noOfAttempts && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.noOfAttempts}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='number'
                                        name='noOfAttempts'
                                        value={state.noOfAttempts}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}>Allotment of Seat Made by DGHS<span className='required'>*</span>  {errors.DGHSallotment && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.DGHSallotment}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="DGHSallotment"
                                            value="First"
                                            checked={state.DGHSallotment === 'First'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "10px" }}> First</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="DGHSallotment"
                                            value="Second"
                                            checked={state.DGHSallotment === 'Second'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "10px" }}> Second</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="DGHSallotment"
                                            value="Third Round"
                                            checked={state.DGHSallotment === 'Third Round'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "10px" }}> Third Round</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="DGHSallotment"
                                            value="Stray Vacancy Round"
                                            checked={state.DGHSallotment === 'Stray Vacancy Round'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "10px" }}> Stray Vacancy Round</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>ABC id</strong><span className='required'>*</span>  {errors.ABCid && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.ABCid}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='ABCid'
                                        value={state.ABCid}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>UGC Antiragging declaration Reference No</strong><span className='required'>*</span>  {errors.antiraggingRefNo && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.antiraggingRefNo}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='antiraggingRefNo'
                                        value={state.antiraggingRefNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12} sm={6}><strong>Residential Status</strong><span className='required'>*</span>  {errors.residentialStatus && <span style={{ color: "red", fontSize: '15px', fontStyle: 'italic' }}>{errors.residentialStatus}</span>}</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="residentialStatus"
                                            value="Hostel"
                                            checked={state.residentialStatus === 'Hostel'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}> Hostel</span>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            id="option-1"
                                            name="residentialStatus"
                                            value="Day Scholar"
                                            checked={state.residentialStatus === 'Day Scholar'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <span style={{ marginRight: "20px" }}> Day Scholar</span>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                state.residentialStatus === "Hostel" &&
                                <Row className="mt-2">
                                    <Col xs={12} sm={6}><strong>Dietary Preference</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Check
                                                inline
                                                label="Vegetarian"
                                                type="radio"
                                                id="option-1"
                                                name="dietaryPreference"
                                                value="Vegetarian"
                                                checked={state.dietaryPreference === 'Vegetarian'}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="Non Vegetarian"
                                                type="radio"
                                                id="option-1"
                                                name="dietaryPreference"
                                                value="Non Vegetarian"
                                                checked={state.dietaryPreference === 'Non Vegetarian'}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                            {
                                state.residentialStatus === "Day Scholar" &&
                                <Row className="mt-2">
                                    <Col xs={12} sm={6}><strong>Select Bus Stop</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Select onChange={onChangeInput} name='busStops' value={state.busStops}>
                                            <option>Select Bus Stops</option>
                                            <option name="busStops" value="OT Manikoondu (Cuddalore)">OT Manikoondu (Cuddalore)</option>
                                            <option name="busStops" value="Committee (Cuddalore)">Committee (Cuddalore)</option>
                                            <option name="busStops" value="KV Tex (Cuddalore)">KV Tex (Cuddalore)</option>
                                            <option name="busStops" value="GRT (Cuddalore)">GRT (Cuddalore)</option>
                                            <option name="busStops" value="New Cinemas (Cuddalore)">New Cinemas (Cuddalore)</option>
                                            <option name="busStops" value="Post Office (Cuddalore)">Post Office (Cuddalore)</option>
                                            <option name="busStops" value="G.H. (Cuddalore)">G.H. (Cuddalore)</option>
                                            <option name="busStops" value="Blind School (Cuddalore)">Blind School (Cuddalore)</option>
                                            <option name="busStops" value="KNC (Cuddalore)">KNC (Cuddalore)</option>
                                            <option name="busStops" value="Semandalam (Cuddalore)">Semandalam (Cuddalore)</option>
                                            <option name="busStops" value="Dhanalakshmi Nagar (Cuddalore)">Dhanalakshmi Nagar (Cuddalore)</option>
                                            <option name="busStops" value="Gundu Salai (Cuddalore)">Gundu Salai (Cuddalore)</option>
                                            <option name="busStops" value="Aalpettai (Cuddalore)">Aalpettai (Cuddalore)</option>
                                            <option name="busStops" value="Gankanakuppam (Cuddalore)">Gankanakuppam (Cuddalore)</option>
                                            <option name="busStops" value="Aarupadai Hostel (Cuddalore)">Aarupadai Hostel (Cuddalore)</option>
                                            <option name="busStops" value="Gorimedu">Gorimedu</option>
                                            <option name="busStops" value="Subbaiya">Subbaiya</option>
                                            <option name="busStops" value="Thattanchavdy">Thattanchavdy</option>
                                            <option name="busStops" value="Estate">Estate</option>
                                            <option name="busStops" value="Rajiv Gandhi">Rajiv Gandhi</option>
                                            <option name="busStops" value="Indira Gandhi">Indira Gandhi</option>
                                            <option name="busStops" value="Nellithope">Nellithope</option>
                                            <option name="busStops" value="New Bus Stand">New Bus Stand</option>
                                            <option name="busStops" value="Antony church">Antony church</option>
                                            <option name="busStops" value="A.F.T">A.F.T</option>
                                            <option name="busStops" value="Murungapakkam">Murungapakkam</option>
                                            <option name="busStops" value="Pillaiyar Kovil">Pillaiyar Kovil</option>
                                            <option name="busStops" value="Ponniyaman Kovil">Ponniyaman Kovil</option>
                                            <option name="busStops" value="Vaanavil">Vaanavil</option>
                                            <option name="busStops" value="Naavalar">Naavalar</option>
                                            <option name="busStops" value="Vallalar School">Vallalar School</option>
                                            <option name="busStops" value="Oulgaret Sandhai">Oulgaret Sandhai</option>
                                            <option name="busStops" value="Iyyanar Kovil">Iyyanar Kovil</option>
                                            <option name="busStops" value="Latha Steels">Latha Steels</option>
                                            <option name="busStops" value="Sarathambal Kovil">Sarathambal Kovil</option>
                                            <option name="busStops" value="Kaatupalayam">Kaatupalayam</option>
                                            <option name="busStops" value="Anugraga">Anugraga</option>
                                            <option name="busStops" value="Reddiyarpalayam">Reddiyarpalayam</option>
                                            <option name="busStops" value="Cauvery Nagar">Cauvery Nagar</option>
                                            <option name="busStops" value="Ajith Nagar">Ajith Nagar</option>
                                            <option name="busStops" value="Pazhva Nagar">Pazhva Nagar</option>
                                            <option name="busStops" value="Kamachi">Kamachi</option>
                                            <option name="busStops" value="R.T.O">R.T.O</option>
                                            <option name="busStops" value="Nainarmandapam">Nainarmandapam</option>
                                            <option name="busStops" value="Edayarpalayam">Edayarpalayam</option>
                                            <option name="busStops" value="Thavalakuppam">Thavalakuppam</option>
                                            <option name="busStops" value="Reddychavadi">Reddychavadi</option>
                                            <option name="busStops" value="Ginger Hotel">Ginger Hotel</option>
                                            <option name="busStops" value="Bharathidasan College">Bharathidasan College</option>
                                            <option name="busStops" value="Gingee Salai">Gingee Salai</option>
                                            <option name="busStops" value="Law College">Law College</option>
                                            <option name="busStops" value="Uppalam Stadium">Uppalam Stadium</option>
                                            <option name="busStops" value="Uppalam Kallarai">Uppalam Kallarai</option>
                                            <option name="busStops" value="Kandhasamy House">Kandhasamy House</option>
                                            <option name="busStops" value="Mudaliyarpettai">Mudaliyarpettai</option>
                                            <option name="busStops" value="Ariyankuppam Police Station">Ariyankuppam Police Station</option>
                                            <option name="busStops" value="Boat House">Boat House</option>
                                            <option name="busStops" value="Poornakuppam">Poornakuppam</option>
                                            <option name="busStops" value="Sivaji Statue ">Sivaji Statue </option>
                                            <option name="busStops" value="Marapaalam ">Marapaalam </option>
                                            <option name="busStops" value="Muthiyalpet Police Station">Muthiyalpet Police Station</option>
                                            <option name="busStops" value="Anandha Ranga">Anandha Ranga</option>
                                            <option name="busStops" value="Saram">Saram</option>
                                            <option name="busStops" value="Balaji Theatre">Balaji Theatre</option>
                                            <option name="busStops" value="JeevaRukmani Theatre">JeevaRukmani Theatre</option>
                                            <option name="busStops" value="Raja Theatre">Raja Theatre</option>
                                            <option name="busStops" value="Old Bus Stand">Old Bus Stand</option>
                                            <option name="busStops" value="Ariyankuppam">Ariyankuppam</option>
                                            <option name="busStops" value="Rani Hospital">Rani Hospital</option>
                                            <option name="busStops" value="Kathirkamam">Kathirkamam</option>
                                            <option name="busStops" value="Shanmugapuram">Shanmugapuram</option>
                                            <option name="busStops" value="Mettupalayam">Mettupalayam</option>
                                            <option name="busStops" value="Moolakulam">Moolakulam</option>
                                            <option name="busStops" value="Oulgaret">Oulgaret</option>
                                            <option name="busStops" value="Jaya Nagar">Jaya Nagar</option>
                                            <option name="busStops" value="Kirumampakam">Kirumampakam</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            }
                            {
                                state.residentialStatus === "Hostel" &&
                                <div>
                                    <Row className="mt-2">
                                        <Col xs={12} sm={6}><strong>Local Guardian Name</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                name='localGuardianName'
                                                value={state.localGuardianName}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs={12} sm={6}><strong>Local Guardian Mobile No</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                name='localGuardianMobileNo'
                                                value={state.localGuardianMobileNo}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs={12} sm={6}><strong>Local Guardian Mail Id</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                name='localGuardianMailId'
                                                value={state.localGuardianMailId}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs={12} sm={6}><strong>Local Guardian Address</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                name='localGuardianAddress'
                                                as="textarea"
                                                value={state.localGuardianAddress}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row className='mt-3'>
                                <Col>
                                    <Form.Check
                                        type="checkbox"
                                        id="myCheckbox"
                                        name='declarationAgree'
                                        checked={state.declarationAgree}
                                        onChange={onChangeInput}
                                        label={
                                            <div>
                                                <span>
                                                    <strong>
                                                        I {state.applicantName} hereby solemnly and sincerely affirm that statement made and information furnished in my application
                                                        form and also in all the enclosures submitted by me are true. Should in however by found that any information furnished herein is untrue,
                                                        I realise that I am liable for criminal prosecution and agree to forgot my seat, at any stage.
                                                    </strong>
                                                </span>
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col style={{ textAlign: "center" }}>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        style={{
                                            alignItems: 'center',
                                            width: '150px',
                                            borderRadius: '50px',
                                            padding: '10px 20px',
                                        }}
                                        className="w-70"
                                        onClick={SaveandNext}
                                    >
                                        Save & Next
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Row>
            </Container>
            <Modal
                show={showRemarkModal}
                onHide={CloseRemarkModal}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <img
                        src={prerequest}
                        style={{
                            width: '100%',
                            height: '370px',
                            display: 'block',
                            cursor: 'pointer'
                        }}
                        alt="Prerequest"
                        onClick={redirectSBVwelcomepage}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => setshowRemarkModal(false)}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MBBSandBDSstudentRegistration