import { React, useState, useEffect } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap';
import { AutoComplete, Input } from 'antd';
import Spinner from './Spinner';
import swal from 'sweetalert';
import {
    getAHSapplicationDataforUpdatePaymentStatus,
    UpdateAHSapplicationPaymentStatus
} from './services';


function UpdatePaymentStatus() {
    const [spinner, setspinner] = useState(false);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [applicationData, setapplicationData] = useState([]);
    const [selectedApplicationPaymentStatus, setselectedApplicationPaymentStatus] = useState('');
    const [paymentID, setpaymentID] = useState('');
    const [OrderID, setOrderID] = useState('');


    const filterData = (input) => {
        if (!input) {
            setValue('');
            setselectedApplicationPaymentStatus('');
            return [];
        } else {
            setValue(input);
            return applicationData.filter(item => item.applicationNo.toLowerCase().includes(input.toLowerCase()))
                .map(item => ({
                    value: item._id,
                    label: `${item.applicationNo} - ${item.name}`,
                    item
                }));
        }
    };

    const onSelect = (id, option) => {
        setSelectedId(id);
        setValue(option.label); // Display the combined label in the input
    };

    const getApplicationData = async () => {
        try {
            const result = await getAHSapplicationDataforUpdatePaymentStatus();
            setapplicationData(result);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        setselectedApplicationPaymentStatus('');
        applicationData.map((obj) => {
            if (selectedId === obj._id) {
                setselectedApplicationPaymentStatus(obj.paymentStatus)
            }
        })
    }, [selectedId]);

    useEffect(() => {
        getApplicationData();
    }, []);

    const submit = async () => {
        if (selectedId && paymentID && OrderID) {
            try {
                setspinner(true);
                var obj = {
                    "_id": selectedId,
                    "paymentId": paymentID,
                    "orderId": OrderID
                };
                console.log(obj);
                const result = await UpdateAHSapplicationPaymentStatus(obj);
                setSelectedId('');
                setOrderID('');
                setpaymentID('');
                setValue('');
                setselectedApplicationPaymentStatus('');
                setspinner(false);
                getApplicationData();
                swal({
                    title: "Payment status updated",
                    icon: 'success',
                })
            } catch (err) {
                console.log(err);
            }
        } else {
            swal({
                title: "All fields are required",
                icon: 'info',
            })
        }
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Row className='mt-3'>
                <Col style={{ textAlign: 'center', color: "#ba0af0" }}>
                    <h5>Update Payment Status</h5>
                </Col>
            </Row>
            <Card style={{ width: "50%", margin: "25px 25% 25px 25%", padding: "25px" }}>
                <Row style={{ textAlign: "center" }}>
                    <Col xs={12} sm={12}>
                        <AutoComplete
                            value={value}
                            options={options}
                            style={{
                                width: 300,
                            }}
                            onSelect={onSelect}
                            onSearch={(text) => setOptions(filterData(text))}
                            placeholder="Enter your application number"
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12} sm={6}><strong>Existing Payment Status</strong></Col>
                    <Col xs={12} sm={6}
                        style={{
                            color: selectedApplicationPaymentStatus === 'Success' ? '#8BC34A' : '#F44336',
                            fontWeight: "bold"
                        }}
                    >{selectedApplicationPaymentStatus}</Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12} sm={6}>
                        <Input placeholder="Payment Id"
                            onChange={(e) => setpaymentID(e.target.value)}
                            value={paymentID}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Input placeholder="Order Id"
                            onChange={(e) => setOrderID(e.target.value)}
                            value={OrderID}
                        />
                    </Col>
                </Row>
                <Row className='mt-4' style={{ textAlign: "center" }}>
                    <Col xs={12} sm={12}>
                        <Button
                            onClick={submit}
                        >Update</Button>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default UpdatePaymentStatus