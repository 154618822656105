import React from 'react';
import { Form, Button, Container, Row, Col, Card, Table, Modal } from 'react-bootstrap';
import SwipeableViews from 'react-swipeable-views';
import {
    GetApplicationsByInstitute,
    GetAllApplications,
    ApplicationVerification,
    GetAllVerifiedCount,
    InstituteWiseVerifiedCount,
    DataInXL,
    SaveRemark,
    ReminderMail,
    DownloadApplication,
    applicationRejected,
    applicationNotEligible,
    getCourseName,
    DownloadHallTicket,
    getInstituteforApplication
} from './services';
import Spinner from './Spinner';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
const callLetterCourses =
    [
        "Poat Graduate Diploma In Music Therapy",
        "M.Sc.Medical Music Therapy",
        "Post Graduate Certificate In Yoga Therapy",
        "Post Graduate Diploma In Yoga Therapy",
        "M.Sc.Yoga Therapy",
        "Masters In Health Professions Education",
        "B.Sc. in Biomedical Sciences",
        "B.Sc.(Hons) in Biomedical Sciences",
        "M.Sc. in Biomedical Sciences",
        "M.Sc. + Ph.D. in Biomedical Sciences",
        "PG Diploma in Bio-Industrial Applications",
        "M.Sc.Medical MicroBiology",
        "M.Sc.Medical Biochemistry",
        "M.Sc.Nurse Practitioner In Critical Care Nursing",
        "Post Graduate Certificate In Health  Science Library Information Technology",
        "Post Graduate Diploma In Pharmacogenomics",
        "Post Graduate Diploma In Good Clinic Laboratory Practices",
        "M.Sc.Medical Laboratory Technology In Clinical Chemistry",
        "M.Sc.Clinical Nutrition",
        "M.Sc.Medical Laboratory Technology In Pathology",
        "M.Sc.Echocardiography",
        "M.Sc.Cardiac Catheterization And Intervention",
        "M.Sc.Medical Imaging Technology"
    ];

function ApplicationApprove() {
    const navigate = useNavigate();
    const [selectedInstitute, setSelectedInstitute] = React.useState('');
    const [selectedBatch, setselectedBatch] = React.useState('');
    const [applications, setapplications] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [pageIndex, setPageIndex] = React.useState(0);
    const [filePath, setFilePath] = React.useState('');
    const [isPdf, setIsPdf] = React.useState(false);
    const [verifiedCount, setverifiedCount] = React.useState(0);
    const [showRemarkModal, setshowRemarkModal] = React.useState(false);
    const [remark, setremark] = React.useState('');
    const [spinner, setspinner] = React.useState(false);
    const [campus, setCampus] = React.useState('');
    const [instituteList, setinstituteList] = React.useState([]);

    const handleSelectChange = (event) => {
        setSelectedInstitute(event.target.value);
    };

    const CloseRemarkModal = () => {
        setremark('');
        setshowRemarkModal(false);
    };

    const remarkSubmit = async () => {
        try {
            const mailid = applications[pageIndex].mailid;
            const id = applications[pageIndex]._id;
            const result = await SaveRemark({ "remark": remark, "id": id, "mailid": mailid });
            swal({
                title: "Remark Mail Sent Successfully.",
                icon: 'success'
            });
            CloseRemarkModal();
            onNext();
        }
        catch (err) {
            console.log(err);
        }
    };

    const submit = async () => {
        try {
            if (selectedInstitute && selectedBatch && campus) {
                setspinner(true);
                const data = await GetApplicationsByInstitute({ "instituteId": selectedInstitute, "Batch": selectedBatch, "campus": campus });
                setspinner(false);
                if (data.length > 0) {
                    setapplications(data);
                    VerifiedCountInInstitute();
                }
                else {
                    setapplications([]);
                    swal({
                        title: "No Record Found.",
                        icon: 'info'
                    });
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const getAllApplications = async () => {
        try {
            if (selectedBatch) {
                setspinner(true);
                const result = await GetAllApplications({ "Batch": selectedBatch });
                setspinner(false);
                if (result.length > 0) {
                    setapplications(result);
                    setSelectedInstitute('');
                    allVerifiedCount();
                }
                else {
                    setapplications([]);
                    swal({
                        title: "No Record Found.",
                        icon: 'info'
                    });
                }
            } else {
                swal({
                    title: "Please Select Batch",
                    icon: 'info'
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const viwApplicationData = (_id) => {
        const copyApplications = [...applications];
        const selectedIndex = copyApplications.findIndex((application) => application._id === _id);
        copyApplications[selectedIndex].initialView = true;

        setapplications(copyApplications);
        setPageIndex(selectedIndex);
        setShowModal(true);
    }

    const onNext = () => {
        setPageIndex((prevIndex) => {
            if (prevIndex < applications.length - 1) {
                return prevIndex + 1;
            }

            return prevIndex;
        });
        setFilePath('')
    };

    const onPrevious = () => {
        setPageIndex((prevIndex) => {
            if (prevIndex > 0) {
                return prevIndex - 1;
            }

            return prevIndex;
        });
        setFilePath('')
    };

    const viwdocument = (file) => {
        const fileNameArray = file.filePath.split('.');
        const extension = fileNameArray[fileNameArray.length - 1].toLowerCase();
        setFilePath(process.env.REACT_APP_SERVICE_URL + '/documents/' + file.filePath);
        setIsPdf(extension === 'pdf');
    };

    const verified = async () => {
        try {
            const id = applications[pageIndex]._id;
            await ApplicationVerification({ "_id": id });
            onNext();
            if (selectedInstitute) {
                VerifiedCountInInstitute();
            }
            else {
                allVerifiedCount();
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        if (selectedInstitute) {
            submit();
        }
        else {
            getAllApplications();
        }
    };

    async function allVerifiedCount() {
        try {
            const count = await GetAllVerifiedCount();
            setverifiedCount(count.Verifiedcount);
        }
        catch (err) {
            console.log(err);
        }
    };

    async function VerifiedCountInInstitute() {
        try {
            const count = await InstituteWiseVerifiedCount({ "institute": selectedInstitute });
            setverifiedCount(count.Verifiedcount);
        }
        catch (err) {
            console.log(err);
        }
    };

    const downloadXL = async () => {
        if (selectedBatch) {
            setspinner(true);
            try {
                const data = await DataInXL({ "Batch": selectedBatch, "Institute": selectedInstitute });
                const addfilepath = data.map((obj) => {
                    if (obj.uploadDocuments.length > 0) {
                        obj.uploadDocuments.map((file) => {
                            file.filePath = `${process.env.REACT_APP_SERVICE_URL}/documents/` + file.filePath;
                            return file;
                        })
                    }
                    return obj;
                });
                const xldata = addfilepath.map((object) => {
                    if (object.uploadDocuments.length > 0) {
                        const keyValues = object.uploadDocuments.reduce((acc, curr) => {
                            acc[curr.documentName] = curr.filePath;
                            return acc;
                        }, {});
                        return { ...object, ...keyValues };
                    }
                    return object;
                });

                const downloaddata = xldata.map((object) => {
                    var studMarks = {};
                    if (object.diplomaDegreeMarks.length > 0) {
                        object.diplomaDegreeMarks.forEach((mark, index) => {
                            var keys = Object.keys(mark);

                            keys.forEach((key) => {
                                studMarks[key + '_' + index] = mark[key];

                            });
                        })
                        object = { ...object, ...studMarks };
                    }
                    return object;
                });

                const finalData1 = downloaddata.map((object) => {
                    var IHPE_UG_to_Masters = {};
                    if (object.IHPE_UG_to_Masters.length > 0) {
                        object.IHPE_UG_to_Masters.forEach((mark, index) => {
                            var keys = Object.keys(mark);
                            keys.forEach((key) => {
                                IHPE_UG_to_Masters[key + '_' + index] = mark[key];

                            });
                        })
                        object = { ...object, ...IHPE_UG_to_Masters };
                    }
                    return object;
                });

                const finalData2 = finalData1.map((object) => {
                    var Experience = {};
                    if (object.experience.length > 0) {
                        object.experience.forEach((rowdata, index) => {
                            var keys = Object.keys(rowdata);
                            keys.forEach((key) => {
                                Experience[key + '_' + index] = rowdata[key];
                            })
                        })
                        object = { ...object, ...Experience };
                    }
                    return object;
                });

                finalData2.forEach(object => {
                    delete object.diplomaDegreeMarks;
                    delete object.uploadDocuments;
                    delete object.experience;
                    delete object.IHPE_UG_to_Masters;
                });
                const worksheet = XLSX.utils.json_to_sheet(finalData2);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                const fileBuffer = await XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

                const blob = new Blob([fileBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "applications.xlsx";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setspinner(false);
            }
            catch (err) {
                console.log(err);
            }

        } else {
            swal({
                title: "Please Select Batch",
                icon: 'info'
            });

        }
    };

    const downloadInpdf = async (obj) => {
        try {
            const response = await DownloadApplication({ "_id": obj._id });
            const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', obj.applicationNo + '.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        catch (err) {
            console.log(err);
        }
    };

    const rejected = async () => {
        try {
            const id = applications[pageIndex]._id;
            await applicationRejected({ "_id": id });
            onNext();
            if (selectedInstitute) {
                VerifiedCountInInstitute();
            }
            else {
                allVerifiedCount();
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const notEligible = async () => {
        try {
            const id = applications[pageIndex]._id;
            await applicationNotEligible({ "_id": id });
            onNext();
            if (selectedInstitute) {
                VerifiedCountInInstitute();
            }
            else {
                allVerifiedCount();
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const downloadHallTicket = async (id) => {
        try {
            const course = await getCourseName({ "_id": id });
            if (callLetterCourses.indexOf(course.courseName) > -1) {

                const response = await DownloadHallTicket({ "_id": id });
                const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Call Letter.pdf');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
            else {

                const response = await DownloadHallTicket({ "_id": id });
                const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Hall Ticket.pdf');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const getInstituteOptions = async () => {
        try {
            setspinner(true);
            const result = await getInstituteforApplication({ "campus": campus, "role": localStorage.getItem('role') });
            setinstituteList(result);
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        getInstituteOptions();
    }, [campus]);

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container className=" justify-content-center align-items-center" style={{ height: 'auto' }}>
                <Row className='mt-3'>
                    <Col className='mb-2' style={{ textAlign: 'center', color: "#ba0af0" }}>
                        <h5>UG / PG Programme (Non-NEET) Applications</h5>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col sm={1}>
                        <Row style={{ textAlign: 'start' }}>
                            <Col className='mb-2'>
                                <Button
                                    style={{ width: '150px' }}
                                    type='primary'
                                    onClick={downloadXL}
                                >Export as Excel</Button>
                            </Col>
                            <Col className='mb-2'>
                                <Button
                                    style={{ width: '150px' }}
                                    type='primary'
                                    onClick={getAllApplications}
                                >Get All Applications</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={11}>
                        <Card className='mt-2' style={{ width: '65%', height: '230px', marginLeft: '220px' }}>
                            <Container>
                                <Row className="mt-3">
                                    <Col xs={12} sm={4}>Select Campus</Col>
                                    <Col xs={12} sm={8}>
                                        <Form.Select onChange={(e) => setCampus(e.target.value)} name='campus' value={campus}>
                                            <option>Select Campus</option>
                                            <option name="institute"
                                                value="Pondicherry Campus">
                                                Pondicherry Campus
                                            </option>
                                            <option name="institute"
                                                value="Chennai Campus">
                                                Chennai Campus
                                            </option>
                                            <option name="institute"
                                                value="Karaikal Campus">
                                                Karaikal Campus
                                            </option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm={4}>Select Institute</Col>
                                    <Col sm={8}>
                                        <Form.Select
                                            onChange={handleSelectChange}
                                            value={selectedInstitute}
                                        >
                                            <option>Select Institute</option>
                                            {instituteList.map((obj) => (
                                                <option
                                                    key={obj._id}
                                                    value={obj.instituteId}>
                                                    {obj.institute}
                                                </option>
                                            ))
                                            }
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm={4}>Select Batch</Col>
                                    <Col sm={8}>
                                        <Form.Select
                                            onChange={(e) => setselectedBatch(e.target.value)}
                                            value={selectedBatch}
                                        >
                                            <option>Select Batch</option>
                                            <option name="batch" value="2023 - 2024">2023 - 2024</option>
                                            <option name="batch" value="2024 - 2025">2024 - 2025</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className='mt-3' style={{ textAlign: "center" }}>
                                    <Col>
                                        <Button
                                            type='primary'
                                            onClick={submit}
                                        >submit</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Card>
                    </Col>
                </Row>
                {
                    applications.length > 0 &&
                    <Card className='mt-3' style={{ width: '100%', height: 'auto', marginBottom: '25px' }}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>S.No</th>
                                    <th style={{ textAlign: 'center', }}>Application No</th>
                                    <th style={{ textAlign: 'center', }}>Name</th>
                                    <th style={{ textAlign: 'center', }}>Application Status</th>
                                    <th style={{ textAlign: 'center', }}>Remark</th>
                                    <th style={{ textAlign: 'center', }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    applications.map((obj, index) => (
                                        <tr style={{ padding: 0 }} key={obj._id}>
                                            <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                            <td style={{ textAlign: 'center' }}>{obj.applicationNo}</td>
                                            <td style={{ textAlign: 'center' }}>{obj.name}</td>
                                            <td style={{ textAlign: 'center' }}>{obj.applicationStatus}</td>
                                            <td style={{ textAlign: 'center', width: '500px' }}>{obj.remark}</td>
                                            <td style={{ textAlign: 'center', width: '135px' }}>
                                                <Row>
                                                    <Col>
                                                        <Button
                                                            type='primary'
                                                            size='sm'
                                                            onClick={() => viwApplicationData(obj._id)}
                                                        >view</Button>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            type='primary'
                                                            size='sm'
                                                            onClick={() => downloadInpdf(obj)}
                                                        >
                                                            &#8595;pdf</Button>
                                                    </Col>
                                                    <Row className='mt-3'>
                                                        <Col>
                                                            <Button
                                                                type='primary'
                                                                size='sm'
                                                                onClick={() => downloadHallTicket(obj._id)}
                                                                style={{ width: '100%', marginLeft: '12px' }}
                                                            >
                                                                &#8595;HallTicket</Button>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Card>
                }
                <Modal
                    show={showModal}
                    fullscreen={true}
                    onHide={() => closeModal()}
                    style={{ height: window.innerHeight }}
                >
                    <Modal.Header closeButton>
                        <Container>
                            <Row>
                                <Col style={{ color: 'blue', textAlign: 'center', fontWeight: 'bold' }}>
                                    APPLICATION VERIFICATION <span style={{ paddingLeft: '70px' }}> Count :{verifiedCount}/{applications.length}</span>
                                    <Button
                                        style={{ marginLeft: '50px' }}
                                        onClick={() => setshowRemarkModal(true)}
                                    >Remark</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Header>
                    <Modal.Body>
                        <SwipeableViews index={pageIndex}>
                            {
                                applications.map((formdata) => (
                                    <Row key={formdata._id} style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Col style={{ height: window.innerHeight, overflow: 'auto' }} md={6}>
                                            <Row className='mt-3'>
                                                <Col><strong>Application No</strong></Col>
                                                <Col>{formdata.applicationNo}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Name</strong></Col>
                                                <Col>{formdata.name}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Campus</strong></Col>
                                                <Col>{formdata.campus}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Institute</strong></Col>
                                                <Col>{formdata.institute}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Course</strong></Col>
                                                <Col>{formdata.course}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Gender</strong></Col>
                                                <Col>{formdata.gender}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Date Of Birth</strong></Col>
                                                <Col>{formdata.dateofbirth}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Age</strong></Col>
                                                <Col>{formdata.age}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>House Number / Appartment Name</strong></Col>
                                                <Col>{formdata.addressLine1}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Street</strong></Col>
                                                <Col>{formdata.addressLine2}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Locality</strong></Col>
                                                <Col>{formdata.addressLine3}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Taluk / Nagar</strong></Col>
                                                <Col>{formdata.addressLine4}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>District</strong></Col>
                                                <Col>{formdata.district}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>State</strong></Col>
                                                <Col>{formdata.statte}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Pin Code</strong></Col>
                                                <Col>{formdata.pincode}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Nationality</strong></Col>
                                                <Col>{formdata.nationality}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Religion</strong></Col>
                                                <Col>{formdata.religion}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Community</strong></Col>
                                                <Col>{formdata.community}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Economically Weaker Section</strong></Col>
                                                <Col>{formdata.weakerSection}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Aadhar No</strong></Col>
                                                <Col>{formdata.aadharNo}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Mobile No</strong></Col>
                                                <Col>{formdata.mobileNo}</Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col><strong>Mail Id</strong></Col>
                                                <Col>{formdata.mailid}</Col>
                                            </Row>
                                            {formdata.course === "Masters In Health Professions Education" &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Official Mail Id</strong></Col>
                                                        <Col>{formdata.officialmailid}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Official Phone No</strong></Col>
                                                        <Col>{formdata.officialphoneno}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Official / Institutional Address</strong></Col>
                                                        <Col>{formdata.officialaddress}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>The subject in which the candidate has qualified for the</strong></Col>
                                                        <Col>{formdata.subjectQualified}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>The above master's Degree was awarded by</strong></Col>
                                                        <Col>{formdata.degreeAwardedBy}</Col>
                                                    </Row>
                                                    {formdata.degreeAwardedBy === "Others" &&
                                                        <Row className='mt-3'>
                                                            <Col><strong>If Others, Mention degree awarded by</strong></Col>
                                                            <Col>{formdata.othersDegreeAwardedBy}</Col>
                                                        </Row>
                                                    }
                                                </div>
                                            }
                                            {
                                                formdata.degree === "UG" && formdata.course !== "Masters In Health Professions Education" &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Month / Year of Passing</strong></Col>
                                                        <Col>{formdata.hscmonthAndYearOfPassing}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Board for HSC</strong></Col>
                                                        <Col>{formdata.hscinstitution}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Marks in %</strong></Col>
                                                        <Col>{formdata.hscmarks}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Total Marks</strong></Col>
                                                        <Col>{formdata.totalMark12}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Details Of Qualifying Examination:</strong></Col>
                                                    </Row>
                                                    <Table striped bordered hover className="mt-3 text-center">
                                                        <thead>
                                                            <tr>
                                                                <th>Subject</th>
                                                                <th>Marks Obtained</th>
                                                                <th>Maximum Marks</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                typeof formdata.TFHSObtainedMark === 'number' && (
                                                                    <tr>
                                                                        <td>Tamil/French/Hindi/Sanskrit</td>
                                                                        <td>{formdata.TFHSObtainedMark}</td>
                                                                        <td>{formdata.TFHSMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                typeof formdata.englishObtainedMark === 'number' && (
                                                                    <tr>
                                                                        <td>English</td>
                                                                        <td>{formdata.englishObtainedMark}</td>
                                                                        <td>{formdata.englishMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                typeof formdata.physicsObtainedMark === 'number' && (
                                                                    <tr>
                                                                        <td>Physics</td>
                                                                        <td>{formdata.physicsObtainedMark}</td>
                                                                        <td>{formdata.physicsMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                typeof formdata.chemistryObtainedMark === 'number' && (
                                                                    <tr>
                                                                        <td>Chemistry</td>
                                                                        <td>{formdata.chemistryObtainedMark}</td>
                                                                        <td>{formdata.chemistryMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                typeof formdata.biologyObtainedMark === 'number' && (
                                                                    <tr>
                                                                        <td>Biology</td>
                                                                        <td>{formdata.biologyObtainedMark}</td>
                                                                        <td>{formdata.biologyMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                            {
                                                                typeof formdata.botanyObtainedMark === 'number' && (
                                                                    <tr>
                                                                        <td>Botany</td>
                                                                        <td>{formdata.botanyObtainedMark}</td>
                                                                        <td>{formdata.botanyMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                typeof formdata.zoologyObtainedMark === 'number' && (
                                                                    <tr>
                                                                        <td>Zology</td>
                                                                        <td>{formdata.zoologyObtainedMark}</td>
                                                                        <td>{formdata.zoologyMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                typeof formdata.mathematicsObtainedMark === 'number' && (
                                                                    <tr>
                                                                        <td>Mathematics</td>
                                                                        <td>{formdata.mathematicsObtainedMark}</td>
                                                                        <td>{formdata.mathematicsMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                typeof formdata.computerScienceObtainedMark === 'number' && (
                                                                    <tr>
                                                                        <td>Computer Science</td>
                                                                        <td>{formdata.computerScienceObtainedMark}</td>
                                                                        <td>{formdata.computerScienceMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                typeof formdata.othersObtainedMark === 'number' && (

                                                                    <tr>
                                                                        <td>Others</td>
                                                                        <td>{formdata.othersObtainedMark}</td>
                                                                        <td>{formdata.othersMaxMark}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            }
                                            {formdata.degree === "PG" && formdata.course !== "Masters In Health Professions Education" &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Month / Year of Passing</strong></Col>
                                                        <Col>{formdata.degremonthAndYearOfPassing}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Institution / University</strong></Col>
                                                        <Col>{formdata.degreinstitution}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Marks in %</strong></Col>
                                                        <Col>{formdata.degremarks}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Total Marks Obtained</strong></Col>
                                                        <Col>{formdata.totalSemesterMarksObtained}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Total Maximum Marks</strong></Col>
                                                        <Col>{formdata.totalSemesterMaxMarks}</Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Details Of Qualifying Examination:</strong></Col>
                                                    </Row>
                                                    <Table striped bordered hover className="mt-3 text-center">
                                                        <thead>
                                                            <tr>
                                                                <th>Semester</th>
                                                                <th>Month / Year of Passing</th>
                                                                <th>Marks Obtained</th>
                                                                <th>Maximum Marks</th>
                                                                <th>Marks In %</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            <tbody>
                                                                {
                                                                    formdata.diplomaDegreeMarks.map((obj) => (
                                                                        < tr key={obj._id}>
                                                                            <td>{obj.semester}</td>
                                                                            <td>{obj.semesterYearOfPassing}</td>
                                                                            <td>{obj.semesterMarksObtained}</td>
                                                                            <td>{obj.semesterMaxMarks}</td>
                                                                            <td>{obj.semesterPercentageMark}</td>
                                                                        </tr>
                                                                    ))

                                                                }
                                                            </tbody>
                                                        }
                                                    </Table>
                                                </div>
                                            }
                                            {formdata.course === "Masters In Health Professions Education" &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Academic Qualification (UG to Masters)</strong></Col>
                                                    </Row>
                                                    <Table striped bordered hover className="mt-3 text-center">
                                                        <thead>
                                                            <tr>
                                                                <th>Qualification</th>
                                                                <th>School/College</th>
                                                                <th>Board/University</th>
                                                                <th>Month & Year of Passing</th>
                                                                <th>Subject</th>
                                                                <th>% of Mark</th>
                                                                <th>Class/Rank</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                formdata.IHPE_UG_to_Masters.map((obj) => (
                                                                    < tr key={obj._id}>
                                                                        <td>{obj.IHPEqualification}</td>
                                                                        <td>{obj.IHPEschool_college}</td>
                                                                        <td>{obj.IHPEboard_university}</td>
                                                                        <td>{obj.IHPEmonth_year_passing}</td>
                                                                        <td>{obj.IHPEsubject}</td>
                                                                        <td>{obj.IHPEpercentagemark}</td>
                                                                        <td>{obj.IHPEclass_rank}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    <Row className='mt-3'>
                                                        <Col><strong>Professional/Teaching Experience</strong></Col>
                                                    </Row>
                                                    <Table striped bordered hover className="mt-3 text-center">
                                                        <thead>
                                                            <tr>
                                                                <th>Designation</th>
                                                                <th>Institution</th>
                                                                <th>From Date</th>
                                                                <th>To Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                formdata.experience.map((obj) => (
                                                                    < tr key={obj._id}>
                                                                        <td>{obj.expDesignation}</td>
                                                                        <td>{obj.expInstitution}</td>
                                                                        <td>{obj.expFromDate}</td>
                                                                        <td>{obj.expToDate}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            }
                                            <Row className='mt-3'>
                                                <Col><strong>uploaded Documents List</strong></Col>
                                            </Row>

                                            {
                                                formdata.uploadDocuments.map((obj) => (
                                                    <Row className='mt-3' key={obj._id}>
                                                        <Col>{obj.documentName}</Col>
                                                        <Col><Button
                                                            type='primary'
                                                            size='sm'
                                                            onClick={() => viwdocument(obj)}
                                                        >view</Button></Col>
                                                    </Row>
                                                ))
                                            }
                                        </Col>

                                        <Col style={{ height: window.innerHeight, overflow: 'auto' }} md={6}>
                                            {
                                                isPdf ? <iframe src={filePath} width="630px" height="630px" />
                                                    : <img src={filePath} width="630px" height="630px" alt='Image' />
                                            }

                                        </Col>
                                    </Row>
                                ))
                            }

                        </SwipeableViews>
                    </Modal.Body>
                    <Modal
                        show={showRemarkModal}
                        onHide={CloseRemarkModal}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Remark</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={remark}
                                        onChange={(e) => setremark(e.target.value)}
                                    />
                                </Form.Group>
                            </Form>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={remarkSubmit}
                            >
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Container>
                        <Row className='mb-2'>
                            <Col>
                                <Button type='primary' onClick={onPrevious}>Back</Button>
                            </Col>
                            <Col>
                                <Button type='primary' onClick={onNext}>Next</Button>
                            </Col>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: 'red' }} onClick={rejected}>Rejected</Button>
                            </Col>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: 'brown' }} onClick={notEligible}>Not Eligible</Button>
                            </Col>
                            <Col>
                                <Button style={{ backgroundColor: 'green' }} type='primary' onClick={verified}>Verify & Accept</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </Container>
        </>
    )
}

export default ApplicationApprove