import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { login } from './services';

function AdminLogin() {
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();

    const submit = async (data) => {
        if (data.email && data.password) {
            try {
                const result = await login(data);
                if (result.role === "admin") {
                    localStorage.setItem("role", result.role);
                    localStorage.setItem('permissions', result.Permissions);
                    navigate('/adminDashboard');
                } else {
                    swal({
                        title: "You are not an admin.",
                        icon: 'info'
                    });
                }
            } catch (err) {
                console.log(err);
            }
        }
        else {
            swal({
                title: "Enter Username and Password.",
                icon: 'info'
            });
        }
    };

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ height: '419px' }}>
            <div style={{ width: '440px', marginTop: '25px' }}>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit(submit)}>
                            <Form.Group controlId="formBasicUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control {...register("email")} type="text" />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control {...register("password")} type="text" />
                            </Form.Group>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <Button variant="primary" type="submit" className="mt-3" style={{ width: '80px' }}>
                                        Login
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default AdminLogin