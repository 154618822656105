import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import header from '../header.png'

function Header() {
    return (
        <Container fluid>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <img
                        src={header}
                        style={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                        }}
                        alt="header"
                    />
                </Col>
            </Row>
        </Container>

    )
}

export default Header;