import { React, useState, useEffect } from 'react';
import { Drawer, Tooltip, Divider } from 'antd';
import { List, BoxArrowRight, CaretDownFill } from 'react-bootstrap-icons';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import MBBSandBDSadmissionControl from './MBBSandBDSadmissionController';
import MD_MS_MDSadmissionControl from './MDMSadmissionController';
import AHSadmissionControl from './ApplicationApprove';
import PhDadmissionControl from './PhdAdmissionController';
import FellowshipadmissionControl from './FellowshipAdmissionController';
import EDP_FellowshipadmissionControl from './EDP_FellowshipAdmissionController';
import Open_Closeapplication from './ApplicationController';
import Bulkdownload from './applicationsBulkDownload';
import PaymentReminder from './PaymentReminder';
import AddInstitutionandCourse from './Addinstituteandcourse';
import CreateExamSchedule from './ExamSchedule';
import UpdatePayment from './UpdatePaymentStatus';


function AdminDashboard() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [MBBSandBDS, setMBBSandBDS] = useState(false);
    const [MDMSMDS, setMDMSMDS] = useState(false);
    const [AHS, setAHS] = useState(false);
    const [PhD, setPhD] = useState(false);
    const [Fellowship, setFellowship] = useState(false);
    const [EDP, setEDP] = useState(false);
    const [OpenClose, setOpenClose] = useState(false);
    const [Bulk, setBulk] = useState(false);
    const [Reminder, SetReminder] = useState(false);
    const [AddInstiandCourse, setAddInstiandCourse] = useState(false);
    const [Exam, SetExam] = useState(false);
    const [PaymentStatus, SetPaymentStatus] = useState(false);
    const [permissions, setpermissions] = useState([]);

    const showDefaultDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const logout = () => {
        localStorage.clear();
        navigate('/');
    };

    const MBBSandBDSapplication = () => {
        setMBBSandBDS(true);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(false);
        setFellowship(false);
        setEDP(false);
        setOpenClose(false);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const MDMSMDSapplication = () => {
        setMBBSandBDS(false);
        setMDMSMDS(true);
        setAHS(false);
        setPhD(false);
        setFellowship(false);
        setEDP(false);
        setOpenClose(false);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const AHSapplication = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(true);
        setPhD(false);
        setFellowship(false);
        setEDP(false);
        setOpenClose(false);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const PhDapplication = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(true);
        setFellowship(false);
        setEDP(false);
        setOpenClose(false);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const Fellowshipapplication = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(false);
        setFellowship(true);
        setEDP(false);
        setOpenClose(false);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const EDPapplication = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(false);
        setFellowship(false);
        setEDP(true);
        setOpenClose(false);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const OpenCloseapplication = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(false);
        setFellowship(false);
        setEDP(false);
        setOpenClose(true);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const BulkDownloadapplication = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(false);
        setFellowship(false);
        setEDP(false);
        setOpenClose(false);
        setBulk(true);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const PaymenderReminder = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(false);
        setFellowship(false);
        setEDP(false);
        setOpenClose(false);
        setBulk(false);
        SetReminder(true);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const AddInstituteandCourse = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(false);
        setFellowship(false);
        setEDP(false);
        setOpenClose(false);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(true);
        SetExam(false);
        SetPaymentStatus(false);
        onClose();
    };

    const CreateExamInterviewSchedule = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(false);
        setFellowship(false);
        setEDP(false);
        setOpenClose(false);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(true);
        SetPaymentStatus(false);
        onClose();
    };

    const PaymentStatusUpdate = () => {
        setMBBSandBDS(false);
        setMDMSMDS(false);
        setAHS(false);
        setPhD(false);
        setFellowship(false);
        setEDP(false);
        setOpenClose(false);
        setBulk(false);
        SetReminder(false);
        setAddInstiandCourse(false);
        SetExam(false);
        SetPaymentStatus(true);
        onClose();
    };

    useEffect(() => {
        var user = localStorage.getItem('role');
        if (user === 'admin') {
            var data = localStorage.getItem('permissions');
            setpermissions(data);
        } else {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/adminlogin');
        }
    }, []);

    return (
        <>
            <Row style={{ backgroundColor: "#CCCCFF", marginLeft: "10px", marginRight: "10px" }}>
                <Col sm={5} style={{ margin: 0, padding: 0 }}>
                    <Tooltip title="menu">
                        <List
                            type='button'
                            onClick={showDefaultDrawer}
                            style={{
                                fontSize: "40px",
                                color: "white",
                                backgroundColor: "#DE2711"
                            }}
                        />
                    </Tooltip>
                </Col>
                <Col sm={5} style={{ margin: 0, padding: 0 }}>
                    <h3 style={{ color: "#2874A6" }}>Admin Dashboard</h3>
                </Col>
                <Col sm={2} style={{ margin: 0, padding: 0 }}>
                    <div className="navigation">

                        <button className="button" onClick={logout}>
                            <BoxArrowRight className='img' />
                            <div className="logout">LOGOUT</div>
                        </button>

                    </div>
                </Col>
            </Row>

            <Drawer
                title={"Menu"}
                placement="left"
                size={'default'}
                onClose={onClose}
                open={open}
                style={{ backgroundColor: "#f8fdfd" }}
            >
                <ul className='ul' style={{ paddingLeft: 0, fontSize: "15px" }}>

                    {(permissions.includes("MBBS,BDS") || permissions.includes("MD,MS,MDS") ||
                        permissions.includes("BSc,MSc,BPT") || permissions.includes("PhD") ||
                        permissions.includes("Fellowship") || permissions.includes("EDP")) &&
                        <div>
                            <li style={{ textDecoration: "none" }}>
                                Admission Controller
                                <CaretDownFill
                                    style={{ fontSize: "20px", marginLeft: "3px" }}
                                ></CaretDownFill>
                                <ul>{permissions.includes("MBBS,BDS") &&
                                    <div>
                                        <li onClick={MBBSandBDSapplication}>MBBS / BDS Applications</li>
                                        <Divider style={{ margin: 0 }}></Divider>
                                    </div>
                                }
                                    {permissions.includes("MD,MS,MDS") &&
                                        <div>
                                            <li onClick={MDMSMDSapplication}>MD / MS / MDS Applications</li>
                                            <Divider style={{ margin: 0 }}></Divider>
                                        </div>
                                    }
                                    {permissions.includes("BSc,MSc,BPT") &&
                                        <div>
                                            <li onClick={AHSapplication}>UG / PG Programme (Non-NEET) Applications</li>
                                            <Divider style={{ margin: 0 }}></Divider>
                                        </div>
                                    }
                                    {permissions.includes("PhD") &&
                                        <div >
                                            <li onClick={PhDapplication}>Ph.D Applications</li>
                                            <Divider style={{ margin: 0 }}></Divider>
                                        </div >
                                    }
                                    {permissions.includes("Fellowship") &&
                                        <div >
                                            <li onClick={Fellowshipapplication}>Fellowship Applications</li>
                                            <Divider style={{ margin: 0 }}></Divider>
                                        </div >
                                    }
                                    {permissions.includes("EDP") &&
                                        <div >
                                            <li onClick={EDPapplication}>EDP / Fellowship Applications</li>
                                            <Divider style={{ margin: 0 }}></Divider>
                                        </div >
                                    }
                                </ul>
                            </li>
                            <Divider style={{ margin: 0 }}></Divider>
                        </div>
                    }
                    {permissions.includes("Open,Close") &&
                        <div>
                            <li onClick={OpenCloseapplication}>Application Open / Close</li>
                            <Divider style={{ margin: 0 }}></Divider>
                        </div>
                    }
                    {permissions.includes("Bulkdownload") &&
                        <div>
                            <li onClick={BulkDownloadapplication}>Applications Bulk Download</li>
                            <Divider style={{ margin: 0 }}></Divider>
                        </div>
                    }
                    {permissions.includes("PaymentReminder") &&
                        <div>
                            <li onClick={PaymenderReminder}>Payment Reminder</li>
                            <Divider style={{ margin: 0 }}></Divider>
                        </div>
                    }
                    {permissions.includes("ExamSchedule") &&
                        <div>
                            <li onClick={CreateExamInterviewSchedule}>Create Exam / Interview Schedule</li>
                            <Divider style={{ margin: 0 }}></Divider>
                        </div>
                    }
                    {permissions.includes("Institute,Course") &&
                        <div>
                            <li onClick={AddInstituteandCourse}>Institute and Course</li>
                            <Divider style={{ margin: 0 }}></Divider>
                        </div>
                    }
                    {permissions.includes("UpdatePayment") &&
                        <div>
                            <li onClick={PaymentStatusUpdate}>Update Payment Status</li>
                            <Divider style={{ margin: 0 }}></Divider>
                        </div>
                    }
                </ul>
            </Drawer>
            <div>{
                MBBSandBDS === true &&
                <MBBSandBDSadmissionControl></MBBSandBDSadmissionControl>
            }
            </div>
            <div>{
                MDMSMDS === true &&
                <MD_MS_MDSadmissionControl></MD_MS_MDSadmissionControl>
            }
            </div>
            <div>{
                AHS === true &&
                <AHSadmissionControl></AHSadmissionControl>
            }
            </div>
            <div>{
                PhD === true &&
                <PhDadmissionControl></PhDadmissionControl>
            }
            </div>
            <div>{
                Fellowship === true &&
                <FellowshipadmissionControl></FellowshipadmissionControl>
            }
            </div>
            <div>{
                EDP === true &&
                <EDP_FellowshipadmissionControl></EDP_FellowshipadmissionControl>
            }
            </div>
            <div>{
                OpenClose === true &&
                <Open_Closeapplication></Open_Closeapplication>
            }
            </div>
            <div>{
                Bulk === true &&
                <Bulkdownload></Bulkdownload>
            }
            </div>
            <div>{
                Reminder === true &&
                <PaymentReminder></PaymentReminder>
            }
            </div>
            <div>{
                AddInstiandCourse === true &&
                <AddInstitutionandCourse></AddInstitutionandCourse>
            }
            </div>
            <div>
                {
                    Exam === true &&
                    <CreateExamSchedule></CreateExamSchedule>
                }
            </div>
            <div>
                {
                    PaymentStatus === true &&
                    <UpdatePayment></UpdatePayment>
                }
            </div>
        </>
    )
}

export default AdminDashboard


