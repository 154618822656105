import React from 'react';
import { Form, Button, Container, Row, Col, Card, Table, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import SwipeableViews from 'react-swipeable-views';
import * as XLSX from 'xlsx';
import {
    getAllFellowshipApplicationData,
    sentFellowshipApplicationRemark,
    fellowshipApplicationRejected,
    fellowshipApplicationNotEligible,
    fellowshipApplicationVerified,
    exportFellowshipApplications,
    fellowshipApplicationDownload
} from './services'

function FellowshipAdmissionController() {

    const [applications, setApplications] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [pageIndex, setPageIndex] = React.useState(0);
    const [isPdf, setIsPdf] = React.useState(false);
    const [filePath, setFilePath] = React.useState('');
    const [showRemarkModal, setshowRemarkModal] = React.useState(false);
    const [remark, setremark] = React.useState('');
    const navigate = useNavigate();

    React.useEffect(() => {
        getFellowshipApplications();
    }, []);

    async function getFellowshipApplications() {
        try {
            const data = await getAllFellowshipApplicationData();
            if (data.application.length > 0) {
                setApplications(data.application);
            }
            else {
                setApplications([]);
                swal({
                    title: "No Record Found.",
                    icon: 'info'
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setFilePath('');
        getFellowshipApplications();
    };

    const viwApplicationData = (_id) => {
        const copyApplications = [...applications];
        const selectedIndex = copyApplications.findIndex((application) => application._id === _id);
        setApplications(copyApplications);
        setPageIndex(selectedIndex);
        setShowModal(true);
    };

    const viwdocument = (file) => {
        if (file.filePath) {
            const fileNameArray = file.filePath.split('.');
            const extension = fileNameArray[fileNameArray.length - 1].toLowerCase();
            setFilePath(process.env.REACT_APP_SERVICE_URL + '/documents/' + file.filePath);
            setIsPdf(extension === 'pdf');
        }
    };

    const CloseRemarkModal = () => {
        setremark('');
        setshowRemarkModal(false);
    };

    const remarkSubmit = async () => {
        try {
            const mailid = applications[pageIndex].mailid;
            const id = applications[pageIndex]._id;
            const result = await sentFellowshipApplicationRemark({ "remark": remark, "id": id, "mailid": mailid });
            swal({
                title: "Remark Mail Sent Successfully.",
                icon: 'success'
            });
            CloseRemarkModal();
            onNext();
        }
        catch (err) {
            console.log(err);
        }
    };


    const onNext = () => {
        setPageIndex((prevIndex) => {
            if (prevIndex < applications.length - 1) {
                return prevIndex + 1;
            }

            return prevIndex;
        });
        setFilePath('')
    };

    const onPrevious = () => {
        setPageIndex((prevIndex) => {
            if (prevIndex > 0) {
                return prevIndex - 1;
            }

            return prevIndex;
        });
        setFilePath('')
    };

    const rejected = async () => {
        try {
            const id = applications[pageIndex]._id;
            await fellowshipApplicationRejected({ "_id": id });
            onNext();
        }
        catch (err) {
            console.log(err);
        }
    };

    const notEligible = async () => {
        try {
            const id = applications[pageIndex]._id;
            await fellowshipApplicationNotEligible({ "_id": id });
            onNext();
        }
        catch (err) {
            console.log(err);
        }
    };


    const verified = async () => {
        try {
            const id = applications[pageIndex]._id;
            await fellowshipApplicationVerified({ "_id": id });
            onNext();
        }
        catch (err) {
            console.log(err);
        }
    };

    const downloadXL = async () => {
        try {
            const data = await exportFellowshipApplications();
            const addfilepath = data.result.map((obj) => {
                if (obj.uploadDocuments.length > 0) {
                    obj.uploadDocuments.map((file) => {
                        file.filePath = `${process.env.REACT_APP_SERVICE_URL}/documents/` + file.filePath;
                        return file;
                    })
                }
                return obj;
            });
            const xldata = addfilepath.map((object) => {
                if (object.uploadDocuments.length > 0) {
                    const keyValues = object.uploadDocuments.reduce((acc, curr) => {
                        acc[curr.documentName] = curr.filePath;
                        return acc;
                    }, {});
                    return { ...object, ...keyValues };
                }
                return object;
            });

            const academicArray = xldata.map((object) => {
                var studMarks = {};
                if (object.academicQualification.length > 0) {
                    object.academicQualification.forEach((mark, index) => {
                        var keys = Object.keys(mark);

                        keys.forEach((key) => {
                            studMarks[key + '_' + index] = mark[key];

                        });
                    })
                    object = { ...object, ...studMarks };
                }
                return object;
            });

            const finalData = academicArray.map((object) => {
                var studMarks = {};
                if (object.teachingExperience.length > 0) {
                    object.teachingExperience.forEach((mark, index) => {
                        var keys = Object.keys(mark);

                        keys.forEach((key) => {
                            studMarks[key + '_' + index] = mark[key];

                        });
                    })
                    object = { ...object, ...studMarks };
                }
                return object;
            });
            finalData.forEach(object => {
                delete object.academicQualification;
                delete object.uploadDocuments;
                delete object.teachingExperience;
            });
            const worksheet = XLSX.utils.json_to_sheet(finalData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const fileBuffer = await XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

            const blob = new Blob([fileBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Fellowship_Applications.xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        catch (err) {
            console.log(err);
        }
    };

    const downloadInpdf = async (obj) => {
        try {
            const response = await fellowshipApplicationDownload({ "_id": obj._id });
            const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', obj.applicationNo + '.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <Container className=" justify-content-center align-items-center" style={{ height: 'auto' }}>
            <Row className='mt-3'>
                <Col className='mb-2' style={{ textAlign: 'center', color: "#ba0af0" }}>
                    <h5>Fellowship Applications</h5>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col className='mb-2' style={{ textAlign: 'end' }}>
                    <Button
                        style={{ width: '150px' }}
                        type='primary'
                        onClick={downloadXL}
                    >Export as Excel</Button>
                </Col>
            </Row>
            <Card className='mt-3' style={{ width: '100%', height: 'auto', marginBottom: '25px' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>S.No</th>
                            <th style={{ textAlign: 'center', }}>Application No</th>
                            <th style={{ textAlign: 'center', }}>Name</th>
                            <th style={{ textAlign: 'center', }}>Application Status</th>
                            <th style={{ textAlign: 'center', }}>Remark</th>
                            <th style={{ textAlign: 'center', }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            applications.map((obj, index) => (
                                <tr style={{ padding: 0 }} key={obj._id}>
                                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                    <td style={{ textAlign: 'center' }}>{obj.applicationNo}</td>
                                    <td style={{ textAlign: 'center' }}>{obj.applicantName}</td>
                                    <td style={{ textAlign: 'center' }}>{obj.applicationStatus}</td>
                                    <td style={{ textAlign: 'center', width: '500px' }}>{obj.remark}</td>
                                    <td style={{ textAlign: 'center', width: '135px' }}>
                                        <Row>
                                            <Col>
                                                <Button
                                                    type='primary'
                                                    size='sm'
                                                    onClick={() => viwApplicationData(obj._id)}
                                                >view</Button>
                                                <Button
                                                    style={{ marginLeft: "3px" }}
                                                    type='primary'
                                                    size='sm'
                                                    onClick={() => downloadInpdf(obj)}
                                                >
                                                    &#8595;pdf</Button>
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Card>
            <Modal
                show={showModal}
                fullscreen={true}
                onHide={() => closeModal()}
                style={{ height: window.innerHeight }}
            >
                <Modal.Header closeButton>
                    <Container>
                        <Row>
                            <Col style={{ color: 'blue', textAlign: 'center', fontWeight: 'bold' }}>
                                APPLICATION VERIFICATION
                                <Button
                                    style={{ marginLeft: '50px' }}
                                    onClick={() => setshowRemarkModal(true)}
                                >Remark</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                    <SwipeableViews index={pageIndex}>
                        {
                            applications.map((formdata) => (
                                <Row key={formdata._id} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Col style={{ height: window.innerHeight, overflow: 'auto' }} md={6}>

                                        <Row className='mt-3'>
                                            <Col><strong>Application No</strong></Col>
                                            <Col>{formdata.applicationNo}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Name</strong></Col>
                                            <Col>{formdata.applicantName}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Fellowship Programme</strong></Col>
                                            <Col>{formdata.fellowshipProgramme}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Gender</strong></Col>
                                            <Col>{formdata.gender}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Date of Birth</strong></Col>
                                            <Col>{formdata.dateofbirth}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Community</strong></Col>
                                            <Col>{formdata.community}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Nationality</strong></Col>
                                            <Col>{formdata.nationality}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Religion</strong></Col>
                                            <Col>{formdata.religion}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Name of Father / Guardian</strong></Col>
                                            <Col>{formdata.fatherName}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Residential Address</strong></Col>
                                            <Col>{formdata.residentialaddress}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Office/Institution Address (Current Working)</strong></Col>
                                            <Col>{formdata.officeaddress}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Aadhar No</strong></Col>
                                            <Col>{formdata.aadharNo}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Mobile No</strong></Col>
                                            <Col>{formdata.mobileNo}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Mail Id</strong></Col>
                                            <Col>{formdata.mailid}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Official Mail Id</strong></Col>
                                            <Col>{formdata.officialmailid}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Official Phone No</strong></Col>
                                            <Col>{formdata.officialphoneno}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>Academic Qualification (UG to M.Phil)</strong></Col>
                                        </Row>

                                        <Table striped bordered hover className="mt-3 text-center">
                                            <thead>
                                                <tr>
                                                    <th>Qualification</th>
                                                    <th>School/College</th>
                                                    <th>Board/University</th>
                                                    <th>Month & Year of Passing</th>
                                                    <th>subject</th>
                                                    <th>% of Mark</th>
                                                    <th>Class/Rank</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    formdata.academicQualification.map((obj) => (
                                                        <tr key={obj._id}>
                                                            <td>{obj.qualification}</td>
                                                            <td>{obj.school_college}</td>
                                                            <td>{obj.board_university}</td>
                                                            <td>{obj.month_year_passing}</td>
                                                            <td>{obj.subject}</td>
                                                            <td>{obj.percentagemark}</td>
                                                            <td>{obj.class_rank}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>

                                        <Row className='mt-3'>
                                            <Col><strong>Professional/Teaching Experience</strong></Col>
                                        </Row>

                                        <Table striped bordered hover className="mt-3 text-center">
                                            <thead>
                                                <tr>
                                                    <th>Designation</th>
                                                    <th>Institution</th>
                                                    <th>From Date</th>
                                                    <th>To Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    formdata.teachingExperience.map((obj) => (
                                                        <tr key={obj._id}>
                                                            <td>{obj.teachExpDesignation}</td>
                                                            <td>{obj.teachExpInstitution}</td>
                                                            <td>{obj.teachExpFromDate}</td>
                                                            <td>{obj.teachExpToDate}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>

                                        <Row className='mt-3'>
                                            <Col><strong>The subject in which the candidate has qualified for the</strong></Col>
                                            <Col>{formdata.masterDegreeQualifiedSubject}</Col>
                                        </Row>


                                        <Row className='mt-3'>
                                            <Col><strong>The above master's Degree was awarded by</strong></Col>
                                            <Col>{formdata.degreeAwardedBy}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>If Others, Mention degree awarded by</strong></Col>
                                            <Col>{formdata.othersDegreeAwardedBy}</Col>
                                        </Row>

                                        <Row className='mt-3'>
                                            <Col><strong>uploaded Documents List</strong></Col>
                                        </Row>

                                        {
                                            formdata.uploadDocuments.map((obj) => (
                                                <Row key={obj._id} className='mt-3'>
                                                    <Col>{obj.documentName}</Col>
                                                    <Col><Button
                                                        type='primary'
                                                        size='sm'
                                                        onClick={() => viwdocument(obj)}
                                                    >view</Button></Col>
                                                </Row>
                                            ))
                                        }

                                    </Col>
                                    <Col style={{ height: window.innerHeight, overflow: 'auto' }} md={6}>

                                        {
                                            isPdf ? <iframe src={filePath} width="630px" height="630px" />
                                                : <img src={filePath} width="630px" height="630px" alt='Image' />
                                        }

                                    </Col>

                                </Row>
                            ))
                        }
                    </SwipeableViews>
                </Modal.Body>
                <Modal
                    show={showRemarkModal}
                    onHide={CloseRemarkModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Remark</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={remark}
                                    onChange={(e) => setremark(e.target.value)}
                                />
                            </Form.Group>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={remarkSubmit}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container>
                    <Row className='mb-2'>
                        <Col>
                            <Button type='primary' onClick={onPrevious}>Back</Button>
                        </Col>
                        <Col>
                            <Button type='primary' onClick={onNext}>Next</Button>
                        </Col>
                        <Col>
                            <Button type='primary' style={{ backgroundColor: 'red' }} onClick={rejected}>Rejected</Button>
                        </Col>
                        <Col>
                            <Button type='primary' style={{ backgroundColor: 'brown' }} onClick={notEligible}>Not Eligible</Button>
                        </Col>
                        <Col>
                            <Button style={{ backgroundColor: 'green' }} type='primary' onClick={verified}>Verify & Accept</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </Container>
    )
}

export default FellowshipAdmissionController