import React from 'react';
import { Divider } from 'antd';
import Newimg from "../src/newImage.gif";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { getAhsApplicationPosition, getPhdApplicationPosition, getFellowshipApplicationPosition } from './services';
import swal from 'sweetalert';

function ApplicationSelection() {

    const navigate = useNavigate();
    const role = localStorage.getItem('role');

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const goToDashboard = () => {
        navigate("/dashboard");
    };

    const AHS_Link = async () => {
        if (role === 'admin') {
            navigate("/form");
        } else {

            const response = await getAhsApplicationPosition();
            if (response.applicationPosition === "Open") {
                navigate("/form");
            }
            if (response.applicationPosition === "Close") {
                swal({
                    title: "Admission Closed.",
                    icon: 'info'
                });
            }
        }
    };

    const PHD_Link = async () => {
        if (role === 'admin') {
            navigate("/phdapplication");
        } else {

            const response = await getPhdApplicationPosition();
            if (response.applicationPosition === "Open") {
                navigate("/phdapplication");
            }
            if (response.applicationPosition === "Close") {
                swal({
                    title: "Admission Closed.",
                    icon: 'info'
                });
            }
        }
    };

    const Fellowship_Link = async () => {
        if (role === 'admin') {
            navigate("/fellowshipapplication");
        } else {

            const response = await getFellowshipApplicationPosition();
            if (response.applicationPosition === "Open") {
                navigate("/fellowshipapplication");
            }
            if (response.applicationPosition === "Close") {
                swal({
                    title: "Admission Closed.",
                    icon: 'info'
                });
            }
        }
    };

    const MBBS_BDS_Link = async () => {
        navigate("/mbbsandbdsapplication");
    };

    const MDMS_Link = async () => {
        navigate("/mdmsapplication");
    };

    const Student_Registration = async () => {
        navigate("/mbbsandbdsstudentregistration");
    };

    const EDP_Fellowship_Link = async () => {
        navigate("/EDP_fellowship_application");
    };

    const StudentRegistration_MD_ms_mds = async () => {
        navigate("/studentregistration_MD_MS_MDS");
    };

    const StudentRegistration_DM_MCh = async () => {
        navigate("/studentregistration_DM_MCh");
    };

    const StudentRegistration_BSc_PBBSc_MSc_NPCC = async () => {
        navigate("/student_registration_BSc_PBBSc_MSc_NPCC_nursing");
    };

    const StudentRegistration_BPharm = async () => {
        navigate("/student_registration_BPharm");
    };

    const studentRegistration_AHS = async () => {
        navigate("/student_registration_AHS");
    };

    const studentRegistration_BPT_MPT = async () => {
        navigate("/student_registration_BPT_MPT");
    };

    const StudentRegistration_MSc_Medical_MBA_MPH = async () => {
        navigate("/student_registration_MSc_Medical_MBA_MPH");
    };

    React.useEffect(() => {
        var user = localStorage.getItem('username');
        if (!user) {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/');
        }
    })

    return (
        <Container>
            <Row className='mt-5'>
                <Col style={{ textAlign: "start" }}>
                    <Button variant="primary" type="button"
                        onClick={goToDashboard}
                    >
                        Go To Dashboard
                    </Button>
                </Col>
                <Col style={{ textAlign: "end" }}>
                    <Button variant="primary" type="button"
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </Col>
            </Row>

            {/*    <div style={{ display: "flex" }}>
                <img
                    src={Newimg}
                    style={{
                        width: '50px',
                        height: '20px',
                        display: 'block',
                        marginTop: "10px"
                    }}
                />

                <h5
                    style={{ color: "rgb(240, 151, 7)", marginLeft: "10px", marginTop: "10px", fontFamily: "cursive" }}>
                    Admission open for 2024 to 2025
                </h5>
            </div>
            <h6
                style={{ color: "#EE390D", marginLeft: "10px", marginTop: "10px", lineHeight: "30px" }}>
                New programme offered: <br />
                &bull; MBA (Hospital Administration)<br />
                &bull; MPH (Master of Public Health)
            </h6> */}

            <Card style={{ paddingBottom: "20px", marginTop: "15px" }}>
                <div style={{ marginLeft: "50px" }}>
                    <div style={{ textAlign: "center", color: "#A52A2A", marginTop: "30px", fontWeight: "bold" }}>
                        <strong>Admitted Student Registration Application</strong>
                    </div>

                    <Row className='mt-3' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={StudentRegistration_MD_ms_mds} style={{ textDecoration: "none" }}>
                                Admitted Student Application for <b>MD / MS / MDS.</b>
                            </a>
                        </Col>
                    </Row>

                    {/* <Row className='mt-3' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={StudentRegistration_DM_MCh} style={{ textDecoration: "none" }}>
                                Admitted Student Registration DM / M.Ch.
                            </a>
                        </Col>
                    </Row> */}

                    <Row className='mt-3' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={StudentRegistration_BPharm} style={{ textDecoration: "none" }}>
                                Admitted Student Application for <b>B.Pharm.</b>
                            </a>
                        </Col>
                    </Row>

                    <Row className='mt-3' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={studentRegistration_AHS} style={{ textDecoration: "none" }}>
                                Admitted Student Application for <b>AHS.</b>
                            </a>
                        </Col>
                    </Row>

                    <Row className='mt-3' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={StudentRegistration_BSc_PBBSc_MSc_NPCC} style={{ textDecoration: "none" }}>
                                Admitted Student Application for <b>B.Sc / P.B.B.Sc / M.Sc / NPCC Nursing.</b>
                            </a>
                        </Col>
                    </Row>

                    <Row className='mt-1' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={studentRegistration_BPT_MPT} style={{ textDecoration: "none" }}>
                                Admitted Student Application for <b>Biomedical Sciences and BPT / MPT.</b>
                            </a>
                        </Col>
                    </Row>

                    <Row className='mt-1' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={StudentRegistration_MSc_Medical_MBA_MPH} style={{ textDecoration: "none" }}>
                                Admitted Student Application for <b>M.Sc (Medical) / MBA / MPH / Music & Yoga Therapy.</b>
                            </a>
                        </Col>
                    </Row>

                </div>
            </Card>

            <Card style={{ paddingBottom: "50px", marginTop: "15px" }}>
                <div style={{ marginLeft: "50px" }}>
                    <div style={{ textAlign: "center", marginTop: "20px", color: "#f511cf" }}>
                        <strong>New Admission Application</strong>
                    </div>
                    {/*     <Row className='mt-3' style={{ display: "flex" }}>
                <Col style={{ fontSize: "30px", maxWidth: "25px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                        <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                    </svg>
                </Col>
                <Col style={{ marginTop: "10px", textAlign: "start" }}>
                    <a href="#" onClick={MBBS_BDS_Link} style={{ textDecoration: "none" }}>
                        Click here to Apply for MBBS / BDS Admission.
                    </a>
                </Col>
            </Row>

            <Row className='mt-3' style={{ display: "flex" }}>
                <Col style={{ fontSize: "30px", maxWidth: "25px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                        <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                    </svg>
                </Col>
                <Col style={{ marginTop: "10px", textAlign: "start" }}>
                    <a href="#" onClick={MDMS_Link} style={{ textDecoration: "none" }}>
                        Click here to Apply for MDMS Admission.
                    </a>
                </Col>
            </Row>

            <Row className='mt-3' style={{ display: "flex" }}>
                <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                        <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                    </svg>
                </Col>
                <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                    <a href="#" onClick={Student_Registration} style={{ textDecoration: "none" }}>
                        Online Application For Admitted Students (M.B.B.S)
                    </a>
                </Col>
            </Row>*/}

                    <Row className='mt-3' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={AHS_Link} style={{ textDecoration: "none" }}>
                                Click here to Apply for UG/PG Programme at Allied Health Sciences, Pharmacy, Nursing, M.Sc. Medical, BioMedical Sciences, Physiotherapy, Salutogenesis & Complementary Medicine and Health Profession Education.
                            </a>
                        </Col>
                    </Row>

                    <Row className='mt-3' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={PHD_Link} style={{ textDecoration: "none" }}>
                                Click here to Apply for Doctoral Programme (Ph.D) at SBV.
                            </a>
                        </Col>
                    </Row>

                    <Row className='mt-3' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={EDP_Fellowship_Link} style={{ textDecoration: "none" }}>
                                Click here to Apply for Executive Development Program (EDP) / Fellowship Programmes.
                            </a>
                        </Col>
                    </Row>

                    {/*                     <Row className='mt-3' style={{ display: "flex" }}>
                        <Col sm={1} style={{ fontSize: "30px", maxWidth: "25px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-diamond-fill" viewBox="0 0 16 16" style={{ color: "#0DA3EE" }}>
                                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L4.047 3.339 8 7.293l3.954-3.954L9.049.435zm3.61 3.611L8.708 8l3.954 3.954 2.904-2.905c.58-.58.58-1.519 0-2.098l-2.904-2.905zm-.706 8.614L8 8.708l-3.954 3.954 2.905 2.904c.58.58 1.519.58 2.098 0l2.905-2.904zm-8.614-.706L7.292 8 3.339 4.046.435 6.951c-.58.58-.58 1.519 0 2.098l2.904 2.905z" />
                            </svg>
                        </Col>
                        <Col sm={11} style={{ marginTop: "10px", textAlign: "start" }}>
                            <a href="#" onClick={Fellowship_Link} style={{ textDecoration: "none" }}>
                                Click here to Apply for Fellowship / Observership in Ultrasound Guided Regional Anaesthesia (FUGRA / OUGRA).
                            </a>
                        </Col>
                    </Row>

                    <p style={{ fontSize: '15px', fontStyle: 'italic', marginLeft: "22px", justifyContent: "flex-start", color: "#212F3D" }}>
                        (Admission portal to FUGRA / OUGRA open throughout the year. But applications will be processed during August every year and admission will be in August and course will commence from September every year)</p> */}

                </div>
            </Card>
        </Container>
    )
}

export default ApplicationSelection