import React from 'react';
import swal from 'sweetalert';
import Card from 'react-bootstrap/Card';
import { Button, Container, Row, Col } from 'react-bootstrap';
import useRazorpay from 'react-razorpay';
import { useNavigate } from "react-router-dom";
import { createRazorpayOrder, PaymentSuccessResponse, PaymentErrorResponse } from './services';

function Payment(props) {
    const navigate = useNavigate();
    const Razorpay = useRazorpay();
    const {
        updateSwipeableViewHeight,
        selectedCourse,
        selectedInstitute,
        name
    } = props;
    const feeRef = React.useRef(0);
    const [paying, setPaying] = React.useState(false);

    if (selectedCourse === "M.Sc.Medical Biochemistry" || selectedCourse === "M.Sc.Medical MicroBiology") {
        feeRef.current = 1000;
    } else {
        feeRef.current = 500;
    }

    updateSwipeableViewHeight();


    const createOrder = () => {
        const payload = {
            amount: feeRef.current * 100
        };

        return createRazorpayOrder(payload);
    };

    const handlePayment = async () => {
        const order = await createOrder();

        const onSuccessPayment = async (response) => {
            swal({
                title: `Please note your payment id ${response.razorpay_payment_id}`,
                icon: 'success'
            });
            await PaymentSuccessResponse({ response, "_id": localStorage.getItem("_id"), fees: feeRef.current });
            navigate('/dashboard');
        };

        const option = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: feeRef.current * 100,
            currency: 'INR',
            name: 'Sri Balaji Vidyapeeth',
            order_id: order.id,
            handler: onSuccessPayment
        };

        const rzp = new Razorpay(option);

        rzp.on('payment.failed', async (response) => {
            await PaymentErrorResponse({ response, "_id": localStorage.getItem("_id"), fees: feeRef.current });
        });

        rzp.open();

        setPaying(false);
    };

    const onPayClick = () => {
        setPaying(true);
    };

    React.useEffect(() => {
        if (paying) {
            handlePayment();
        }
    }, [paying]);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: -45 }}>
                <Card style={{ width: '30rem', height: 'auto', paddingBottom: '30px', marginTop: '70px', paddingTop: '15px' }}>
                    <Container fluid>
                        <Row className='mt-3'>
                            <Col xs={12} sm={4}>Name:</Col>
                            <Col xs={12} sm={8}>{name}</Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} sm={4}>Institute Name:</Col>
                            <Col xs={12} sm={8}>{selectedInstitute}</Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} sm={4}>Course Name:</Col>
                            <Col xs={12} sm={8}>{selectedCourse}</Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12} sm={4}>Application Fees:</Col>
                            <Col xs={12} sm={8}>{feeRef.current}</Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col className='text-center'>
                                <Button
                                    variant="primary"
                                    type="button"
                                    style={{ width: '70px' }}
                                    onClick={onPayClick}
                                    disabled={paying}
                                >
                                    Pay
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </div>
        </>
    )
}

export default Payment