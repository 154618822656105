import React from 'react';

function Footer() {
    return (
        <div
            className="mt-3"
            style={{
                width: "100%",
                height: "30px",
                backgroundColor: "#1D9EDA",
                textAlign: "center",
                color: "white",
                paddingTop: "3px",
                fontFamily: "inherit",
                position: "fixed",
                left: 0,
                bottom: 0,
            }}
        >
            <p>
                &copy; Developed By Centre for Digital Resources, Education and Medical
                Informatics (C-DREaMs), SBV Deemed University, Puducherry.
            </p>
        </div>

    )
}

export default Footer;