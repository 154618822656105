import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Card, Form } from 'react-bootstrap';
import Spinner from './Spinner';
import swal from 'sweetalert';
import {
    downloadBulkApplications,
    getInstitute_BatchforBulkDownload
} from './services';

function ApplicationsBulkDownload() {
    const [applicationName, setApplicationName] = React.useState('');
    const [applicationType, setApplicationType] = React.useState('');
    const [selectedInstitute, setSelectedInstitute] = React.useState('');
    const [selectedBatch, setSelectedBatch] = React.useState('');
    const [institute, setInstitute] = React.useState([]);
    const [batch, setBatch] = React.useState([]);
    const navigate = useNavigate();
    const [spinner, setspinner] = React.useState(false);
    const [campus, setCampus] = React.useState('');

    React.useEffect(() => {
        if (applicationName) {
            getInstituteandBatch();
        }
    }, [applicationName]);

    const getInstituteandBatch = async () => {
        try {
            const result = await getInstitute_BatchforBulkDownload({ "applicationName": applicationName });
            if (result) {
                setInstitute(result.institute);
                setBatch(result.Batch);
            }

        } catch (err) {
            console.log(err);
        }
    };

    const download = async () => {
        if (applicationName && applicationType && selectedBatch && selectedInstitute) {
            try {
                setspinner(true);
                var obj = {
                    "applicationName": applicationName,
                    "applicationType": applicationType,
                    "batch": selectedBatch,
                    "institute": selectedInstitute,
                    "campus": campus
                };
                const response = await downloadBulkApplications(obj);
                const blob = new Blob([response], { type: 'application/zip' });
                const href = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${applicationName}_${applicationType}.zip`); // Set the desired filename
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setspinner(false);
            }
            catch (err) {
                console.log(err);
            }
        } else {
            swal({
                title: "All details are required.",
                icon: 'info'
            });
        }
    }

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container className=" justify-content-center align-items-center" style={{ height: 'auto' }}>
                <Row className='mt-3'>
                    <Col className='mb-2' style={{ textAlign: 'center', color: "#ba0af0" }}>
                        <h5>Applications Bulk Download</h5>
                    </Col>
                </Row>
                <Card className='mt-5'>
                    <Row className="mt-3 mb-3" style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}>
                        <Col xs={12} sm={6}>
                            <Form.Select onChange={(e) => setApplicationName(e.target.value)}>
                                <option value="">Select Application</option>
                                <option value="MBBS_BDS_Application">MBBS and BDS Applications</option>
                                <option value="MDMS_Application">MDMS Applications</option>
                                <option value="EDP_Application">EDP Applications</option>
                                <option value="Fellowship_Application">Fellowship Applications</option>
                                <option value="Phd_Application">Ph.D Applications</option>
                                <option value="AHS_Application">UG/PG at AHS,Pharmacy,Nursing etc.</option>
                            </Form.Select>
                        </Col>

                        <Col xs={12} sm={6}>
                            <Form.Select onChange={(e) => setSelectedInstitute(e.target.value)}>
                                <option value="">Select Institute</option>
                                {institute.length > 0 && institute.map((obj, index) => (
                                    <option key={index} value={obj._id}>{obj._id}</option>
                                ))}
                            </Form.Select>
                        </Col>

                    </Row>

                    <Row className="mt-3 mb-3" style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}>
                        <Col xs={12} sm={6}>
                            <Form.Select onChange={(e) => setApplicationType(e.target.value)}>
                                <option value="">Select Application Status</option>
                                <option value="Verified">Verified</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Not Eligible">Not Eligible</option>
                                <option value="Pending">Pending</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} style={{ textAlign: "end" }}>
                            <Form.Select onChange={(e) => setSelectedBatch(e.target.value)}>
                                <option value="">Select Batch</option>
                                {batch.length > 0 && batch.map((obj, index) => (
                                    <option key={index} value={obj._id}>{obj._id}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row className="mt-3 mb-3" style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}>
                        <Col xs={12} sm={6}>
                            <Form.Select onChange={(e) => setCampus(e.target.value)} name='campus' value={campus}>
                                <option>Select Campus</option>
                                <option name="institute"
                                    value="Pondicherry Campus">
                                    Pondicherry Campus
                                </option>
                                <option name="institute"
                                    value="Chennai Campus">
                                    Chennai Campus
                                </option>
                                <option name="institute"
                                    value="Karaikal Campus">
                                    Karaikal Campus
                                </option>
                            </Form.Select>
                            <label style={{ color: "red" }}>Campus is optional</label>
                        </Col>
                        <Col xs={12} sm={6} style={{ textAlign: "end" }}>
                            <Button
                                style={{ width: "150px" }}
                                type='primary'
                                onClick={download}
                            >Download</Button>
                        </Col>
                    </Row>

                </Card>
            </Container>
        </>
    )
}

export default ApplicationsBulkDownload