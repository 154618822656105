import React from 'react';
import { Button, Container, Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Spinner from './Spinner';
import { downloadAllDeclarationsInPDF } from './services';

function DownloadAllDeclarations() {
    const [spinner, setspinner] = React.useState(false);
    const navigate = useNavigate();

    const goToDashboard = () => {
        localStorage.removeItem("registrationNo");
        navigate("/dashboard");
    };

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const downloadAllDeclarations = async () => {
        try {
            setspinner(true);
            const response = await downloadAllDeclarationsInPDF({ "registrationNo": localStorage.getItem("registrationNo") });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${localStorage.getItem("registrationNo")}.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
            goToDashboard();
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row className='mt-4' style={{ width: "100%" }}>
                    <Col>
                        <Button variant="primary" type="button"
                            style={{
                                alignItems: 'center',
                                width: '150px',
                                borderRadius: '50px',
                                padding: '10px 10px',
                            }}
                            onClick={goToDashboard}
                        >
                            Go To Dashboard
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                        <Button variant="primary" type="button"
                            style={{
                                alignItems: 'center',
                                width: '150px',
                                borderRadius: '50px',
                                padding: '10px 10px',
                            }}
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-3 mb-4' >
                    <Card style={{ width: "100%", padding: 16, backgroundColor: "#EADDCA" }}>
                        <Row className='mt-2'>
                            <Col style={{ marginLeft: "20px" }}>
                                <Row>
                                    Kindly download below mentioned auto filled decalaration form to submit with self attestation in all declaration form.
                                </Row>
                                <Row>1. Application For Admission To The UG Programmes (MBBS - BDS) 2023.</Row>
                                <Row>2. Anti Ragging - Undertaking. </Row>
                                <Row>3. Undertaking Regarding Institution Regulations. </Row >
                                <Row>4. Joint Undertaking - Payment Of Tuition Fees. </Row >
                                <Row>5. Hostel Application Form. </Row >
                                <Row>6. Joint Declaration For Hostel Accommodation. </Row >
                                <Row>7. BusPass Application. </Row >
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "center" }}>
                                <Button variant="primary" type="button"
                                    style={{
                                        alignItems: 'center',
                                        width: '150px',
                                        borderRadius: '50px',
                                    }}
                                    onClick={downloadAllDeclarations}
                                >
                                    Download
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default DownloadAllDeclarations