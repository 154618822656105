import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
//import { useState } from "react";
import Card from 'react-bootstrap/Card';
//import { useNavigate } from "react-router-dom";
import { UpdateAcademicData } from './services';

function getInitialState() {
    return {
        hscmonthAndYearOfPassing: '',
        totalMark12: '',
        hscinstitution: '',
        hscmarks: '',
        degremonthAndYearOfPassing: '',
        degreinstitution: '',
        degremarks: '',
        percentage12: '',
        TFHSObtainedMark: '',
        englishObtainedMark: '',
        physicsObtainedMark: '',
        chemistryObtainedMark: '',
        biologyObtainedMark: '',
        botanyObtainedMark: '',
        zoologyObtainedMark: '',
        mathematicsObtainedMark: '',
        computerScienceObtainedMark: '',
        TFHSMaxMark: '',
        englishMaxMark: '',
        physicsMaxMark: '',
        chemistryMaxMark: '',
        biologyMaxMark: '',
        botanyMaxMark: '',
        zoologyMaxMark: '',
        mathematicsMaxMark: '',
        computerScienceMaxMark: '',
        othersObtainedMark: '',
        othersMaxMark: '',
        diplomaDegreeMarks: [
            {
                semester: '',
                semesterYearOfPassing: '',
                semesterMarksObtained: 0,
                semesterMaxMarks: 0,
                semesterPercentageMark: 0
            }
        ],
        experience: [
            {
                expDesignation: '',
                expInstitution: '',
                expFromDate: '',
                expToDate: ''

            }
        ],
        IHPE_UG_to_Masters: [
            {
                IHPEqualification: '',
                IHPEschool_college: '',
                IHPEboard_university: '',
                IHPEmonth_year_passing: '',
                IHPEsubject: '',
                IHPEpercentagemark: 0,
                IHPEclass_rank: ''

            }
        ],
        totalSemesterMarksObtained: 0,
        totalSemesterMaxMarks: 0,
        totalSemesterPercentage: 0,
        submitting: false,
        saving: false,
        subjectQualified: '',
        degreeAwardedBy: '',
        othersDegreeAwardedBy: '',
    };
}

function AcademicDetails(props) {
    const {
        goToNextPage,
        goToPreviousPage,
        updateSwipeableViewHeight,
        applicationData,
        selectedDegree,
        selectedCourse
    } = props;

    const [state, setState] = React.useState(getInitialState());
    const onChangeInput = (event) => {
        const { target: { name, value } } = event;

        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const onDegreeMarksChange = (index, event) => {
        const { target: { name, value } } = event;
        // const name = event.target.name;
        // const value = event.target.value;
        setState((prevState) => {
            const updateDiplomaDegreeMarks = [...prevState.diplomaDegreeMarks];
            updateDiplomaDegreeMarks[index][name] = value;

            return {
                ...prevState,
                diplomaDegreeMarks: updateDiplomaDegreeMarks
            };
        });
    };

    const onChangeExperience = (index, event) => {
        const { target: { name, value } } = event;
        setState((prevState) => {
            const updateexperience = [...prevState.experience];
            updateexperience[index][name] = value;

            return {
                ...prevState,
                experience: updateexperience
            };
        });
    };

    const onChangeIHPE_UG_to_Master = (index, event) => {
        const { target: { name, value } } = event;
        setState((prevState) => {
            const updateIHPE_UG_to_Masters = [...prevState.IHPE_UG_to_Masters];
            updateIHPE_UG_to_Masters[index][name] = value;

            return {
                ...prevState,
                IHPE_UG_to_Masters: updateIHPE_UG_to_Masters
            };
        });
    };

    const addNewDegreeRow = () => {
        const newRow = {
            semester: '',
            semesterYearOfPassing: '',
            semesterMarksObtained: 0,
            semesterMaxMarks: 0,
            semesterPercentageMark: 0
        };

        setState((prevState) => ({
            ...prevState,
            diplomaDegreeMarks: [...prevState.diplomaDegreeMarks, newRow]
        }));
    };

    const addNewExperienceRow = () => {
        const newRow = {
            expDesignation: '',
            expInstitution: '',
            expFromDate: '',
            expToDate: ''
        };

        setState((prevState) => ({
            ...prevState,
            experience: [...prevState.experience, newRow]
        }));
    };
    const addNewIHPERow = () => {
        const newRow = {
            IHPEqualification: '',
            IHPEschool_college: '',
            IHPEboard_university: '',
            IHPEmonth_year_passing: '',
            IHPEsubject: '',
            IHPEpercentagemark: 0,
            IHPEclass_rank: ''
        };

        setState((prevState) => ({
            ...prevState,
            IHPE_UG_to_Masters: [...prevState.IHPE_UG_to_Masters, newRow]
        }));
    };

    const deleteIHPERow = (index) => {
        const deleteRow = [...state.IHPE_UG_to_Masters];
        deleteRow.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            IHPE_UG_to_Masters: deleteRow
        }));
    };

    const deleteDegreeRow = (index) => {
        const deleteRow = [...state.diplomaDegreeMarks];
        deleteRow.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            diplomaDegreeMarks: deleteRow
        }));
    };

    const deleteExperienceRow = (index) => {
        const deleteRow = [...state.experience];
        deleteRow.splice(index, 1);
        setState((prevState) => ({
            ...prevState,
            experience: deleteRow
        }));
    };

    const calculateTotalSemesterMarks = () => {
        let totalDiplomaMarksObtained = 0;
        let totalDiplomaMaxMarks = 0;
        diplomaDegreeMarks.forEach((diplomaMark) => {
            totalDiplomaMarksObtained += parseInt(diplomaMark.semesterMarksObtained);
            totalDiplomaMaxMarks += parseInt(diplomaMark.semesterMaxMarks);
        });

        setState((prevState) => ({
            ...prevState,
            totalSemesterMarksObtained: totalDiplomaMarksObtained,
            totalSemesterMaxMarks: totalDiplomaMaxMarks,
        }));
    };

    const {
        hscmonthAndYearOfPassing,
        hscinstitution,
        hscmarks,
        degremonthAndYearOfPassing,
        degreinstitution,
        degremarks,
        totalMark12,
        percentage12,
        diplomaDegreeMarks,
        totalSemesterMarksObtained,
        totalSemesterMaxMarks,
        totalSemesterPercentage,
        TFHSObtainedMark,
        TFHSMaxMark,
        englishObtainedMark,
        physicsObtainedMark,
        chemistryObtainedMark,
        biologyObtainedMark,
        botanyObtainedMark,
        zoologyObtainedMark,
        mathematicsObtainedMark,
        computerScienceObtainedMark,
        englishMaxMark,
        physicsMaxMark,
        chemistryMaxMark,
        biologyMaxMark,
        botanyMaxMark,
        zoologyMaxMark,
        mathematicsMaxMark,
        computerScienceMaxMark,
        othersObtainedMark,
        othersMaxMark,
        saving,
        experience,
        subjectQualified,
        degreeAwardedBy,
        othersDegreeAwardedBy,
        IHPE_UG_to_Masters
    } = state;

    React.useEffect(() => {
        calculateTotalSemesterMarks();
        updateSwipeableViewHeight();
    }, [diplomaDegreeMarks]);

    React.useEffect(() => {
        updateSwipeableViewHeight();
    }, [experience]);

    React.useEffect(() => {
        updateSwipeableViewHeight();
    }, [degreeAwardedBy]);

    React.useEffect(() => {
        updateSwipeableViewHeight();
    }, [IHPE_UG_to_Masters]);

    const saveAcademeicDetails = () => {
        setState((prevState) => ({
            ...prevState,
            _id: localStorage.getItem("_id"),
            submitting: true,
        }))
    };

    const onSaveClick = () => {
        setState((prevState) => ({
            ...prevState,
            saving: true
        }));
    };

    React.useEffect(() => {
        if (state.saving) {
            saveAcademeicDetails();
        }
    }, [state.saving]);

    async function updateAcademicData() {
        try {
            await UpdateAcademicData(state);
            setState((prevState) => ({
                ...prevState,
                submitting: false,
                saving: false
            }));

            goToNextPage();
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        if (state.submitting) {
            updateAcademicData();
        }
    }, [state.submitting])

    React.useEffect(() => {
        if (applicationData) {
            if (applicationData.diplomaDegreeMarks.length === 0) {
                applicationData.diplomaDegreeMarks.push({
                    semester: '',
                    semesterYearOfPassing: '',
                    semesterMarksObtained: 0,
                    semesterMaxMarks: 0,
                    semesterPercentageMark: 0
                });
            }
            if (applicationData.IHPE_UG_to_Masters.length === 0) {
                applicationData.IHPE_UG_to_Masters.push({
                    IHPEqualification: '',
                    IHPEschool_college: '',
                    IHPEboard_university: '',
                    IHPEmonth_year_passing: '',
                    IHPEsubject: '',
                    IHPEpercentagemark: 0,
                    IHPEclass_rank: ''
                });
            }
            if (applicationData.experience.length === 0) {
                applicationData.experience.push({
                    expDesignation: '',
                    expInstitution: '',
                    expFromDate: '',
                    expToDate: ''
                });
            }

            setState((prevState) => ({
                ...prevState,
                ...applicationData
            }));
        }
    }, [applicationData]);

    return (
        <Container>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: -45 }}>
                <Card style={{ width: '65rem', height: 'auto', paddingBottom: '20px', paddingTop: '45px' }}>
                    <h3 style={{ textAlign: 'center', color: '#fd7e14', paddingTop: '10px' }}>
                        Application for Admission to the Academic Year 2024 - 2025</h3>
                    <Container>
                        <Form>
                            {selectedCourse !== "Masters In Health Professions Education" &&
                                <div>
                                    <Row className="mt-3" >
                                        <Col>
                                            <strong>Details Of Qualifying Examination:</strong>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3" >
                                        <Col>COURSE</Col>
                                        <Col>MONTH/YEAR OF PASSING</Col>
                                        <Col>
                                            {selectedDegree === "UG"
                                                ? <span>BOARD FOR HSC</span>
                                                : <span>INSTITUTION / UNIVERSITY</span>
                                            }
                                        </Col>
                                        <Col>MARKS IN %</Col>
                                    </Row>
                                </div>
                            }
                            {
                                selectedDegree === "UG" &&
                                <Row className="mt-3" >
                                    <Col>HSC/Intermediate</Col>
                                    <Col>
                                        <Form.Control
                                            type='text'
                                            name='hscmonthAndYearOfPassing'
                                            value={hscmonthAndYearOfPassing}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='text'
                                            name='hscinstitution'
                                            value={hscinstitution}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='number'
                                            name='hscmarks'
                                            value={hscmarks}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }
                            {selectedDegree === "PG" && selectedCourse !== "Masters In Health Professions Education" &&
                                <Row className="mt-3" >
                                    <Col>Diploma/Degree</Col>
                                    <Col>
                                        <Form.Control
                                            type='text'
                                            name='degremonthAndYearOfPassing'
                                            value={degremonthAndYearOfPassing}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='text'
                                            name='degreinstitution'
                                            value={degreinstitution}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='number'
                                            name='degremarks'
                                            value={degremarks}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }
                            {
                                selectedDegree === "UG" &&
                                <div>
                                    <Row className="mt-3" >
                                        <Col>
                                            <strong>HSC / INTERMEDIATE MARKS:</strong>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3" >
                                        <Col>SUBJECT</Col>
                                        <Col>MARKS OBTAINED</Col>
                                        <Col>MAXIMUM MARKS</Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Tamil/French/Hindi/Sanskrit</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='TFHSObtainedMark'
                                                value={TFHSObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='TFHSMaxMark'
                                                value={TFHSMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>English</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='englishObtainedMark'
                                                value={englishObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='englishMaxMark'
                                                value={englishMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Physics</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='physicsObtainedMark'
                                                value={physicsObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='physicsMaxMark'
                                                value={physicsMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Chemistry</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='chemistryObtainedMark'
                                                value={chemistryObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='chemistryMaxMark'
                                                value={chemistryMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Biology</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='biologyObtainedMark'
                                                value={biologyObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='biologyMaxMark'
                                                value={biologyMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Botany</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='botanyObtainedMark'
                                                value={botanyObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='botanyMaxMark'
                                                value={botanyMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Zoology</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='zoologyObtainedMark'
                                                value={zoologyObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='zoologyMaxMark'
                                                value={zoologyMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Mathematics</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='mathematicsObtainedMark'
                                                value={mathematicsObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='mathematicsMaxMark'
                                                value={mathematicsMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Computer Science</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='computerScienceObtainedMark'
                                                value={computerScienceObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='computerScienceMaxMark'
                                                value={computerScienceMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Others</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='othersObtainedMark'
                                                value={othersObtainedMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='othersMaxMark'
                                                value={othersMaxMark}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Total Marks</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='totalMark12'
                                                value={totalMark12}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>Percentage Of Marks</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='percentage12'
                                                value={percentage12}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            }
                            {selectedDegree === "PG" && selectedCourse !== "Masters In Health Professions Education" &&
                                <div>
                                    <Row className="mt-3" >
                                        <Col>
                                            <strong>DIPLOMA / DEGREE MARKS:</strong>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3" >
                                        <Col>SEMESTER</Col>
                                        <Col>MONTH/YEAR OF PASSING</Col>
                                        <Col>MARKS OBTAINED</Col>
                                        <Col>MAXIMUM MARKS</Col>
                                        <Col>MARKS IN PERCENTAGE</Col>
                                        <Col></Col>
                                    </Row>
                                    {
                                        diplomaDegreeMarks.map((degRow, index) => (
                                            <Row className="mt-3" key={`${index}_deg`}>
                                                <Col>
                                                    <Form.Control
                                                        type='text'
                                                        name='semester'
                                                        value={degRow.semester}
                                                        onChange={(event) => onDegreeMarksChange(index, event)}
                                                    >
                                                    </Form.Control>
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        type='text'
                                                        name='semesterYearOfPassing'
                                                        value={degRow.semesterYearOfPassing}
                                                        onChange={(event) => onDegreeMarksChange(index, event)}
                                                    >
                                                    </Form.Control>
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        type='number'
                                                        name='semesterMarksObtained'
                                                        value={degRow.semesterMarksObtained}
                                                        onChange={(event) => onDegreeMarksChange(index, event)}
                                                    >
                                                    </Form.Control>
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        type='number'
                                                        name='semesterMaxMarks'
                                                        value={degRow.semesterMaxMarks}
                                                        onChange={(event) => onDegreeMarksChange(index, event)}
                                                    >
                                                    </Form.Control>
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        type='number'
                                                        name='semesterPercentageMark'
                                                        value={degRow.semesterPercentageMark}
                                                        onChange={(event) => onDegreeMarksChange(index, event)}
                                                    >
                                                    </Form.Control>
                                                </Col>
                                                <Col>
                                                    {
                                                        (diplomaDegreeMarks.length - 1) === index && (
                                                            <Button
                                                                variant="primary"
                                                                type="button"
                                                                style={{ alignItems: 'center' }}
                                                                className="w-70"
                                                                onClick={addNewDegreeRow}
                                                            >
                                                                Add Row
                                                            </Button>
                                                        )
                                                    }
                                                    {
                                                        (diplomaDegreeMarks.length - 1) !== index && (
                                                            <Button
                                                                variant="primary"
                                                                type="button"
                                                                style={{ alignItems: 'center', backgroundColor: 'red', width: '125px' }}
                                                                className="w-70"
                                                                onClick={() => deleteDegreeRow(index)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                    <Row className="mt-3">
                                        <Col>Total</Col>
                                        <Col></Col>
                                        <Col>{totalSemesterMarksObtained}</Col>
                                        <Col>{totalSemesterMaxMarks}</Col>
                                        <Col>
                                            <Form.Control
                                                type='number'
                                                name='totalSemesterPercentage'
                                                value={totalSemesterPercentage}
                                                onChange={onChangeInput}
                                            >
                                            </Form.Control>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            }
                            {selectedCourse === "Masters In Health Professions Education" &&
                                <div>
                                    <Row className="mt-3" >
                                        <Col>
                                            <strong>Academic Qualification (UG to Masters)</strong>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3" >
                                        <Col>Qualification</Col>
                                        <Col>School/College</Col>
                                        <Col>Board/University</Col>
                                        <Col>Month & Year of Passing</Col>
                                        <Col>subject</Col>
                                        <Col>% of Mark</Col>
                                        <Col>Class/Rank</Col>
                                        <Col></Col>
                                    </Row>
                                    {IHPE_UG_to_Masters.map((row, index) => (

                                        <Row className="mt-3" key={`${index}_ihpe`} >
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    name='IHPEqualification'
                                                    value={row.IHPEqualification}
                                                    onChange={(event) => onChangeIHPE_UG_to_Master(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    name='IHPEschool_college'
                                                    value={row.IHPEschool_college}
                                                    onChange={(event) => onChangeIHPE_UG_to_Master(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    name='IHPEboard_university'
                                                    value={row.IHPEboard_university}
                                                    onChange={(event) => onChangeIHPE_UG_to_Master(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    name='IHPEmonth_year_passing'
                                                    value={row.IHPEmonth_year_passing}
                                                    onChange={(event) => onChangeIHPE_UG_to_Master(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    name='IHPEsubject'
                                                    value={row.IHPEsubject}
                                                    onChange={(event) => onChangeIHPE_UG_to_Master(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    name='IHPEpercentagemark'
                                                    value={row.IHPEpercentagemark}
                                                    onChange={(event) => onChangeIHPE_UG_to_Master(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    name='IHPEclass_rank'
                                                    value={row.IHPEclass_rank}
                                                    onChange={(event) => onChangeIHPE_UG_to_Master(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                {
                                                    (IHPE_UG_to_Masters.length - 1) === index && (
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            style={{ alignItems: 'center' }}
                                                            className="w-70"
                                                            onClick={addNewIHPERow}
                                                        >
                                                            Add Row
                                                        </Button>
                                                    )
                                                }
                                                {
                                                    (IHPE_UG_to_Masters.length - 1) !== index && (
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            style={{ alignItems: 'center', backgroundColor: 'red' }}
                                                            className="w-70"
                                                            onClick={() => deleteIHPERow(index)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    ))
                                    }
                                    <Row className="mt-3" >
                                        <Col>
                                            <strong>Professional/Teaching Experience</strong>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3" >
                                        <Col>Designation</Col>
                                        <Col>Institution</Col>
                                        <Col>From Date</Col>
                                        <Col>To Date</Col>
                                        <Col></Col>
                                    </Row>
                                    {experience.map((row, index) => (
                                        <Row className="mt-3" key={`${index}_exp`}>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    name='expDesignation'
                                                    value={row.expDesignation}
                                                    onChange={(event) => onChangeExperience(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='text'
                                                    name='expInstitution'
                                                    value={row.expInstitution}
                                                    onChange={(event) => onChangeExperience(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='date'
                                                    name='expFromDate'
                                                    value={row.expFromDate}
                                                    onChange={(event) => onChangeExperience(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type='date'
                                                    name='expToDate'
                                                    value={row.expToDate}
                                                    onChange={(event) => onChangeExperience(index, event)}
                                                >
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                {
                                                    (experience.length - 1) === index && (
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            style={{ alignItems: 'center' }}
                                                            className="w-70"
                                                            onClick={addNewExperienceRow}
                                                        >
                                                            Add Row
                                                        </Button>
                                                    )
                                                }
                                                {
                                                    (experience.length - 1) !== index && (
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            style={{ alignItems: 'center', backgroundColor: 'red', width: '125px' }}
                                                            className="w-70"
                                                            onClick={() => deleteExperienceRow(index)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    ))
                                    }
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>The subject in which the candidate has qualified for the</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select onChange={onChangeInput} name='subjectQualified' value={subjectQualified}>
                                                <option value="">Please Select</option>
                                                <option value="Master's Degree">Master's Degree</option>
                                                <option value="Doctoral Degree">Doctoral Degree</option>
                                                <option value="Post Doctoral Degree">Post Doctoral Degree</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>The above master's Degree was awarded by</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select onChange={onChangeInput} name='degreeAwardedBy' value={degreeAwardedBy}>
                                                <option value="">Please Select</option>
                                                <option value="Faculty of Medicine">Faculty of Medicine</option>
                                                <option value="Faculty of Dentistry">Faculty of Dentistry</option>
                                                <option value="Faculty of Nursing">Faculty of Nursing</option>
                                                <option value="Faculty of Physiotherapy">Faculty of Physiotherapy</option>
                                                <option value="Faculty of Allied Health Sciences">Faculty of Allied Health Sciences</option>
                                                <option value="Faculty of AYUSH">Faculty of AYUSH</option>
                                                <option value="Others">Others</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>

                                    {degreeAwardedBy === "Others" &&
                                        <Row className="mt-3">
                                            <Col xs={12} sm={6}>If Others, Mention degree awarded by</Col>
                                            <Col xs={12} sm={6}>
                                                <Form.Control
                                                    type='text'
                                                    name='othersDegreeAwardedBy'
                                                    value={othersDegreeAwardedBy}
                                                    onChange={onChangeInput}
                                                >
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                            <Row>
                                <Col >
                                    <Button variant="primary"
                                        onClick={goToPreviousPage}
                                        className="mt-3 w-70">
                                        Back
                                    </Button>
                                </Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col></Col>
                                <Col>
                                    <Button
                                        variant="primary"
                                        onClick={onSaveClick}
                                        className="mt-3 w-70"
                                        disabled={saving}
                                    >
                                        Save & Next
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Card>
            </div>
        </Container>
    );
}

export default AcademicDetails