import * as React from 'react';
import swal from 'sweetalert';
import Spinner from './Spinner';
import { Form, Button, Container, Row, Col, Card, Table, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { GetCourselistwithCount, ExamScheduleUpdate, GetUnscheduledCount, getInstitutionName } from './services';


function ExamSchedule() {
    const navigate = useNavigate();
    const [course, setcourse] = React.useState([]);
    const [selectedinstitute, setselectedinstitute] = React.useState('');
    const [selectInstitute, setselectInstitute] = React.useState([]);
    const [selectedcourse, setselectedcourse] = React.useState('');
    const [applicationCount, setapplicationCount] = React.useState(0);
    const [modeofselect, setmodeofselect] = React.useState('');
    const [date, setdate] = React.useState('');
    const [fromtime, setfromtime] = React.useState('');
    const [totime, settotime] = React.useState('');
    const [applicationLimit, setapplicationLimit] = React.useState(0);
    const [session, setsession] = React.useState(null);
    const [venue, setvenue] = React.useState('');
    const [uncheduledCount, setunscheduledCount] = React.useState(0);
    const [spinner, setspinner] = React.useState(false);

    async function GetCourselistWithCount(institute) {
        try {
            const data = await GetCourselistwithCount({ institute });
            setcourse(data);
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        getInstitution();
    }, []);

    async function getInstitution() {
        const result = await getInstitutionName();
        setselectInstitute(result.result);
    }

    async function unScheduledcount(selectCourse) {
        const result = await GetUnscheduledCount({ "course": selectCourse, "institute": selectedinstitute });
        setunscheduledCount(result.count);
    };

    const courseChange = (e) => {
        setselectedcourse(e.target.value);
        unScheduledcount(e.target.value);
        course.find((object) => {
            if (object._id === e.target.value) {
                setapplicationCount(object.count);
            }
        });
    };

    const instituteChange = (e) => {
        setselectedcourse('');
        setapplicationCount('');
        setunscheduledCount('');
        setselectedinstitute(e.target.value)
        GetCourselistWithCount(e.target.value);
    };

    const modeofselectChange = (e) => {
        setmodeofselect(e.target.value);
    };

    const submit = async () => {
        try {
            setspinner(true);
            var data = {
                "selectedInstitute": selectedinstitute,
                "selectedCourse": selectedcourse,
                "modeOfSelection": modeofselect,
                "examorinterviewdate": date,
                "session": session,
                "fromTime": new Date(new Date().toDateString() + ' ' + fromtime),
                "toTime": new Date(new Date().toDateString() + ' ' + totime),
                "applicationLimit": parseInt(applicationLimit),
                "venue": venue
            };
            if (
                selectedcourse !== '' &&
                modeofselect !== '' &&
                date !== '' &&
                session !== null &&
                fromtime !== '' &&
                totime !== '' &&
                applicationLimit !== 0 &&
                venue !== '' &&
                selectedinstitute !== ''
            ) {
                await ExamScheduleUpdate(data);
            }
            else {
                swal({
                    title: "All fields are required. ",
                    icon: 'info'
                });
            }
            setselectedinstitute('');
            setselectedcourse('');
            setapplicationCount(0);
            setmodeofselect('');
            setdate('');
            setfromtime('');
            settotime('');
            setapplicationLimit(0);
            setsession(null);
            setvenue('');
            setunscheduledCount(0);
            getInstitution();
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>

            <Container className=" justify-content-center align-items-center" style={{ height: 'auto' }}>
                <Row className='mt-3'>
                    <Col className='mb-2' style={{ textAlign: 'center', color: "#ba0af0" }}>
                        <h5>Create Exam / Interview Schedule</h5>
                    </Col>
                </Row>
                <Card className='mt-2' style={{ width: '70%', height: '500px', marginLeft: '180px', marginBottom: '30px', padding: "20px" }}>
                    <Container>
                        <Form>
                            <Row className='mt-2'>
                                <Col sm={4}><strong>Total Count</strong></Col>
                                <Col sm={2}>{applicationCount}</Col>
                                <Col sm={4}><strong>Unscheduled Count</strong></Col>
                                <Col sm={2}>{uncheduledCount}</Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col sm={6}><strong>Select Institute</strong></Col>
                                <Col sm={6}><strong>Select Course</strong></Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col sm={6}>
                                    <Form.Select
                                        onChange={instituteChange}
                                        value={selectedinstitute}
                                    >
                                        <option>Select Institute</option>
                                        {
                                            selectInstitute.map((obj, index) => (
                                                <option
                                                    key={index}
                                                    name="institute"
                                                    value={obj._id}>
                                                    {obj._id}
                                                </option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                                <Col sm={6}>
                                    <Form.Select
                                        onChange={courseChange}
                                        value={selectedcourse}
                                    >
                                        <option>Select Course</option>
                                        {
                                            course.map((obj, index) => (
                                                <option
                                                    key={index}
                                                    name="course"
                                                    value={obj._id}>
                                                    {obj._id}
                                                </option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col><strong>Mode of Selection</strong></Col>
                                <Col><strong>Select Exam / Interview Date</strong></Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Form.Select
                                        onChange={modeofselectChange}
                                        value={modeofselect}
                                    >
                                        <option>Mode of Selection</option>
                                        <option name="modeofselection"
                                            value="Entrance Examination">
                                            Entrance Examination
                                        </option>
                                        <option name="modeofselection"
                                            value="Interview">
                                            Interview
                                        </option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={date}
                                        onChange={(e) => setdate(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col></Col>
                                <Col></Col>
                                <Col><strong> Select From time</strong></Col>
                                <Col><strong> Select To time</strong></Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col sm={3}><strong>Select Session</strong></Col>
                                <Col>
                                    <Form.Group
                                        value={session}
                                    >
                                        <Form.Check
                                            inline
                                            label="F.N"
                                            type="radio"
                                            id="session"
                                            name="session"
                                            value="Forenoon"
                                            checked={session === 'Forenoon'}
                                            onChange={(e) => setsession(e.target.value)}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="A.N"
                                            type="radio"
                                            id="session"
                                            name="session"
                                            value="Afternoon"
                                            checked={session === 'Afternoon'}
                                            onChange={(e) => setsession(e.target.value)}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='time'
                                        name='fromtime'
                                        value={fromtime}
                                        onChange={(e) => setfromtime(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type='time'
                                        name='totime'
                                        value={totime}
                                        onChange={(e) => settotime(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col sm={3}><strong>Application Limit per Session</strong></Col>
                                <Col sm={2}>
                                    <Form.Control
                                        type='number'
                                        value={applicationLimit}
                                        onChange={(e) => setapplicationLimit(e.target.value)}
                                    />
                                </Col>
                                <Col sm={2}><strong>Venue</strong></Col>
                                <Col sm={5}>
                                    <Form.Control
                                        type='text'
                                        value={venue}
                                        onChange={(e) => setvenue(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Form>
                        <Row className='mt-3'>
                            <Col style={{ textAlign: 'center' }}>
                                <Button onClick={submit}>Submit</Button>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            </Container>
        </>
    )
}

export default ExamSchedule