import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';

import { login } from './services';

function Login() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const submit = async (data) => {
    try {
      const user = await login(data);
      if (user) {
        localStorage.setItem("username", user.email);
        localStorage.setItem('role', user.role);
        navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
      swal({
        title: err.response.data,
        icon: 'error'
      });
    }
  };

  return (
    <>
      <Container className="d-flex align-items-center justify-content-center" style={{ height: '419px' }}>
        <div style={{ width: '440px', marginTop: '25px' }}>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(submit)}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control {...register('email', { required: true })} type="email" placeholder="Enter email" />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control {...register('password', { required: true, minLength: 6 })} type="password" placeholder="Password" />
                </Form.Group>

                <div className="d-flex justify-content-between align-items-center">
                  <div className="mt-3">
                    Don't have an account?
                    <Link to="/register">Register here</Link>.
                    <Link style={{ paddingLeft: '25px', textDecoration: 'none' }} to="/adminlogin">Admin</Link>
                  </div>
                  <Button variant="primary" type="submit" className="mt-3">
                    Login
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Link to="/forgotpassword">Forgot Password</Link>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default Login