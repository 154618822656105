import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Card, Form } from 'react-bootstrap';
import Spinner from './Spinner';
import swal from 'sweetalert';
import {
    getBatchByApplicationName,
    sendPaymentReminderByApplicationWithBatch
} from './services';

function PaymentReminder() {
    const navigate = useNavigate();
    const [spinner, setspinner] = React.useState(false);
    const [applicationName, setApplicationName] = React.useState('');
    const [selectedBatch, setSelectedBatch] = React.useState('');
    const [batch, setBatch] = React.useState([]);

    React.useEffect(() => {
        if (applicationName) {
            getBatch();
        }
    }, [applicationName]);

    const getBatch = async () => {
        try {
            setspinner(true);
            const result = await getBatchByApplicationName({ "applicationName": applicationName });
            if (result) {
                setBatch(result.Batch);
            }
            setspinner(false);

        } catch (err) {
            console.log(err);
        }
    };

    const sendPaymentReminder = async () => {
        if (applicationName && selectedBatch) {

            try {
                setspinner(true);
                const data = {
                    "applicationName": applicationName,
                    "batch": selectedBatch
                }
                const result = await sendPaymentReminderByApplicationWithBatch(data);
                setspinner(false);
                swal({
                    title: `${result.message}`,
                    icon: 'success'
                });
                setApplicationName('');
                setSelectedBatch('');
            } catch (err) {
                console.log(err);
            }
        } else {
            swal({
                title: "Select Application & Batch",
                icon: 'info'
            });
        }
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container className=" justify-content-center align-items-center" style={{ height: 'auto' }}>
                <Row className='mt-3'>
                    <Col className='mb-2' style={{ textAlign: 'center', color: "#ba0af0" }}>
                        <h5>Payment Reminder</h5>
                    </Col>
                </Row>

                <Card className='mt-5'>
                    <Row className="mt-3 mb-3" style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}>
                        <Col xs={12} sm={6}>
                            <Form.Select onChange={(e) => setApplicationName(e.target.value)}>
                                <option value="">Select Application</option>
                                <option value="MBBS_BDS_Application">MBBS and BDS Applications</option>
                                <option value="MDMS_Application">MDMS Applications</option>
                                <option value="EDP_Application">EDP Applications</option>
                                <option value="Fellowship_Application">Fellowship Applications</option>
                                <option value="Phd_Application">Ph.D Applications</option>
                                <option value="AHS_Application">UG/PG at AHS,Pharmacy,Nursing etc.</option>
                            </Form.Select>
                        </Col>

                        <Col xs={12} sm={6} style={{ textAlign: "end" }}>
                            <Form.Select onChange={(e) => setSelectedBatch(e.target.value)}>
                                <option value="">Select Batch</option>
                                {batch.length > 0 && batch.map((obj, index) => (
                                    <option key={index} value={obj._id}>{obj._id}</option>
                                ))}
                            </Form.Select>
                        </Col>

                    </Row>

                    <Row className="mt-3 mb-3 justify-content-center">
                        <Button
                            style={{ width: "150px" }}
                            type='primary'
                            onClick={sendPaymentReminder}
                        >Send</Button>
                    </Row>

                </Card>

            </Container>
        </>
    )
}

export default PaymentReminder