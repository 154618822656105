import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';

import { registerUser } from './services';

function Register() {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await registerUser(data);
      swal({
        title: response.message,
        icon: 'success'
      });
      navigate('/');
    } catch (err) {
      swal({
        title: err.response.data.message,
        icon: 'info'
      });
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ height: '419px' }}>
      <div style={{ width: '440px', marginTop: '20px' }}>
        <Row>
          <Col>
            <h2 className="text-center mb-4">Register</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control {...register('email', { required: true })} type="email" placeholder="Enter email" />

                {errors.email && (
                  <Form.Text className="text-error" style={{ color: "red" }}>
                    Email Id required.
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control {...register('password', { required: true, minLength: 6 })} type="password" placeholder="Password" />
                {
                  errors.password?.type === 'required' && (
                    <Form.Text className="text-error" style={{ color: "red" }}>
                      Password required.
                    </Form.Text>
                  )
                }

                {
                  errors.password?.type === 'minLength' && (
                    <Form.Text className="text-error" style={{ color: "red" }}>
                      Password should be minimum 6 character.
                    </Form.Text>
                  )
                }
              </Form.Group>

              <Form.Group controlId="formBasicConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  {...register('confirmPassword', {
                    required: true,
                    minLength: 6,
                    validate: (value) => {
                      if (watch('password') !== value) {
                        return "Your passwords do no match";
                      }
                    }
                  })}
                  type="password"
                  placeholder="Confirm Password"
                />
                {
                  errors.confirmPassword?.type === 'required' && (
                    <Form.Text className="text-error" style={{ color: "red" }} >
                      ConfirmPassword is required.
                    </Form.Text>
                  )
                }
                {
                  errors.confirmPassword?.type === 'minLength' && (
                    <Form.Text className="text-error" style={{ color: "red" }}>
                      Confirm Password should be minimum 6 character.
                    </Form.Text>
                  )
                }

                {
                  errors.confirmPassword?.type === 'validate' && (
                    <Form.Text className="text-error" style={{ color: "red" }}>
                      {errors.confirmPassword.message}
                    </Form.Text>
                  )
                }
              </Form.Group>
              <div style={{ textAlign: 'center' }}>
                <Button variant="primary" type="submit" className="mt-3">
                  Register
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Register