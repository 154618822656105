import { React, useState, useEffect } from 'react';
import { Row, Col, Tab, Tabs, Card, Table, Container, Button, Modal, Form } from 'react-bootstrap';
import { EyeFill, PenFill, TrashFill } from 'react-bootstrap-icons';
import { FloatButton, Tooltip } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Spinner from './Spinner';
import {
    addInstitute,
    getAllInstituteData,
    updateInstituteData,
    addCourse,
    updateCourse,
    getCourse,
    Coursedelete
} from './services';

function Addinstituteandcourse() {
    const [key, setKey] = useState('institute');
    const [instituteModal, setinstituteModal] = useState(false);
    const [courseModal, setcourseModal] = useState(false);
    const [instituteData, setinstituteData] = useState([]);
    const [selectedInstitute, setselectedInstitute] = useState('');
    const [selectedCourse, setselectedCourse] = useState('');
    const [spinner, setspinner] = useState(false);
    const [instituteEdit, setinstituteEdit] = useState(false);
    const [courseEdit, setcourseEdit] = useState(false);
    const [selectedInstituteCourse, setselectedInstituteCourse] = useState([]);
    const [selectedCampus, setselectedCampus] = useState('');
    const [selectedInstituteName, setselectedInstituteName] = useState('');

    const {
        register: registerInstitute,
        handleSubmit: handleSubmitInstitute,
        setValue: instituteSetValue,
        formState: { errors: instituteErrors } } = useForm();

    const {
        register: registerCourse,
        handleSubmit: handleSubmitCourse,
        setValue: courseSetValue,
        formState: { errors: courseErrors } } = useForm();

    useEffect(() => {
        getInstituteDetails();
    }, []);

    const addinstitute = () => {
        instituteSetValue('campus', '');
        instituteSetValue('instituteId', '');
        instituteSetValue('institute', '');
        instituteSetValue('status', '');
        setinstituteModal(true);
    };

    const addcourse = () => {
        setcourseModal(true);
        courseSetValue('courseName', '');
        courseSetValue('degree', '');
        courseSetValue('status', '');
    };

    const submitInstitute = async (data) => {
        try {
            if (instituteEdit) {
                setspinner(true);
                await updateInstituteData(data);
                getInstituteDetails();
                setinstituteModal(false);
                setinstituteEdit(false);
                setspinner(false);
            } else {
                setspinner(true);
                await addInstitute(data);
                getInstituteDetails();
                setinstituteModal(false);
                setspinner(false);
            }
        } catch (err) {
            console.log(err)
        }
    };

    const submitCourse = async (data) => {
        try {
            if (courseEdit) {
                setspinner(true);
                data._id = selectedInstitute;
                data.selectedCourseID = selectedCourse;
                updateCourse(data);
                setcourseModal(false);
                getCourseDetails();
                setcourseEdit(false);
                setspinner(false);
            } else {
                setspinner(true);
                data._id = selectedInstitute;
                addCourse(data);
                setspinner(false);
                getCourseDetails();
                setcourseModal(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getInstituteDetails = async () => {
        try {
            const result = await getAllInstituteData();
            setinstituteData(result);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getInstituteDetails();
        if (key === 'institute') {
            setselectedCampus('');
            setselectedInstituteName('');
        }
    }, [key])

    const viewCourse = async (data) => {
        try {
            setKey('course');
            setselectedInstitute(data._id);
            setselectedInstituteCourse(data.course);
            setselectedCampus(data.campus);
            setselectedInstituteName(data.institute);
            courseSetValue('courseName', '');
            courseSetValue('degree', '');
            courseSetValue('status', '');
        } catch (err) {
            console.log(err);
        }
    };

    const editInstitute = async (data) => {
        instituteSetValue('campus', data.campus);
        instituteSetValue('instituteId', data.instituteId);
        instituteSetValue('institute', data.institute);
        instituteSetValue('status', data.status);
        instituteSetValue('id', data._id);
        setinstituteEdit(true);
        setinstituteModal(true);
    };

    const editCourse = async (data) => {
        courseSetValue('courseName', data.courseName);
        courseSetValue('degree', data.degree);
        courseSetValue('status', data.status);
        setselectedCourse(data._id);
        setcourseEdit(true);
        setcourseModal(true);
    };

    const deleteCourse = async (data) => {
        try {
            setspinner(true);
            data.insti_id = selectedInstitute;
            Coursedelete(data);
            getCourseDetails();
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const getCourseDetails = async () => {
        try {
            const result = await getCourse({ "_id": selectedInstitute });
            setselectedInstituteCourse(result.course);
        } catch (err) {
            console.log(err);
        }
    };

    const closeInstituteModel = () => {
        setinstituteModal(false);
        setinstituteEdit(false);
    };

    const closeCourseModel = () => {
        setcourseEdit(false);
        setcourseModal(false);
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Row className='mt-3'>
                <Col className='mb-2' style={{ textAlign: 'center', color: "#ba0af0" }}>
                    <h5>Add Institute and Course</h5>
                </Col>
            </Row>
            <div style={{ display: "flex", marginBottom: "40px" }}>
                <Card style={{ width: "80%", margin: "0 10% 0 10%" }}>
                    <Tabs
                        id="controlled-taB"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="institute" title="Institute">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>S.no</th>
                                        <th>Institute Id</th>
                                        <th>Institute</th>
                                        <th>Campus</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        instituteData.map((obj, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>

                                                <td>{obj.instituteId}</td>

                                                <td>{obj.institute}</td>

                                                <td
                                                    style={{
                                                        backgroundColor:
                                                            obj.campus === 'Pondicherry Campus' ? '#EF9A9A' :
                                                                obj.campus === 'Chennai Campus' ? '#F48FB1' :
                                                                    obj.campus === 'Karaikal Campus' ? '#CE93D8' :
                                                                        'white'
                                                    }}
                                                >{obj.campus}</td>

                                                <td style={{ backgroundColor: obj.status === 'Active' ? '#8BC34A' : '#F44336' }}>{obj.status}</td>

                                                <td style={{ display: "flex", justifyContent: "space-around" }}>
                                                    <Tooltip title="view course">
                                                        <EyeFill
                                                            style={{ fontSize: "20px", cursor: "pointer" }}
                                                            onClick={() => viewCourse(obj)}
                                                        />
                                                    </Tooltip>

                                                    <Tooltip title="edit institute">
                                                        <PenFill
                                                            style={{ fontSize: "20px", cursor: "pointer" }}
                                                            onClick={() => editInstitute(obj)}
                                                        />
                                                    </Tooltip>

                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Tab>

                        <Tab eventKey="course" title="Course" disabled
                        >
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>S.no</th>
                                        <th>Course</th>
                                        <th>Degree</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedInstituteCourse.map((obj, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{obj.courseName}</td>
                                            <td style={{ backgroundColor: obj.degree === 'UG' ? '#64B5F6' : '#7986CB' }}>{obj.degree}</td>

                                            <td style={{ backgroundColor: obj.status === 'Active' ? '#8BC34A' : '#F44336' }}>{obj.status}</td>
                                            <td style={{ display: "flex", justifyContent: "space-around" }}>
                                                <Tooltip title="edit course">
                                                    <PenFill
                                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                                        onClick={() => editCourse(obj)}
                                                    />
                                                </Tooltip>

                                                <Tooltip title="delete course">
                                                    <TrashFill
                                                        style={{ fontSize: "20px", cursor: "pointer" }}
                                                        onClick={() => deleteCourse(obj)}
                                                    />
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </Table>
                        </Tab>

                    </Tabs>
                </Card>
                <div>
                    {key === "institute" &&
                        <FloatButton
                            type="primary"
                            shape="circle"
                            style={{ position: "fixed", bottom: "40%", right: "50px" }}
                            tooltip={<div>Add Institute</div>}
                            icon={<PlusCircleFilled />}
                            onClick={addinstitute}
                        />
                    }
                    {key === "course" &&
                        <FloatButton
                            type="primary"
                            shape="circle"
                            style={{ position: "fixed", bottom: "40%", right: "50px" }}
                            tooltip={<div>Add Course</div>}
                            icon={<PlusCircleFilled />}
                            onClick={addcourse}
                        />
                    }
                </div>
                <div class="floating-text">
                    <span>{selectedInstituteName}</span>
                    <span style={{ marginLeft: "30px" }}>{selectedCampus}</span>
                </div>
            </div>

            <Modal
                show={instituteModal}
                onHide={() => setinstituteModal(false)}
                size="800px"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Form onSubmit={handleSubmitInstitute(submitInstitute)}>
                    <Modal.Header >
                    </Modal.Header>
                    <Container style={{ margin: "20px", width: "450px" }}>
                        <Row className='mt-2'>
                            <strong>Select campus:</strong>
                            <Form.Select
                                {...registerInstitute('campus', {
                                    required: "Select campus"
                                })}
                            >
                                <option value="">Select campus</option>
                                <option value="Pondicherry Campus">Pondicherry Campus</option>
                                <option value="Chennai Campus">Chennai Campus</option>
                                <option value="Karaikal Campus">Karaikal Campus</option>
                            </Form.Select>
                            {
                                instituteErrors?.campus &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {instituteErrors.campus.message}
                                </Form.Text>
                            }
                        </Row>
                        <Row className='mt-2'>
                            <strong>Enter institute name:</strong>
                            <Form.Control
                                type='text'
                                {...registerInstitute('institute', { required: "Required field" })}
                            >
                            </Form.Control>
                            {
                                instituteErrors?.institute &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {instituteErrors.institute.message}
                                </Form.Text>
                            }
                        </Row>
                        <Row className='mt-2'>
                            <strong>Enter institute id:</strong>
                            <Form.Control
                                type='text'
                                {...registerInstitute('instituteId', { required: "Required field" })}
                            >
                            </Form.Control>
                            {
                                instituteErrors?.instituteId &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {instituteErrors.instituteId.message}
                                </Form.Text>
                            }
                        </Row>
                        <Row className='mt-2'>
                            <strong>Select status:</strong>
                            <Form.Select
                                {...registerInstitute('status', {
                                    required: "Select status"
                                })}
                            >
                                <option value="">Select status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Form.Select>
                            {
                                courseErrors?.status &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {courseErrors.status.message}
                                </Form.Text>
                            }
                        </Row>
                    </Container>
                    <Modal.Footer style={{ justifyContent: "space-between" }}>
                        <Button
                            onClick={closeInstituteModel}
                            style={{ backgroundColor: "red" }}
                        >Close</Button>
                        <Button type='submit'
                        >{instituteEdit ? "Update" : "Submit"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                show={courseModal}
                onHide={() => setcourseModal(false)}
                size="800px"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Form onSubmit={handleSubmitCourse(submitCourse)}>
                    <Modal.Header>
                    </Modal.Header>
                    <Container style={{ margin: "20px", width: "450px" }}>
                        <Row className='mt-2'>
                            <strong>Enter course name:</strong>
                            <Form.Control
                                type='text'
                                {...registerCourse('courseName', { required: "Required field" })}
                            >
                            </Form.Control>
                            {
                                courseErrors?.courseName &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {courseErrors.courseName.message}
                                </Form.Text>
                            }
                        </Row>
                        <Row className='mt-2'>
                            <strong>Select degree:</strong>
                            <Form.Select
                                {...registerCourse('degree', {
                                    required: "Select degree"
                                })}
                            >
                                <option value="">Select degree</option>
                                <option value="UG">UG</option>
                                <option value="PG">PG</option>
                            </Form.Select>
                            {
                                courseErrors?.degree &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {courseErrors.degree.message}
                                </Form.Text>
                            }
                        </Row>
                        <Row className='mt-2'>
                            <strong>Select status:</strong>
                            <Form.Select
                                {...registerCourse('status', {
                                    required: "Select status"
                                })}
                            >
                                <option value="">Select status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Form.Select>
                            {
                                courseErrors?.status &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {courseErrors.status.message}
                                </Form.Text>
                            }
                        </Row>

                    </Container>
                    <Modal.Footer style={{ justifyContent: "space-between" }}>
                        <Button
                            onClick={closeCourseModel}
                            style={{ backgroundColor: "red" }}
                        >Close</Button>
                        <Button type='submit'>{courseEdit ? "Update" : "Submit"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default Addinstituteandcourse