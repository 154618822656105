import React from 'react';
import { Button, Container, Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {
    saveMDMSapplication,
    MDMS_application_count_MGMCRI,
    MDMS_application_count_SSSMCRI,
    MDMS_application_count_IGIDS,
    getMDMSapplicationDetailsByApplicationNo,
    updateMDMSapplication
} from './services';
import Spinner from './Spinner';
function getInitialState() {
    return {
        applicantName: '',
        institute: '',
        mobileNo: '',
        mailid: '',
        gender: '',
        dateofbirth: '',
        parentName: '',
        parentmailid: '',
        parentmobileNo: '',
        address: '',
        course: '',
        NEETRollNo: '',
        NEETRank: '',
        NEETMark: '',
        submit: false,
        edit: false
    }
};

function MDMSapplication() {
    const [spinner, setspinner] = React.useState(false);
    const [state, setState] = React.useState(getInitialState());
    const navigate = useNavigate();
    const regiNoBegin = 2000;
    const IGIDSregnobegin = 1000;

    const goToDashboard = () => {
        localStorage.removeItem("applicationNo");
        localStorage.removeItem("institute");
        navigate("/dashboard");
    };

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const onChangeInput = (event) => {
        const { target: { name, value, checked } } = event;
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const SaveandNext = async () => {
        try {
            if (state.edit === false) {
                if (state.institute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI") {
                    let count = await MDMS_application_count_MGMCRI();
                    setState((prevState) => ({
                        ...prevState,
                        userName: localStorage.getItem("username"),
                        paymentStatus: "Pending",
                        applicationStatus: "Pending",
                        applicationNo: `T23M0${regiNoBegin + count.result + 1}`,
                        submit: true
                    }));
                }
                if (state.institute === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {
                    let count = await MDMS_application_count_SSSMCRI();
                    setState((prevState) => ({
                        ...prevState,
                        userName: localStorage.getItem("username"),
                        paymentStatus: "Pending",
                        applicationStatus: "Pending",
                        applicationNo: `T23S0${regiNoBegin + count.result + 1}`,
                        submit: true
                    }));
                }
                if (state.institute === "Indira Gandhi Institute Of Dental Sciences - IGIDS") {
                    let count = await MDMS_application_count_IGIDS();
                    setState((prevState) => ({
                        ...prevState,
                        userName: localStorage.getItem("username"),
                        paymentStatus: "Pending",
                        applicationStatus: "Pending",
                        applicationNo: `T23I0${IGIDSregnobegin + count.result + 1}`,
                        submit: true
                    }));
                }
            }
            if (state.edit === true) {
                update();
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const update = async () => {
        try {
            setspinner(true);
            const result = await updateMDMSapplication(state);
            setState((prevState) => ({
                ...prevState,
                edit: false
            }));
            setspinner(false);
            navigate("/mdmsdocumentsupload");
        }
        catch (err) {
            console.log(err);
        }
    };

    async function save() {
        try {
            setspinner(true);
            await saveMDMSapplication(state);
            localStorage.setItem("applicationNo", state.applicationNo);
            localStorage.setItem("institute", state.institute);
            setState((prevState) => ({
                ...prevState,
                submit: false
            }));
            setspinner(false);
            navigate("/mdmsdocumentsupload");
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        if (state.submit) {
            save();
        }
    }, [state.submit]);

    React.useEffect(() => {
        const ApplicationNo = localStorage.getItem("applicationNo");
        if (ApplicationNo) {
            getApplicationDetailsByApplicationNo(ApplicationNo);
        }
    }, []);

    const getApplicationDetailsByApplicationNo = async (ApplicationNo) => {
        try {
            const result = await getMDMSapplicationDetailsByApplicationNo({ "applicationNo": ApplicationNo });
            setState(result);
            setState((prevState) => ({
                ...prevState,
                edit: true
            }))
        }
        catch (err) {
            console.log(err);
        }
    };

    const getCourseOptions = () => {
        if (state.institute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="Anatomy">Anatomy</option>
                    <option name="course" value="Biochemistry">Biochemistry</option>
                    <option name="course" value="Physiology">Physiology</option>
                    <option name="course" value="Microbiology">Microbiology</option>
                    <option name="course" value="Pharmacology">Pharmacology</option>
                    <option name="course" value="Community Medicine">Community Medicine</option>
                    <option name="course" value="Pathology">Pathology</option>
                    <option name="course" value="Anaesthesiology">Anaesthesiology</option>
                    <option name="course" value="Ophthalmology">Ophthalmology</option>
                    <option name="course" value="Psychiatry">Psychiatry</option>
                    <option name="course" value="Otorhinolaryngology (E.N.T.)">Otorhinolaryngology (E.N.T.)</option>
                    <option name="course" value="General Medicine">General Medicine</option>
                    <option name="course" value="General Surgery">General Surgery</option>
                    <option name="course" value="Paediatrics">Paediatrics</option>
                    <option name="course" value="Respiratory Medicine">Respiratory Medicine</option>
                    <option name="course" value="Orthopaedics">Orthopaedics</option>
                    <option name="course" value="Obstetrics & Gynaecology">Obstetrics & Gynaecology</option>
                    <option name="course" value="Dermatology, Venerology & Leprosy">Dermatology, Venerology & Leprosy</option>
                    <option name="course" value="Radio diagnosis">Radio diagnosis</option>
                </React.Fragment>
            )
        }
        if (state.institute === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="Microbiology">Microbiology</option>
                    <option name="course" value="Pharmacology">Pharmacology</option>
                    <option name="course" value="Forensic Medicine">Forensic Medicine</option>
                    <option name="course" value="Community Medicine">Community Medicine</option>
                    <option name="course" value="Pathology">Pathology</option>
                    <option name="course" value="Anaesthesiology">Anaesthesiology</option>
                    <option name="course" value="Ophthalmology">Ophthalmology</option>
                    <option name="course" value="Otorhinolaryngology (E.N.T.)">Otorhinolaryngology (E.N.T.)</option>
                    <option name="course" value="General Medicine">General Medicine</option>
                    <option name="course" value="General Surgery">General Surgery</option>
                    <option name="course" value="Paediatrics">Paediatrics</option>
                    <option name="course" value="Respiratory Medicine">Respiratory Medicine</option>
                    <option name="course" value="Orthopaedics">Orthopaedics</option>
                    <option name="course" value="Obstetrics & Gynaecology">Obstetrics & Gynaecology</option>
                    <option name="course" value="Dermatology, Venerology & Leprosy">Dermatology, Venerology & Leprosy</option>
                    <option name="course" value="Radio diagnosis">Radio diagnosis</option>
                </React.Fragment>
            )

        }
        if (state.institute === "Indira Gandhi Institute Of Dental Sciences - IGIDS") {
            return (
                <React.Fragment>
                    <option>Select Course</option>
                    <option name="course" value="Oral Medicine & Radiology">Oral Medicine & Radiology</option>
                    <option name="course" value="Conservative Dentistry and Endodontics">Conservative Dentistry and Endodontics</option>
                    <option name="course" value="Oral and Maxillofacial Surgery">Oral and Maxillofacial Surgery</option>
                    <option name="course" value="Orthodontics & Dentofacial Orthopaedics">Orthodontics & Dentofacial Orthopaedics</option>
                    <option name="course" value="Pedodontics & Preventive Dentistry">Pedodontics & Preventive Dentistry</option>
                    <option name="course" value="Periodontology">Periodontology</option>
                    <option name="course" value="Prosthodontics and Crown & Bridge">Prosthodontics and Crown & Bridge</option>
                    <option name="course" value="Oral Pathology and Microbiology">Oral Pathology and Microbiology</option>
                    <option name="course" value="Public Health Dentistry">Public Health Dentistry</option>
                </React.Fragment>
            )
        }
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row className='mt-4' style={{ width: "100%" }}>
                    <Col>
                        <Button variant="primary" type="button"
                            onClick={goToDashboard}
                        >
                            Go To Dashboard
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                        <Button variant="primary" type="button"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-4 mb-5' >
                    <Card style={{ width: "100%", padding: 16 }}>
                        <Form>
                            <Row style={{ textAlign: "center", color: '#fd7e14' }}>
                                <Col><h4>MD / MS / MDS Admission</h4></Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={6}>Name of the Applicant<br />
                                    <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(in UG Certificate)</span>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='applicantName'
                                        value={state.applicantName}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            {
                                state.edit === false &&
                                <div>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Select Institute</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select onChange={onChangeInput} name='institute' value={state.institute}>
                                                <option>Select Institute</option>
                                                <option name="institute"
                                                    value="Mahatma Gandhi Medical College & Research Institute - MGMCRI">
                                                    Mahatma Gandhi Medical College & Research Institute - MGMCRI</option>
                                                <option name="institute"
                                                    value="Shri Sathya Sai Medical College & Research Institute - SSSMCRI">
                                                    Shri Sathya Sai Medical College & Research Institute - SSSMCRI
                                                </option>
                                                <option name="institute"
                                                    value="Indira Gandhi Institute Of Dental Sciences - IGIDS">
                                                    Indira Gandhi Institute Of Dental Sciences - IGIDS
                                                </option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={6}>Course</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select onChange={onChangeInput} name='course' value={state.course}>
                                                {getCourseOptions()}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>NEET Roll No</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='NEETRollNo'
                                        value={state.NEETRollNo}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>NEET Rank</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='NEETRank'
                                        value={state.NEETRank}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>NEET Mark</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='NEETMark'
                                        value={state.NEETMark}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Mobile No</Col>
                                <Col xs={12} sm={6}>
                                    <InputGroup>
                                        <InputGroup.Text id="mobile-prefix">+91</InputGroup.Text>
                                        <Form.Control
                                            name='mobileNo'
                                            value={state.mobileNo}
                                            type="tel"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            maxLength="10"
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </InputGroup>

                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Mail Id</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='email'
                                        name='mailid'
                                        value={state.mailid}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Gender</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="Male"
                                            type="radio"
                                            id="option-1"
                                            name="gender"
                                            value="Male"
                                            checked={state.gender === 'Male'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Female"
                                            type="radio"
                                            id="option-2"
                                            name="gender"
                                            value="Female"
                                            checked={state.gender === 'Female'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Third Gender"
                                            type="radio"
                                            id="option-2"
                                            name="gender"
                                            value="Third Gender"
                                            checked={state.gender === 'Third Gender'}
                                            onChange={onChangeInput}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Date of Birth</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='Date'
                                        name='dateofbirth'
                                        value={state.dateofbirth}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Name of Parent / Guardian</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='parentName'
                                        value={state.parentName}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Parent Mail Id</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='email'
                                        name='parentmailid'
                                        value={state.parentmailid}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Parent Mobile No</Col>
                                <Col xs={12} sm={6}>
                                    <InputGroup>
                                        <InputGroup.Text id="mobile-prefix">+91</InputGroup.Text>
                                        <Form.Control
                                            name='parentmobileNo'
                                            value={state.parentmobileNo}
                                            type="tel"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            maxLength="10"
                                            onChange={onChangeInput}
                                        >
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Address</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='address'
                                        as="textarea"
                                        value={state.address}
                                        onChange={onChangeInput}
                                    >
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col style={{ textAlign: "center" }}>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        style={{ alignItems: 'center', width: '150px' }}
                                        className="w-70"
                                        onClick={SaveandNext}
                                    >
                                        Save & Next
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default MDMSapplication