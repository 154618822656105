import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Card, Form } from 'react-bootstrap';
import {
    MBBS_BDS_DocumentsUpload,
    get_MBBS_uploaded_documents,
    deleteMBBSPaymentDoc
} from './services';
import Spinner from './Spinner';
import swal from 'sweetalert';

function MBBSandBDSPayment() {
    const [DDNo, SetDDNo] = React.useState('');
    const [RTGSNo, SetRTGSNo] = React.useState('');
    const navigate = useNavigate();
    const [docname, Setdocname] = React.useState('');
    const [file, Setfile] = React.useState();
    const [spinner, setspinner] = React.useState(false);
    const [selectedInstitute, setSelectedeInstitute] = React.useState('');
    const [filedata, setfiledata] = React.useState({});
    const fileInputRef = React.useRef(null);

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const goToDashboard = () => {
        localStorage.removeItem("applicationNo");
        localStorage.removeItem("institute");
        navigate("/dashboard");
    };

    const docnameChange = (event) => {
        Setdocname(event.target.value);
    };

    const handleFileInputChange = (event) => {
        const { target: { files } } = event;
        if (!files[0]) {
            return;
        }

        Setfile(files[0]);
    };

    React.useEffect(() => {
        GetUploadedDocuments();
    }, []);

    const GetUploadedDocuments = async () => {
        const doc = await get_MBBS_uploaded_documents({ applicationNo: localStorage.getItem('applicationNo') });
        doc.uploadDocuments.forEach((object) => {
            if (object.documentName === "RTGS" || object.documentName === "Demand Draft") {
                setfiledata(object);
            }
            else {
                setfiledata({});
            }
        })

    };

    const viewfile = (filedata) => {
        if (filedata.filePath) {
            window.open(process.env.REACT_APP_SERVICE_URL + "/documents/" + filedata.filePath, "_blank");
        }
        else {
            swal({
                title: "File Not Found.",
                icon: 'info'
            });
        }
    };

    const deletefile = async (filedata) => {
        try {
            setspinner(true);
            await deleteMBBSPaymentDoc({ filedata, applicationNo: localStorage.getItem("applicationNo") });
            GetUploadedDocuments();
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleUpload = async () => {
        try {
            if (file) {
                if (DDNo) {
                    const formData = new FormData();
                    formData.append('paymentTransactionNo', DDNo);
                    formData.append('document_name', docname);
                    formData.append('file', file);
                    formData.append('applicationNo', localStorage.getItem('applicationNo'));
                    await MBBS_BDS_DocumentsUpload(formData);
                    GetUploadedDocuments();
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    };
                    SetDDNo('');
                };
                if (RTGSNo) {
                    const formData = new FormData();
                    formData.append('paymentTransactionNo', RTGSNo);
                    formData.append('document_name', docname);
                    formData.append('file', file);
                    formData.append('applicationNo', localStorage.getItem('applicationNo'));
                    await MBBS_BDS_DocumentsUpload(formData);
                    GetUploadedDocuments();
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    };
                    SetRTGSNo('');
                };

            }
            if (!DDNo && !RTGSNo) {
                swal({
                    title: "Please Enter Transaction or Demand Draft No",
                    icon: 'info'
                });
            }
            if (!file) {
                swal({
                    title: "Please Select File",
                    icon: 'info'
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        setSelectedeInstitute(localStorage.getItem("institute"));
    }, []);

    const Submit = async () => {
        try {
            const doc = await get_MBBS_uploaded_documents({ applicationNo: localStorage.getItem('applicationNo') });
            doc.uploadDocuments.forEach((obj) => {
                if (obj.documentName === "RTGS" || obj.documentName === "Demand Draft") {
                    swal({
                        title: "Application Submitted Successfully.",
                        icon: 'success',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    }).then(function () {
                        localStorage.removeItem("applicationNo");
                        localStorage.removeItem("institute");
                        navigate("/dashboard");
                    });
                }
                else {
                    swal({
                        title: "Please Upload Payment Document.",
                        icon: 'info',
                    })
                }
            })
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row className='mt-4' style={{ width: "100%" }}>
                    <Col>
                        <Button variant="primary" type="button"
                            onClick={goToDashboard}
                        >
                            Go To Dashboard
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                        <Button variant="primary" type="button"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-4 mb-5' >
                    <Card style={{ width: "60%", padding: 16 }}>
                        <Form>
                            {
                                selectedInstitute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI" &&
                                <div>
                                    <Row className='mt-3' style={{ textAlign: "center" }}>
                                        <Col><strong>RTGS / Online Bank Transfer Details</strong></Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>A/C Name</Col>
                                        <Col xs={12} sm={8}>Mahatma Gandhi Medical College and Research Institute</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>A/C No</Col>
                                        <Col xs={12} sm={8}>513230975</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>Bank</Col>
                                        <Col xs={12} sm={8}>Indian Bank</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>Branch</Col>
                                        <Col xs={12} sm={8}>Pondicherry Main Branch</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>IFSC Code</Col>
                                        <Col xs={12} sm={8}>IDIB000P042</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>SWIFT Code</Col>
                                        <Col xs={12} sm={8}>IDIBINBBPON</Col>
                                    </Row>
                                </div>
                            }
                            {
                                selectedInstitute === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI" &&
                                <div>
                                    <Row className='mt-3' style={{ textAlign: "center" }}>
                                        <Col><strong>RTGS / Online Bank Transfer Details</strong></Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>A/C Name</Col>
                                        <Col xs={12} sm={8}>Shri Sathya Sai Medical College & Research Institute</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>A/C No</Col>
                                        <Col xs={12} sm={8}>845729805</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>Bank</Col>
                                        <Col xs={12} sm={8}>Indian Bank</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>Branch</Col>
                                        <Col xs={12} sm={8}>Ashok Nagar, Chennai 600083</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>IFSC Code</Col>
                                        <Col xs={12} sm={8}>IDIB000A031</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>SWIFT Code</Col>
                                        <Col xs={12} sm={8}>IDIBINBBTSY</Col>
                                    </Row>
                                </div>
                            }
                            {
                                selectedInstitute === "Indira Gandhi Institute Of Dental Sciences - IGIDS" &&
                                <div>
                                    <Row className='mt-3' style={{ textAlign: "center" }}>
                                        <Col><strong>RTGS / Online Bank Transfer Details</strong></Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>A/C Name</Col>
                                        <Col xs={12} sm={8}>Indira Gandhi Institute of Dental Sciences</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>A/C No</Col>
                                        <Col xs={12} sm={8}>734599917</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>Bank</Col>
                                        <Col xs={12} sm={8}>Indian Bank</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>Branch</Col>
                                        <Col xs={12} sm={8}>Pondicherry Main Branch</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>IFSC Code</Col>
                                        <Col xs={12} sm={8}>IDIB000P042</Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>SWIFT Code</Col>
                                        <Col xs={12} sm={8}>IDIBINBBPON</Col>
                                    </Row>
                                </div>
                            }
                            <Row className='mt-3'>
                                <Col xs={12} sm={5}>
                                    <Form.Select onChange={docnameChange} name='paymentmethod'>
                                        <option>Select Payment Method</option>
                                        <option value="RTGS">RTGS / Online Bank Transfer</option>
                                        <option value="Demand Draft">Demand Draft</option>
                                    </Form.Select>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        onClick={handleUpload}
                                        disabled={!DDNo && !RTGSNo}
                                        value="Upload"
                                    />
                                </Col>
                            </Row>
                            {
                                docname === "Demand Draft" &&
                                <Row className="mt-3">
                                    <Col xs={12} sm={6}>Demand Draft No</Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='Demand Draft No'
                                            value={DDNo}
                                            onChange={(e) => SetDDNo(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }
                            {
                                docname === "RTGS" &&
                                <Row className="mt-3">
                                    <Col xs={12} sm={6}>Transaction No</Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='RTGS No'
                                            value={RTGSNo}
                                            onChange={(e) => SetRTGSNo(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }
                            {
                                (filedata.documentName === "RTGS" || filedata.documentName === "Demand Draft") &&
                                <Row className="mt-3">
                                    <Col xs={6} sm={6}>{filedata.documentName}</Col>
                                    <Col xs={6} sm={3}>
                                        <input
                                            style={{ marginRight: '10px', marginLeft: '100px' }}
                                            onClick={() => viewfile(filedata)}
                                            type="button"
                                            value="view"
                                        />
                                    </Col>
                                    <Col xs={6} sm={3}>
                                        <input
                                            onClick={() => deletefile(filedata)}
                                            type="button"
                                            value="delete"
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row className='mt-3'>
                                <Col style={{ textAlign: "center" }}>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        style={{ alignItems: 'center', width: '150px' }}
                                        className="w-70"
                                        onClick={Submit}
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default MBBSandBDSPayment