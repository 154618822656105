import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Card, Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { List, Divider } from 'antd';
import { MortarboardFill, Upload, Download } from 'react-bootstrap-icons';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import Spinner from './Spinner';
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import {
    studentRegistrationMD_MS_MDS,
    studentRegistrationMD_MS_MDS_DocUpload,
    studentRegistrationMD_MS_MDS_UploadedDocList,
    studentRegistrationMD_MS_MDS_DeleteDoc,
    studentRegistrationMD_MS_MDS_DownloadDeclaration,
    studentRegistrationMD_MS_MDS_CheckDuplicateReg
} from './services';

function StudentRegistration_MD_MS_MDS() {
    const [docname, Setdocname] = React.useState('');
    const [docList, setDocList] = React.useState([]);
    const [spinner, setspinner] = React.useState(false);
    const [age, setage] = React.useState(0);
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
    const formWizardRef = React.createRef();
    const [doc, setdoc] = React.useState();
    const [dataSaved, setDataSaved] = React.useState(false);
    const [programme, setprogramme] = React.useState('');
    const [selectedInstitute, setSelectedInstitute] = React.useState('');
    const fileInputRef = React.useRef(null);
    const fileNameRef = React.useRef(null);
    const [TotalObtained, setTotalObtained] = React.useState(0);
    const [TotalMax, setTotalMax] = React.useState(0);
    const [TotalPercent, setTotalPercent] = React.useState(0);
    const [academicQualification, setacademicQualification] = React.useState([{ programmes: '', percentage: 0, obtainedMarks: 0, month_and_yearofpassing: '', maximumMarks: 0, No_of_attempts: 0 }]);
    const navigate = useNavigate();

    const declarationFormList = [
        "Application form",
        "Anti ragging - undertaking reference no",
        "Undertaking for publication of dissertation",
        "Joint undertaking - payment of tution fees",
        "Undertaking regarding institution regulations",
        "Hostel Application Form"
    ]

    const {
        register,
        setValue,
        watch,
        control,
        trigger,
        formState: { errors, isValid }
    } = useForm({ mode: 'onBlur' });

    const wholeData = watch();
    const studentName = watch('name');
    const dateOfBirth = watch('dateofbirth');
    const institute = watch('institute');
    const name = watch('name');
    const mobileNo = watch('mobileNo');
    const residentialStatus = watch('residentialStatus');

    const getSpecialisation = () => {

        if (selectedInstitute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI") {

            return (
                <React.Fragment>
                    <option key="0" value="">Please Select</option>
                    <option key="1" value="MD ANATOMY">MD ANATOMY</option>
                    <option key="2" value="MD PHYSIOLOGY">MD PHYSIOLOGY</option>
                    <option key="3" value="MD BIOCHEMISTRY">MD BIOCHEMISTRY</option>
                    <option key="4" value="MD PATHOLOGY">MD PATHOLOGY</option>
                    <option key="5" value="MD PHARMACOLOGY">MD PHARMACOLOGY</option>
                    <option key="6" value="MD MICROBIOLOGY">MD MICROBIOLOGY</option>
                    <option key="7" value="MD COMMUNITY MEDICINE">MD COMMUNITY MEDICINE</option>
                    <option key="8" value="MD GENERAL MEDICINE">MD GENERAL MEDICINE</option>
                    <option key="9" value="MD RESPIRATORY MEDICINE">MD RESPIRATORY MEDICINE</option>
                    <option key="10" value="MD PSYCHIATRY">MD PSYCHIATRY</option>
                    <option key="11" value="MD DERMATOLOGY">MD DERMATOLOGY</option>
                    <option key="12" value="MD PAEDIATRICS">MD PAEDIATRICS</option>
                    <option key="13" value="MS GENERAL SURGERY">MS GENERAL SURGERY</option>
                    <option key="14" value="MS ORTHOPAEDICS">MS ORTHOPAEDICS</option>
                    <option key="15" value="MS OPTHALMOLOGY">MS OPTHALMOLOGY</option>
                    <option key="16" value="MS OTO-RHINOLARYNGOLOGY">MS OTO-RHINOLARYNGOLOGY</option>
                    <option key="17" value="MS OBS. & GYNAE">MS OBS. & GYNAE</option>
                    <option key="18" value="MD ANAESTHESIOLOGY">MD ANAESTHESIOLOGY</option>
                    <option key="19" value="MD RADIO-DIAGNOSIS">MD RADIO-DIAGNOSIS</option>
                </React.Fragment>
            )
        };
        if (selectedInstitute === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {

            return (
                <React.Fragment>
                    <option key="20" value="">Please Select</option>
                    <option key="21" value="MD PATHOLOGY">MD PATHOLOGY</option>
                    <option key="22" value="MD PHARMACOLOGY">MD PHARMACOLOGY</option>
                    <option key="23" value="MD MICROBIOLOGY">MD MICROBIOLOGY</option>
                    <option key="24" value="MD COMMUNITY MEDICINE">MD COMMUNITY MEDICINE</option>
                    <option key="25" value="MD GENERAL MEDICINE">MD GENERAL MEDICINE</option>
                    <option key="26" value="MD RESPIRATORY MEDICINE">MD RESPIRATORY MEDICINE</option>
                    <option key="27" value="MD PSYCHIATRY">MD PSYCHIATRY</option>
                    <option key="28" value="MD DERMATOLOGY">MD DERMATOLOGY</option>
                    <option key="29" value="MD PAEDIATRICS">MD PAEDIATRICS</option>
                    <option key="30" value="MS GENERAL SURGERY">MS GENERAL SURGERY</option>
                    <option key="31" value="MS ORTHOPAEDICS">MS ORTHOPAEDICS</option>
                    <option key="32" value="MS OPTHALMOLOGY">MS OPTHALMOLOGY</option>
                    <option key="33" value="MS OTO-RHINOLARYNGOLOGY">MS OTO-RHINOLARYNGOLOGY</option>
                    <option key="34" value="MS OBS. & GYNAE">MS OBS. & GYNAE</option>
                    <option key="35" value="MD ANAESTHESIOLOGY">MD ANAESTHESIOLOGY</option>
                    <option key="36" value="MD RADIO-DIAGNOSIS">MD RADIO-DIAGNOSIS</option>
                </React.Fragment>
            )
        };
        if (selectedInstitute === "Indira Gandhi Institute Of Dental Sciences - IGIDS") {

            return (
                <React.Fragment>
                    <option key="37" value="">Please Select</option>
                    <option key="38" value="MDS - CONSERVATIVE DENTISTRY & ENDODONTICS">MDS - CONSERVATIVE DENTISTRY & ENDODONTICS</option>
                    <option key="39" value="MDS - ORAL AND MAXILLOFACIAL SURGERY">MDS - ORAL AND MAXILLOFACIAL SURGERY</option>
                    <option key="40" value="MDS - ORAL MEDICINE AND RADIOLOGY">MDS - ORAL MEDICINE AND RADIOLOGY</option>
                    <option key="41" value="MDS - ORAL PATHOLOGY AND MICROBIOLOGY">MDS - ORAL PATHOLOGY AND MICROBIOLOGY</option>
                    <option key="42" value="MDS - PAEDODONTICS AND PREVENTIVE DENTISTRY">MDS - PAEDODONTICS AND PREVENTIVE DENTISTRY</option>
                    <option key="43" value="MDS - ORTHODONTICS AND DENTOFACIAL ORTHOPAEDCS">MDS - ORTHODONTICS AND DENTOFACIAL ORTHOPAEDCS</option>
                    <option key="44" value="MDS - PERIODONTOLOGY">MDS - PERIODONTOLOGY</option>
                    <option key="45" value="MDS - PROSTHODONTICS AND CROWN AND BRIDGE">MDS - PROSTHODONTICS AND CROWN AND BRIDGE</option>
                    <option key="46" value="MDS - PUBLIC HEALTH DENTISTRY">MDS - PUBLIC HEALTH DENTISTRY</option>
                </React.Fragment>
            )
        };
    };

    React.useEffect(() => {
        setValue('specialisation', '');
    }, [selectedInstitute]);


    const tabChanged = async ({ prevIndex, nextIndex }) => {
        setCurrentTabIndex(prevIndex);
    };

    React.useEffect(() => {
        const DocUpdate = localStorage.getItem("updateDoc");
        if (DocUpdate) {
            formWizardRef.current?.goToTab(2);
        }
    }, []);

    const handelNext = async () => {

        if (currentTabIndex === 0 && !dataSaved) {

            if (institute && name && programme && mobileNo) {
                var obj = {
                    "institute": institute,
                    "name": name,
                    "programme": programme,
                    "mobileNo": mobileNo
                };
                var result = await studentRegistrationMD_MS_MDS_CheckDuplicateReg(obj);
                if (result.length > 0) {
                    swal({
                        title: "Data already exist.",
                        icon: 'info'
                    });
                } else {
                    formWizardRef.current?.nextTab();
                    await trigger(['institute', 'specialisation', 'name', 'dateofbirth', 'placeofbirth', 'district',
                        'antiRaggingNo', 'annualincome', 'communicationaddress', 'community', 'fatherName', 'fatheroccupation',
                        'firstgraduate', 'gender', 'mailId', 'mobileNo', 'motheroccupation', 'nationality', 'panno',
                        'permanentaddress', 'state', 'aadharno', 'residentialStatus', "fatherMobileNo", "abcid", "motherMobileNo", "busStops"
                    ]);
                }
            } else {
                swal({
                    title: "Please fill all required fields and check both two pages",
                    icon: 'info'
                });
                await trigger(['institute', 'specialisation', 'name', 'dateofbirth', 'placeofbirth', 'district',
                    'antiRaggingNo', 'annualincome', 'communicationaddress', 'community', 'fatherName', 'fatheroccupation',
                    'firstgraduate', 'gender', 'mailId', 'mobileNo', 'motheroccupation', 'nationality', 'panno',
                    'permanentaddress', 'state', 'aadharno', 'residentialStatus', "fatherMobileNo", "abcid", "motherMobileNo", "busStops"
                ]);
            }

        }

        if (currentTabIndex === 1 && !dataSaved) {

            if (isValid) {
                submit();
                formWizardRef.current?.nextTab();
            } else {
                swal({
                    title: "Please fill all required fields.",
                    icon: 'info'
                });
                await trigger(['academicQualification', 'admittedyear',
                    'dateofcrricommencement', 'dateofcrricompletion', 'declaration',
                    'internshipcompleteddate', 'medicalcouncilname', 'medicalcouncilregno', 'neetair', 'neetattempts', 'neetmarks', 'neetrollno', 'pgMarksObtained',
                    'pgMaximumMarks', 'pgMonthandYearofPassing', 'pgNoOfAttempts', 'pgPercentageofMarks', 'seatallotment', 'totalMarksObtained', 'totalMaximumMarks',
                    'totalPercentageofMarks', 'uginstitutionname',
                ])
            }
        }

        if ((currentTabIndex === 0 && dataSaved) || (currentTabIndex === 1 && dataSaved)) {
            formWizardRef.current?.nextTab();
        }

        if (currentTabIndex > 1) {

            formWizardRef.current?.nextTab();
        }

    };

    const handelPrev = () => {
        const DocUpdate = localStorage.getItem("updateDoc");
        if (!DocUpdate) {
            formWizardRef.current?.prevTab();
        }

        if (DocUpdate && currentTabIndex > 2) {
            formWizardRef.current?.prevTab();
        }
    };

    function GraduationCapIcon() {
        return (
            <MortarboardFill />
        );
    };

    function UploadIcon() {
        return (
            <Upload />
        );
    };

    function DownloadIcon() {
        return (
            <Download />
        );
    };

    const submit = async () => {
        setspinner(true);
        wholeData.userName = localStorage.getItem("username");
        wholeData.age = age;
        wholeData.programme = programme;
        wholeData.academicQualification = academicQualification;
        wholeData.TotalObtained = TotalObtained;
        wholeData.TotalMax = TotalMax;
        wholeData.TotalPercent = TotalPercent;
        const result = await studentRegistrationMD_MS_MDS(wholeData);
        localStorage.setItem("id", result.id);
        setDataSaved(true);
        setspinner(false);
    };

    const docnameChange = (event) => {
        Setdocname(event.target.value);
    };

    const docUpload = async () => {
        if (docname && doc) {
            setspinner(true);
            const formData = new FormData();
            formData.append('documentName', docname);
            formData.append('file', doc);
            formData.append('id', localStorage.getItem("id"));
            await studentRegistrationMD_MS_MDS_DocUpload(formData);
            setspinner(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            if (fileNameRef.current) {
                fileNameRef.current.value = 'Select Document Name';
            }
            Setdocname('');
            setdoc(null);
            getUploadedDocuments();

        } else {
            swal({
                title: "Please Choose File and File Name",
                icon: 'info'
            });
        }
    };

    const handleFileChange = async (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setdoc(file);
        }
        else {
            swal({
                title: "Please Choose File",
                icon: 'info'
            });
        }
    };

    function getAgeAsOn31stDec() {
        const dec31st = new Date(2024, 11, 31, 23, 59, 59, 999);
        const dobDate = new Date(dateOfBirth);
        const diffInMs = dec31st.getTime() - dobDate.getTime();
        const diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365);

        return Math.floor(diffInYears);
    };
    React.useEffect(() => {
        const ageAsOn31stDec = getAgeAsOn31stDec();
        setage(ageAsOn31stDec);
    }, [dateOfBirth]);

    React.useEffect(() => {
        getUploadedDocuments();
    }, []);

    const getUploadedDocuments = async () => {
        const ID = localStorage.getItem("id");
        if (ID) {
            setspinner(true);
            const result = await studentRegistrationMD_MS_MDS_UploadedDocList({ "id": ID });
            setDocList(result.uploadDocuments);
            setspinner(false);
        }
    };

    const viewDocument = async (obj) => {
        if (obj.filePath) {
            window.open(process.env.REACT_APP_SERVICE_URL + "/documents/" + obj.filePath, "_blank");
        } else {
            swal({
                title: "File Not Found.",
                icon: 'info'
            });
        }
    };

    const deleteDocument = async (obj) => {
        setspinner(true);
        var object = {
            "_id": localStorage.getItem("id"),
            "docId": obj._id
        }
        await studentRegistrationMD_MS_MDS_DeleteDoc(object);
        setspinner(false);
        getUploadedDocuments();
    };

    const downloadAllDeclarationsInPDF = async () => {
        try {
            setspinner(true);
            const response = await studentRegistrationMD_MS_MDS_DownloadDeclaration({ "_id": localStorage.getItem('id') });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
            localStorage.removeItem("id");
            localStorage.removeItem("updateDoc");
            navigate("/dashboard");
        } catch (err) {
            console.log(err);
        }
    };

    const goToDashboard = () => {
        localStorage.removeItem("id");
        localStorage.removeItem("updateDoc");
        navigate("/dashboard");
    };

    const selectProgramme = (event) => {
        if (event.target.value === "Mahatma Gandhi Medical College & Research Institute - MGMCRI" || event.target.value === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {
            setprogramme("MD / MS");
            setSelectedInstitute(event.target.value);
        };

        if (event.target.value === "Indira Gandhi Institute Of Dental Sciences - IGIDS") {
            setprogramme("MDS");
            setSelectedInstitute(event.target.value);
        };
    };

    React.useEffect(() => {
        let Tobtain = 0;
        let Tmax = 0;

        academicQualification.forEach((obj) => {
            if (obj.obtainedMarks !== 0 && obj.maximumMarks !== 0) {
                Tobtain += parseInt(obj.obtainedMarks);
                Tmax += parseInt(obj.maximumMarks);
            }
        });

        setTotalMax(Tmax);
        setTotalObtained(Tobtain);
        setTotalPercent(parseInt(Tmax !== 0 ? (Tobtain / Tmax) * 100 : 0).toFixed(2));
    }, [academicQualification]);


    const handleInputChange = (event, index) => {
        const { target: { name, value } } = event;

        const copyData = [...academicQualification];
        const data = copyData[index];

        data[name] = value;

        const { maximumMarks, obtainedMarks } = data;
        if (maximumMarks && obtainedMarks) {
            data.percentage = ((obtainedMarks / maximumMarks) * 100).toFixed(2);
        }

        setacademicQualification(copyData);
    };

    const appendExaminationItems = () => {
        const newHSC = [...academicQualification, { programmes: '', percentage: 0, obtainedMarks: 0, month_and_yearofpassing: '', maximumMarks: 0, No_of_attempts: 0 }];
        setacademicQualification(newHSC);
    };

    const removeExaminationItems = (index) => {
        const copyHSC = [...academicQualification];
        copyHSC.splice(index, 1);
        setacademicQualification(copyHSC);
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Card className='mb-4' style={{ with: "80%" }}>
                    <Form>
                        <FormWizard
                            stepSize="sm"
                            onTabChange={tabChanged}
                            ref={formWizardRef}
                            backButtonTemplate={() => (
                                <button
                                    className="back-button"
                                    type='button'
                                    onClick={handelPrev}
                                >
                                    back
                                </button>
                            )}
                            nextButtonTemplate={() => (
                                <button
                                    className="next-button"
                                    type='button'
                                    onClick={handelNext}
                                >
                                    next
                                </button>
                            )}

                            finishButtonTemplate={() => (
                                <button
                                    className="finish-button"
                                    type='button'
                                    onClick={goToDashboard}
                                >
                                    finish
                                </button>
                            )}
                        >
                            <FormWizard.TabContent title="Personal details" icon="ti-user">
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Select Institute</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Select
                                            {...register('institute', {
                                                required: "Please Select Institute"
                                            })}
                                            onChange={selectProgramme}
                                        >
                                            <option value="">Please Select</option>
                                            {/* <option value="Mahatma Gandhi Medical College & Research Institute - MGMCRI">Mahatma Gandhi Medical College & Research Institute - MGMCRI</option>
                                            <option value="Shri Sathya Sai Medical College & Research Institute - SSSMCRI">Shri Sathya Sai Medical College & Research Institute - SSSMCRI</option> */}
                                            <option value="Indira Gandhi Institute Of Dental Sciences - IGIDS">Indira Gandhi Institute Of Dental Sciences - IGIDS</option>
                                        </Form.Select>
                                        {
                                            errors?.institute &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.institute.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Programme</strong></Col>
                                    <Col xs={12} sm={6}>{programme}</Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Specialisation</strong><br /></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Select
                                            {...register('specialisation', { required: "specialisation field is empty." })}
                                        >
                                            {getSpecialisation()}
                                        </Form.Select>
                                        {
                                            errors?.specialisation &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.specialisation.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Name of the Student</strong><br />
                                        <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(as in School Certificate)</span>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('name', { required: "Name field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.name &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.name.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Date of Birth</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='Date'
                                            {...register("dateofbirth", { required: "Please Select Your Date of Birth", pattern: /^\d{4}-\d{2}-\d{2}$/ })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.dateofbirth &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.dateofbirth.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Age</strong><br />
                                        <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(as on 31 December)</span>
                                    </Col>
                                    <Col xs={12} sm={6}>{age}</Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Place of Birth</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('placeofbirth', { required: "Place of birth field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.placeofbirth &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.placeofbirth.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>District</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('district', { required: "District field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.district &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.district.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Nationality</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('nationality', { required: "Nationality Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.nationality &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.nationality.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>State</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('state', { required: "state Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.state &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.state.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Community</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Check
                                                inline
                                                label="SC"
                                                type="radio"
                                                value="SC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="ST"
                                                type="radio"
                                                value="ST"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="MBC"
                                                type="radio"
                                                value="MBC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="BC"
                                                type="radio"
                                                value="BC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="OBC"
                                                type="radio"
                                                value="OBC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="OC"
                                                type="radio"
                                                value="OC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                        {
                                            errors?.community &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.community.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Name of Father / Guardian</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='fatherName'
                                            {...register('fatherName', { required: "Father / Guardian Name Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.fatherName &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.fatherName.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Father's Mobile Number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type="number"
                                            {
                                            ...register('fatherMobileNo',
                                                {
                                                    required: "Mobile Number Field is Empty",
                                                    pattern: {
                                                        value: /^[0-9]{10}$/,
                                                        message: "Please Enter a Valid 10 Digit Phone Number"
                                                    }
                                                }
                                            )
                                            }
                                        >
                                        </Form.Control>
                                        {
                                            errors?.fatherMobileNo &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.fatherMobileNo.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Permanent Address</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            as="textarea"
                                            {...register('permanentaddress', { required: "Permanent Address Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.permanentaddress &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.permanentaddress.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Communication Address</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            as="textarea"
                                            {...register('communicationaddress', { required: "Communication Address Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.communicationaddress &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.communicationaddress.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Mobile Number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type="number"
                                            {
                                            ...register('mobileNo',
                                                {
                                                    required: "Mobile Number Field is Empty",
                                                    pattern: {
                                                        value: /^[1-9][0-9]{9}$/,
                                                        message: "Please Enter a Valid 10 Digit Phone Number"
                                                    }
                                                }
                                            )
                                            }
                                        >
                                        </Form.Control>
                                        {
                                            errors?.mobileNo &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.mobileNo.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Mail Id</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='email'
                                            {
                                            ...register('mailId',
                                                {
                                                    required: "Mail Id Field is Empty",
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.+com$/,
                                                        message: "Please Enter a Valid Email Address"
                                                    }
                                                }
                                            )
                                            }
                                        >
                                        </Form.Control>
                                        {
                                            errors?.mailId &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.mailId.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Gender</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group
                                        >
                                            <Form.Check
                                                inline
                                                label="Male"
                                                type="radio"
                                                value="Male"
                                                {...register('gender', { required: "Please Select Gender" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="Female"
                                                value="Female"
                                                type="radio"
                                                {...register('gender', { required: "Please Select Gender" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="Third Gender"
                                                type="radio"
                                                value="Third Gender"
                                                {...register('gender', { required: "Please Select Gender" })}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                        {
                                            errors?.gender &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.gender.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>


                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Are you the first graduate in your family</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group
                                        >
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                type="radio"
                                                value="Yes"
                                                {...register('firstgraduate', { required: "Please Select Yes or No" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="No"
                                                value="No"
                                                type="radio"
                                                {...register('firstgraduate', { required: "Please Select Yes or No" })}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                        {
                                            errors?.firstgraduate &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.firstgraduate.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Father Occupation</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='fatherOccupation'
                                            {...register('fatheroccupation', { required: "Father Occupation Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.fatheroccupation &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.fatheroccupation.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Mother Occupation</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='motherOccupation'
                                            {...register('motheroccupation', { required: "Mother Occupation Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.motheroccupation &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.motheroccupation.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Mother's Mobile Number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type="number"
                                            {
                                            ...register('motherMobileNo',
                                                {
                                                    required: "Mobile Number Field is Empty",
                                                    pattern: {
                                                        value: /^[0-9]{10}$/,
                                                        message: "Please Enter a Valid 10 Digit Phone Number"
                                                    }
                                                }
                                            )
                                            }
                                        >
                                        </Form.Control>
                                        {
                                            errors?.motherMobileNo &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.motherMobileNo.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Annual Family Income</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='number'
                                            name='annualincome'
                                            {...register('annualincome',
                                                {
                                                    required: "Annual Income Field is Empty",
                                                    pattern: {
                                                        value: /^[1-9][0-9]*$/,
                                                        message: "Please Enter a Valid Number"
                                                    }
                                                }
                                            )}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.annualincome &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.annualincome.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Aadhar number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='aadharno'
                                            {...register('aadharno', {
                                                required: "Aadhar number Field is Empty",
                                                pattern: {
                                                    value: /^\d{4}\s\d{4}\s\d{4}$/,
                                                    message: "Please enter a valid Aadhaar number in this format: 1111 2222 3333"
                                                }
                                            })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.aadharno &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.aadharno.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Pan number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='panno'
                                            {...register('panno', { required: "Pan number Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.panno &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.panno.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>ABC id</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='abcid'
                                            {...register('abcid', {
                                                required: "ABC id field is empty",
                                                pattern: {
                                                    value: /^\d{4}\s\d{4}\s\d{4}$/,
                                                    message: "Please Enter a ABC id number in the format 1111 2222 3333"
                                                }
                                            })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.abcid &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.abcid.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Anti Ragging Reference Number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='number'
                                            name='antiRaggingNo'
                                            {...register('antiRaggingNo',
                                                {
                                                    required: "Anti Ragging Reference Number Field is Empty",
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Negative value not allowed"
                                                    }
                                                }
                                            )}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.antiRaggingNo &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.antiRaggingNo.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Residential Status</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group
                                        >
                                            <Form.Check
                                                inline
                                                label="Hostel"
                                                type="radio"
                                                value="Hostel"
                                                {...register('residentialStatus', { required: "Please select your residential status" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="Day Scholar"
                                                value="Day Scholar"
                                                type="radio"
                                                {...register('residentialStatus', { required: "Please select your residential status" })}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                        {
                                            errors?.residentialStatus &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.residentialStatus.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                {residentialStatus === "Day Scholar" &&
                                    <Row style={{ textAlign: "start" }} className='mt-2'>
                                        <Col xs={12} sm={6}><strong>If you need transport, select a bus stop</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select
                                                {...register('busStops')}
                                            >
                                                <option name="busStops" value="" >Select Bus Stop</option>
                                                <option name="busStops" value="OT Manikoondu (Cuddalore)">OT Manikoondu (Cuddalore)</option>
                                                <option name="busStops" value="Committee (Cuddalore)">Committee (Cuddalore)</option>
                                                <option name="busStops" value="KV Tex (Cuddalore)">KV Tex (Cuddalore)</option>
                                                <option name="busStops" value="GRT (Cuddalore)">GRT (Cuddalore)</option>
                                                <option name="busStops" value="New Cinemas (Cuddalore)">New Cinemas (Cuddalore)</option>
                                                <option name="busStops" value="Post Office (Cuddalore)">Post Office (Cuddalore)</option>
                                                <option name="busStops" value="G.H. (Cuddalore)">G.H. (Cuddalore)</option>
                                                <option name="busStops" value="Blind School (Cuddalore)">Blind School (Cuddalore)</option>
                                                <option name="busStops" value="KNC (Cuddalore)">KNC (Cuddalore)</option>
                                                <option name="busStops" value="Semandalam (Cuddalore)">Semandalam (Cuddalore)</option>
                                                <option name="busStops" value="Dhanalakshmi Nagar (Cuddalore)">Dhanalakshmi Nagar (Cuddalore)</option>
                                                <option name="busStops" value="Gundu Salai (Cuddalore)">Gundu Salai (Cuddalore)</option>
                                                <option name="busStops" value="Aalpettai (Cuddalore)">Aalpettai (Cuddalore)</option>
                                                <option name="busStops" value="Gankanakuppam (Cuddalore)">Gankanakuppam (Cuddalore)</option>
                                                <option name="busStops" value="Aarupadai Hostel (Cuddalore)">Aarupadai Hostel (Cuddalore)</option>
                                                <option name="busStops" value="Gorimedu">Gorimedu</option>
                                                <option name="busStops" value="Subbaiya">Subbaiya</option>
                                                <option name="busStops" value="Thattanchavdy">Thattanchavdy</option>
                                                <option name="busStops" value="Estate">Estate</option>
                                                <option name="busStops" value="Rajiv Gandhi">Rajiv Gandhi</option>
                                                <option name="busStops" value="Indira Gandhi">Indira Gandhi</option>
                                                <option name="busStops" value="Nellithope">Nellithope</option>
                                                <option name="busStops" value="New Bus Stand">New Bus Stand</option>
                                                <option name="busStops" value="Antony church">Antony church</option>
                                                <option name="busStops" value="A.F.T">A.F.T</option>
                                                <option name="busStops" value="Murungapakkam">Murungapakkam</option>
                                                <option name="busStops" value="Pillaiyar Kovil">Pillaiyar Kovil</option>
                                                <option name="busStops" value="Ponniyaman Kovil">Ponniyaman Kovil</option>
                                                <option name="busStops" value="Vaanavil">Vaanavil</option>
                                                <option name="busStops" value="Naavalar">Naavalar</option>
                                                <option name="busStops" value="Vallalar School">Vallalar School</option>
                                                <option name="busStops" value="Oulgaret Sandhai">Oulgaret Sandhai</option>
                                                <option name="busStops" value="Iyyanar Kovil">Iyyanar Kovil</option>
                                                <option name="busStops" value="Latha Steels">Latha Steels</option>
                                                <option name="busStops" value="Sarathambal Kovil">Sarathambal Kovil</option>
                                                <option name="busStops" value="Kaatupalayam">Kaatupalayam</option>
                                                <option name="busStops" value="Anugraga">Anugraga</option>
                                                <option name="busStops" value="Reddiyarpalayam">Reddiyarpalayam</option>
                                                <option name="busStops" value="Cauvery Nagar">Cauvery Nagar</option>
                                                <option name="busStops" value="Ajith Nagar">Ajith Nagar</option>
                                                <option name="busStops" value="Pazhva Nagar">Pazhva Nagar</option>
                                                <option name="busStops" value="Kamachi">Kamachi</option>
                                                <option name="busStops" value="R.T.O">R.T.O</option>
                                                <option name="busStops" value="Nainarmandapam">Nainarmandapam</option>
                                                <option name="busStops" value="Edayarpalayam">Edayarpalayam</option>
                                                <option name="busStops" value="Thavalakuppam">Thavalakuppam</option>
                                                <option name="busStops" value="Reddychavadi">Reddychavadi</option>
                                                <option name="busStops" value="Ginger Hotel">Ginger Hotel</option>
                                                <option name="busStops" value="Bharathidasan College">Bharathidasan College</option>
                                                <option name="busStops" value="Gingee Salai">Gingee Salai</option>
                                                <option name="busStops" value="Law College">Law College</option>
                                                <option name="busStops" value="Uppalam Stadium">Uppalam Stadium</option>
                                                <option name="busStops" value="Uppalam Kallarai">Uppalam Kallarai</option>
                                                <option name="busStops" value="Kandhasamy House">Kandhasamy House</option>
                                                <option name="busStops" value="Mudaliyarpettai">Mudaliyarpettai</option>
                                                <option name="busStops" value="Ariyankuppam Police Station">Ariyankuppam Police Station</option>
                                                <option name="busStops" value="Boat House">Boat House</option>
                                                <option name="busStops" value="Poornakuppam">Poornakuppam</option>
                                                <option name="busStops" value="Sivaji Statue ">Sivaji Statue </option>
                                                <option name="busStops" value="Marapaalam ">Marapaalam </option>
                                                <option name="busStops" value="Muthiyalpet Police Station">Muthiyalpet Police Station</option>
                                                <option name="busStops" value="Anandha Ranga">Anandha Ranga</option>
                                                <option name="busStops" value="Saram">Saram</option>
                                                <option name="busStops" value="Balaji Theatre">Balaji Theatre</option>
                                                <option name="busStops" value="JeevaRukmani Theatre">JeevaRukmani Theatre</option>
                                                <option name="busStops" value="Raja Theatre">Raja Theatre</option>
                                                <option name="busStops" value="Old Bus Stand">Old Bus Stand</option>
                                                <option name="busStops" value="Ariyankuppam">Ariyankuppam</option>
                                                <option name="busStops" value="Rani Hospital">Rani Hospital</option>
                                                <option name="busStops" value="Kathirkamam">Kathirkamam</option>
                                                <option name="busStops" value="Shanmugapuram">Shanmugapuram</option>
                                                <option name="busStops" value="Mettupalayam">Mettupalayam</option>
                                                <option name="busStops" value="Moolakulam">Moolakulam</option>
                                                <option name="busStops" value="Oulgaret">Oulgaret</option>
                                                <option name="busStops" value="Jaya Nagar">Jaya Nagar</option>
                                                <option name="busStops" value="Kirumampakam">Kirumampakam</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                }

                            </FormWizard.TabContent>

                            <FormWizard.TabContent
                                title="Academic details"
                                icon={GraduationCapIcon()}
                            >
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <strong>Qualifying Examination Passed</strong></Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col><strong>Degree</strong></Col>
                                    <Col><strong>Admitted Year</strong></Col>
                                    <Col><strong>Date of CRRI Commencement</strong></Col>
                                    <Col><strong>Date of CRRI Completion</strong></Col>
                                    <Col><strong>Name of Institution</strong></Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col><strong>MBBS / BDS</strong></Col>
                                    <Col>
                                        <Form.Control
                                            type='number'
                                            {...register('admittedyear',
                                                {
                                                    required: "Admitted Year field is empty.",
                                                    pattern: {
                                                        value: /^[1-9]\d{3}$/,
                                                        message: "Please Enter a Valid Year"
                                                    }
                                                }
                                            )}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.admittedyear &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.admittedyear.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='date'
                                            {...register('dateofcrricommencement', { required: "Date of CRRI commencement field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.dateofcrricommencement &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.dateofcrricommencement.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='date'
                                            {...register('dateofcrricompletion', { required: "Date of CRRI completion field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.dateofcrricompletion &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.dateofcrricompletion.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='text'
                                            {...register('uginstitutionname', { required: "Institution name field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.uginstitutionname &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.uginstitutionname.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col><strong>PG Diploma in</strong></Col>
                                    <Col><strong>Year of Admission</strong></Col>
                                    <Col><strong>Year of Completion</strong></Col>
                                    <Col><strong>Name of Institution</strong></Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col>
                                        <Form.Control
                                            type='text'
                                            {...register('diplomacoursename')}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='number'
                                            {...register('diplomaadmissionyear',)}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='number'
                                            {...register('diplomacompletionyear',)}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type='text'
                                            {...register('diplomainstitutename')}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row style={{ textAlign: "start" }} className='mt-4'>
                                    <Col xs={12}><strong>MBBS / BDS / PG Diploma marks</strong></Col>
                                </Row>
                                <Row style={{ textAlign: "center" }} className='mt-2'>
                                    <Col xs={3} sm={2}>Programmes</Col>
                                    <Col xs={3} sm={2}>Month & Year of Passing</Col>
                                    <Col xs={3} sm={2}>Marks Obtained</Col>
                                    <Col xs={3} sm={2}>Maximum Marks</Col>
                                    <Col xs={3} sm={1}>% of Mark</Col>
                                    <Col xs={3} sm={2}>No of Attempts</Col>
                                    <Col xs={3} sm={1}>Action</Col>
                                </Row>
                                {
                                    academicQualification &&
                                    academicQualification.map((item, index) => {
                                        return (
                                            <Row className="mt-2" key={item.id}>

                                                <Col xs={3} sm={2}>
                                                    <Form.Control
                                                        type="text"
                                                        name="programmes"
                                                        value={item.programmes}
                                                        onChange={(event) => handleInputChange(event, index)}
                                                    >
                                                    </Form.Control>
                                                </Col>

                                                <Col xs={3} sm={2}>
                                                    <Form.Control
                                                        type="text"
                                                        name="month_and_yearofpassing"
                                                        value={item.month_and_yearofpassing}
                                                        onChange={(event) => handleInputChange(event, index)}
                                                    >
                                                    </Form.Control>
                                                </Col>

                                                <Col xs={3} sm={2}>
                                                    <Form.Control
                                                        type="number"
                                                        name="obtainedMarks"
                                                        value={item.obtainedMarks}
                                                        onChange={(event) => handleInputChange(event, index)}
                                                    >
                                                    </Form.Control>
                                                </Col>

                                                <Col xs={3} sm={2}>
                                                    <Form.Control
                                                        type="number"
                                                        name="maximumMarks"
                                                        value={item.maximumMarks}
                                                        onChange={(event) => handleInputChange(event, index)}
                                                    >
                                                    </Form.Control>
                                                </Col>

                                                <Col xs={3} sm={1}>
                                                    {item.percentage} %
                                                </Col>

                                                <Col xs={3} sm={2}>
                                                    <Form.Control
                                                        type="number"
                                                        name="No_of_attempts"
                                                        value={item.No_of_attempts}
                                                        onChange={(event) => handleInputChange(event, index)}
                                                    >
                                                    </Form.Control>
                                                </Col>

                                                <Col xs={3} sm={1} style={{ textAlign: "center" }}>
                                                    {academicQualification.length - 1 !== index &&
                                                        <Icon.Trash
                                                            color="red"
                                                            size={25}
                                                            type="button"
                                                            onClick={() => removeExaminationItems(index)}
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Delete Row"
                                                            style={{ marginRight: "10px" }}
                                                        />
                                                    }
                                                    {academicQualification.length - 1 === index &&
                                                        <Icon.PlusCircle
                                                            color="blue"
                                                            size={25}
                                                            type="button"
                                                            onClick={() => appendExaminationItems()}
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Add Row"
                                                        />
                                                    }
                                                </Col>

                                            </Row>
                                        )
                                    })
                                }

                                <Row style={{ textAlign: "center" }} className='mt-2'>
                                    <Col xs={3} sm={2}><strong>Aggregate</strong></Col>
                                    <Col xs={3} sm={2}></Col>
                                    <Col xs={3} sm={2}><strong>{TotalObtained}</strong></Col>
                                    <Col xs={3} sm={2}><strong>{TotalMax}</strong></Col>
                                    <Col xs={3} sm={1}><strong>{TotalPercent} %</strong></Col>
                                    <Col xs={3} sm={2}></Col>
                                    <Col xs={3} sm={1}></Col>
                                </Row>

                                <Divider />

                                <Row style={{ textAlign: "center" }} className='mt-2'>
                                    <Col xs={3} sm={2}>Programmes</Col>
                                    <Col xs={3} sm={2}>Month & Year of Passing</Col>
                                    <Col xs={3} sm={2}>Marks Obtained</Col>
                                    <Col xs={3} sm={2}>Maximum Marks</Col>
                                    <Col xs={3} sm={2}>% of Mark</Col>
                                    <Col xs={3} sm={2}>No of Attempts</Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={3} sm={2}><strong>PG Diploma (if relevent)</strong></Col>
                                    <Col xs={3} sm={2}>
                                        <Form.Control
                                            type='text'
                                            {...register('pgMonthandYearofPassing')}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={3} sm={2}>
                                        <Form.Control
                                            type='number'
                                            {...register('pgMarksObtained')}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={3} sm={2}>
                                        <Form.Control
                                            type='number'
                                            {...register('pgMaximumMarks')}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={3} sm={2}>
                                        <Form.Control
                                            type='number'
                                            {...register('pgPercentageofMarks')}
                                        >
                                        </Form.Control>
                                    </Col>
                                    <Col xs={3} sm={2}>
                                        <Form.Control
                                            type='number'
                                            {...register('pgNoOfAttempts')}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>

                                <Divider />

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={6} sm={6}><strong>Internship Completed Date</strong></Col>
                                    <Col xs={6} sm={6}>
                                        <Form.Control
                                            type='date'
                                            {...register('internshipcompleteddate', { required: "Internship completed date field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.internshipcompleteddate &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.internshipcompleteddate.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={6} sm={6}><strong>Registered medical council name</strong></Col>
                                    <Col xs={6} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('medicalcouncilname', { required: "Medical Council Name field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.medicalcouncilname &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.medicalcouncilname.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={6} sm={6}><strong>Medical council registrationNo</strong></Col>
                                    <Col xs={6} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('medicalcouncilregno', { required: "Medical Council registration no. field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.medicalcouncilregno &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.medicalcouncilregno.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <strong>NEET DETAILS</strong>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={4} sm={3}>Roll No</Col>
                                    <Col xs={4} sm={2}>Marks</Col>
                                    <Col xs={4} sm={1}>AIR</Col>
                                    <Col xs={6} sm={2}>No. of Attempts in NEET Already Made</Col>
                                    <Col xs={6} sm={4}>Allotment of Seat Made by DGHS in </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={4} sm={3}>
                                        <Form.Control
                                            type='number'
                                            {...register('neetrollno',
                                                {
                                                    required: "NEET roll no. field is empty.",
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Negative value not allowed"
                                                    }
                                                }
                                            )}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.neetrollno &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.neetrollno.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col xs={4} sm={2}>
                                        <Form.Control
                                            type='number'
                                            {...register('neetmarks',
                                                {
                                                    required: "NEET marks field is empty.",
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Negative value not allowed"
                                                    }
                                                }
                                            )}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.neetmarks &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.neetmarks.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col xs={4} sm={1}>
                                        <Form.Control
                                            type='number'
                                            {...register('neetair',
                                                {
                                                    required: "NEET AIR field is empty.",
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Negative value not allowed"
                                                    }
                                                }
                                            )}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.neetair &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.neetair.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col xs={6} sm={2}>
                                        <Form.Control
                                            type='number'
                                            {...register('neetattempts',
                                                {
                                                    required: "No of attempts in NEET field is empty.",
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Negative value not allowed"
                                                    }
                                                })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.neetattempts &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.neetattempts.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col xs={6} sm={4}>
                                        <Form.Group>
                                            <Form.Check
                                                inline
                                                label="1"
                                                type="radio"
                                                value="1"
                                                {...register('seatallotment', { required: "Select seat allotment" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="2"
                                                type="radio"
                                                value="2"
                                                {...register('seatallotment', { required: "Select seat allotment" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="Mop-up Round"
                                                type="radio"
                                                value="Mop-up Round"
                                                {...register('seatallotment', { required: "Select seat allotment" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="Stray Vacancy Round"
                                                type="radio"
                                                value="Stray Vacancy Round"
                                                {...register('seatallotment', { required: "Select seat allotment" })}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                        {
                                            errors?.seatallotment &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.seatallotment.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-4'>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label={
                                                <div>
                                                    <span>
                                                        <strong>
                                                            I {studentName} hereby solemnly and sincerely affirm that the statements made and information furnished in my application
                                                            form and also in all the enclosures here to submitted by me are TRUE. Should in however by found that any information furnished herein is untrue,
                                                            I realise that I am liable for criminal prosecution and agree to for go my seat, at any stage.
                                                        </strong>
                                                    </span>
                                                </div>
                                            }
                                            type="checkbox"
                                            value="checked"
                                            {...register('declaration', { required: "You must check to the declaration" })}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                </Row>
                                {
                                    errors?.declaration &&
                                    <Form.Text className="text-error" style={{ color: "red" }}>
                                        {errors.declaration.message}
                                    </Form.Text>
                                }

                            </FormWizard.TabContent>

                            <FormWizard.TabContent
                                title="Documents upload"
                                icon={UploadIcon()}
                            >

                                <Row className="mt-1">
                                    <Col><strong>Documents Upload</strong></Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col xs={12} sm={5}>
                                        <Form.Select
                                            onChange={docnameChange}
                                            ref={fileNameRef}
                                            name='documentName'
                                        >
                                            <option>Select Document Name</option>
                                            <option value="photo">Photo</option>
                                            <option value="UG Marksheet">UG Marksheet</option>
                                            <option value="PG Marksheet">PG Marksheet</option>
                                            <option value="UG Degree">UG Degree</option>
                                            <option value="PG Degree">PG Degree</option>
                                            <option value="UG Provisional">UG Provisional</option>
                                            <option value="PG Provisional">PG Provisional</option>
                                            <option value="Transfer Certificate">Transfer Certificate</option>
                                            <option value="Aadhar Card">Aadhar Card</option>
                                            <option value="Community Certificate">Community Certificate</option>
                                            <option value="Income Certificate">Income Certificate</option>
                                            <option value="Experience Certificate">Experience Certificate</option>
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} sm={5} style={{ textAlign: "center" }}>
                                        <input
                                            htmlFor="formFile"
                                            className="form-control"
                                            id="customFile"
                                            type="file"
                                            accept=".pdf, .jpeg, .jpg,.png"
                                            style={{ display: 'block' }}
                                            onChange={(e) => handleFileChange(e)}
                                            ref={fileInputRef}
                                        />
                                    </Col>
                                    <Col xs={12} sm={2}>
                                        <Button
                                            size='sm'
                                            onClick={docUpload}
                                            style={{ backgroundColor: "green" }}
                                        >
                                            <Icon.Upload /> Upload
                                        </Button>

                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={12} sm={2}></Col>
                                    <Col xs={12} sm={8}>
                                        <Table striped bordered hover className="mt-3 text-center">
                                            <thead>
                                                <tr>
                                                    <th>Sl.no</th>
                                                    <th>Document Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {docList && docList.length > 0 &&
                                                    docList.map((obj, index) => (
                                                        <tr key={obj._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{obj.documentName}</td>
                                                            <td>
                                                                <Icon.Eye
                                                                    style={{ marginRight: "20px" }}
                                                                    color="#0A69FB"
                                                                    size={25}
                                                                    type="button"
                                                                    onClick={() => viewDocument(obj)}
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title="View Doc"
                                                                />
                                                                <Icon.Trash
                                                                    color="#B8250B"
                                                                    size={25}
                                                                    type="button"
                                                                    onClick={() => deleteDocument(obj)}
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title="Delete Doc"
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col xs={12} sm={2}></Col>
                                </Row>
                            </FormWizard.TabContent>

                            <FormWizard.TabContent
                                title="Download all declarations"
                                icon={DownloadIcon()}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <List
                                        style={{ width: "50%" }}
                                        size="small"
                                        header={<strong>Declaration Forms</strong>}
                                        footer={
                                            <Button
                                                className="primary"
                                                type='button'
                                                size='sm'
                                                onClick={() => downloadAllDeclarationsInPDF()}
                                            >
                                                download & finish
                                            </Button>
                                        }
                                        bordered
                                        dataSource={declarationFormList}
                                        renderItem={(item) => <List.Item>{item}</List.Item>}
                                    />
                                </div>
                            </FormWizard.TabContent>

                        </FormWizard>
                    </Form>
                </Card>
            </Container>
            <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
           .finish-button{
          background-color: #4073F1;
          height:35px;
          width:139px;
          border: none;
          color: white;
          text-align: center;
          float:right;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          font-weight:600;
          text-align: center;
          cursor: pointer;
          margin-right: 10px;
          margin-left: 10px;
          border-radius: 3px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
          transition: background-color 0.3s ease;
        }
        .next-button{
          background-color: #4073F1;
          height:36px;
          width:140px;
          border: none;
          color: white;
          text-align: center;
          float:right;
          float:right;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          font-weight:600;
          text-align: center;
          cursor: pointer;
          margin-right: 10px;
          margin-left: 10px;
          border-radius: 3px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
          transition: background-color 0.3s ease;
        }

        .next-button:disabled {
          background: #dddddd;
        }

        .back-button{
          background-color: #4073F1;
          height:36px;
          width:140px;
          border: none;
          color: white;
          text-align: center;
           align-items: start;
          float:left;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          font-weight:600;
          text-align: center;
          cursor: pointer;
          margin-right: 10px;
          margin-left: 10px;
          border-radius: 3px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
          transition: background-color 0.3s ease;
        }
        .wizard-card-footer{
          margin-top: 30px;
        }
      `}</style>
        </>
    )
}

export default StudentRegistration_MD_MS_MDS