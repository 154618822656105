import Card from 'react-bootstrap/Card';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import {
    DocumentsUpload,
    GetUploadedDocuments,
    DeleteDocument,
} from './services';

function DocumentUpload(props) {
    const {
        updateSwipeableViewHeight,
        documentUploadData,
        goToPreviousPage,
        goToNextPage,
        paymentStatus,
    } = props;
    const [file, Setfile] = useState();
    const [docname, Setdocname] = useState('');
    const [doclist, Setdoclist] = useState([]);
    const [uploading, setUploading] = useState(false);
    const navigate = useNavigate();
    var selectedInstitute = localStorage.getItem("institute");

    const requiredDocList = [
        "Photo",
        "+2 Marksheet",
        "Transfer Certificate",
        "Aadhar Card",
    ];

    const docnameChange = (event) => {
        Setdocname(event.target.value);
    };

    const handleFileInputChange = (event) => {
        const { target: { files } } = event;
        if (!files[0]) {
            return;
        }

        Setfile(files[0]);
    };

    async function getUploadedDocuments() {
        const documents = await GetUploadedDocuments({ '_id': localStorage.getItem('_id') });
        if (Array.isArray(documents)) {
            Setdoclist(documents);
        }

        setUploading(false);
    };

    const handleUpload = async () => {
        try {
            const formData = new FormData();
            formData.append('document_name', docname);
            formData.append('file', file);
            formData.append('_id', localStorage.getItem('_id'));

            await DocumentsUpload(formData);
            getUploadedDocuments();
        }
        catch (err) {
            console.log(err);
        }
    };

    const onUploadClick = () => {
        setUploading(true);
    };

    React.useEffect(() => {
        if (uploading) {
            handleUpload();
        }
    }, [uploading]);

    React.useEffect(() => {
        updateSwipeableViewHeight();
    }, [doclist]);

    React.useEffect(() => {
        if (documentUploadData) {
            if (Array.isArray(documentUploadData)) {
                Setdoclist(documentUploadData);
            }
        }
    }, [documentUploadData]);

    const deletefile = async (filedata) => {
        try {
            await DeleteDocument({ filedata, "_id": localStorage.getItem("_id") });
            getUploadedDocuments();
        }
        catch (err) {
            console.log(err);
        }
    };

    const viewfile = (filedata) => {
        window.open(process.env.REACT_APP_SERVICE_URL + "/documents/" + filedata.filePath, "_blank");
    };

    const nextPage = () => {
        const allRequiredDocumentsAvailable = requiredDocList.every(requiredDoc =>
            doclist.some(doc => doc.documentName === requiredDoc)
        );

        if (paymentStatus) {
            if (paymentStatus === "Success") {
                navigate("/dashboard");
            }
            else {
                goToNextPage();
            }
        } else {
            goToNextPage();
        }
        return;
        if (allRequiredDocumentsAvailable) {
            if (paymentStatus) {
                if (paymentStatus.paymentStatus === "Success") {
                    navigate("/dashboard");
                }
                else {
                    goToNextPage();
                }
            } else {
                goToNextPage();
            }
        } else {
            swal({
                title: "Please upload all required documents",
                icon: "info"
            })
        }

    };

    const getDocumentName = () => {

        if (selectedInstitute === "Institute Of Health Profession Education - IHPE") {
            return (
                <React.Fragment>
                    <option>Select Document Name</option>
                    <option value="Photo">Photo </option>
                    <option value="UG/PG - Degree/ Provisional">UG /PG - Degree/ Provisional</option>
                    <option value="Aadhar Card">Aadhar Card </option>
                    <option value="Community Certificate">Community Certificate </option>
                    <option value="Income Certificate">Income Certificate</option>
                    <option value="Experience Certificate">Experience Certificate</option>
                    <option value="NOC from the Head of the Institute">NOC from the Head of the Institute</option>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <option>Select Document Name</option>
                    <option value="Photo">Photo </option>
                    <option value="+2 Marksheet">HSC Marksheet </option>
                    <option value="UG/PG Marksheet">UG / PG Marksheet</option>
                    <option value="Diploma Marksheet">Diploma Marksheet</option>
                    <option value="Diploma Provisional">Diploma Provisional</option>
                    <option value="Diploma Course Completion Certificate">Diploma Course Completion Certificate</option>
                    <option value="UG/PG - Degree/ Provisional">UG /PG - Degree/ Provisional</option>
                    <option value="Transfer Certificate">Transfer Certificate </option>
                    <option value="Aadhar Card">Aadhar Card </option>
                    <option value="Community Certificate">Community Certificate </option>
                    <option value="Income Certificate">Income Certificate</option>
                    <option value="Experience Certificate">Experience Certificate</option>
                    <option value="RNRM Certificate for PBBSc(N), M.Sc(N), NPCC">RNRM Certificate for PBBSc(N), M.Sc(N), NPCC</option>
                    <option value="NOC from the Head of the Institute">NOC from the Head of the Institute</option>
                </React.Fragment>
            )
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: -45 }}>
            <Card style={{ width: '60rem', height: 'auto', paddingBottom: '20px', paddingTop: '45px' }}>
                <h3 style={{ textAlign: 'center', color: '#fd7e14', paddingTop: '10px' }}>
                    Application for Admission to the Academic Year 2024 - 2025</h3>
                <Container>
                    <Row className="mt-3">
                        <Col>
                            <Row>
                                <strong>Documents Upload</strong>
                            </Row>
                            <Row>
                                <label style={{ color: "red" }}>* symbol represents a required document.</label>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <Form.Select onChange={docnameChange} name='documentName'>
                                {getDocumentName()}
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <button onClick={onUploadClick}>Upload</button>
                        </Col>
                    </Row>
                    {
                        doclist.map((data, index) => (
                            <Row className="mt-3" key={data._id}>
                                <Col xs={6} sm={3}>{index + 1}</Col>
                                <Col xs={6} sm={3}>{data.documentName}</Col>
                                <Col xs={6} sm={3}>
                                    <button style={{ marginRight: '10px', marginLeft: '100px' }}
                                        onClick={() => viewfile(data)}
                                    >view</button>
                                </Col>
                                <Col xs={6} sm={3}>
                                    <button onClick={() => deletefile(data)}>delete</button>
                                </Col>
                            </Row>
                        ))
                    }

                    <Row>
                        <Col >
                            <Button variant="primary"
                                onClick={goToPreviousPage}
                                className="mt-3 w-70">
                                Back
                            </Button>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                            <Button
                                variant="primary"
                                className="mt-3 w-70"
                                onClick={nextPage}
                            >
                                Save & Next
                            </Button>
                        </Col>
                    </Row>

                </Container>
            </Card>
        </div>
    );
}

export default DocumentUpload;