import React from 'react';
import { Button, Container, Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Spinner from './Spinner';
import {
    getMBBSandBDSregistrationUploadedDocs,
    deleteMBBSandBDSregistrationUploadedDocs,
    uploadMBBSandBDSregistrationDocs
} from './services';
import swal from 'sweetalert';

function MBBSandBDSstudentRegistrationDocumentsUpload() {
    const [spinner, setspinner] = React.useState(false);
    const [file, Setfile] = React.useState();
    const [uploadedDoc, SetUploadedDoc] = React.useState([]);
    const navigate = useNavigate();
    const Ref1 = React.useRef(null);
    const Ref2 = React.useRef(null);
    const Ref3 = React.useRef(null);
    const Ref4 = React.useRef(null);
    const Ref5 = React.useRef(null);
    const Ref6 = React.useRef(null);
    const Ref7 = React.useRef(null);
    const Ref8 = React.useRef(null);
    const Ref9 = React.useRef(null);
    const Ref10 = React.useRef(null);
    const Ref11 = React.useRef(null);
    const Ref12 = React.useRef(null);
    const Ref13 = React.useRef(null);
    const Ref14 = React.useRef(null);
    const Ref15 = React.useRef(null);
    const Ref16 = React.useRef(null);
    const Ref17 = React.useRef(null);
    const Ref18 = React.useRef(null);
    const Ref19 = React.useRef(null);
    const Ref20 = React.useRef(null);
    const Ref21 = React.useRef(null);
    const Ref22 = React.useRef(null);
    const Ref23 = React.useRef(null);
    const Ref24 = React.useRef(null);

    const requiredDoc = [
        "photo",
        //"Online provisional allotment letter issued by MCC",
        //"Community Certificate",
        //"UG NEET 2023 Admit Card issued by NTA",
        //"UG NEET 2023 Result / Rank Letter issued by NTA",
        //"Date of Birth Certificate",
        //"12th Mark Sheet",
        //"Transfer / Migration / Conduct Certificate",
        //"Photo Identify Proof (Aadhar Card, Voter Id, Pan Card)",
        //"One photographs of Student with Parent / Guardian",
    ];

    const goToDashboard = () => {
        localStorage.removeItem("registrationNo");
        navigate("/dashboard");
    };

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };


    const handleFileInputChange = (event) => {
        const { target: { files } } = event;
        if (!files[0]) {
            return;
        }

        Setfile(files[0]);
    };

    async function getUploadedDocuments() {
        try {
            const doc = await getMBBSandBDSregistrationUploadedDocs({ registrationNo: localStorage.getItem('registrationNo') });
            SetUploadedDoc(doc.uploadDocuments);
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        getUploadedDocuments();
    }, []);

    const UploadPhoto = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "photo");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref1.current) {
                    Ref1.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadPHcertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "PH Certificate");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref2.current) {
                    Ref2.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadOnlineProvisionalAllotmentLetter = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Online provisional allotment letter issued by MCC");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref3.current) {
                    Ref3.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadCommunityCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Community Certificate");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref4.current) {
                    Ref4.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadNEETAdmitCard = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "UG NEET 2023 Admit Card issued by NTA");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref5.current) {
                    Ref5.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadNEETResultRankLetter = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "UG NEET 2023 Result / Rank Letter issued by NTA");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref6.current) {
                    Ref6.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadDateofBirthCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Date of Birth Certificate");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref7.current) {
                    Ref7.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const Upload12thMarkSheet = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "12th Mark Sheet");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref8.current) {
                    Ref8.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const Upload10thMarkSheet = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "10th Mark Sheet");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref9.current) {
                    Ref9.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadTMCcertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Transfer / Migration / Conduct Certificate");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref10.current) {
                    Ref10.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadIdProof = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Photo Identify Proof (Aadhar Card, Voter Id, Pan Card)");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref11.current) {
                    Ref11.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadFamilyPhoto = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "One photographs of Student with Parent / Guardian");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref12.current) {
                    Ref12.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadParentNRIcertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Candidate's Parents NRI certificate issued by the Embassy of the country complying the Income Tax Rules Candidate's Passport / Visa / Resident Visa");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref13.current) {
                    Ref13.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadStudentSignature = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Student Signature");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref14.current) {
                    Ref14.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadFatherSignature = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Father Signature");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref15.current) {
                    Ref15.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadMotherSignature = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Mother Signature");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref16.current) {
                    Ref16.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadGuardianSignature = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Guardian Signature");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref17.current) {
                    Ref17.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadCitizenshipCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Citizenship of the Candidate");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref18.current) {
                    Ref18.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadIncomeTaxCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Income Tax Documents required as per the Income Tax Act 1961");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref19.current) {
                    Ref19.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadPassport = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Valid Foreign Passport (Foreign Nationals / NRI Sponsorer)");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref20.current) {
                    Ref20.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadVisa = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Valid Visa Requirement (Foreign Nationals / NRI Sponsorer)");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref21.current) {
                    Ref21.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadResidentialPermit = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Valid Residential Permit in India (Foreign Nationals)");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref22.current) {
                    Ref22.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadIAUapprovedDegree = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "The Degree / Certificates of the candidates must have been recognized and approved by the Association of Indian Universities / International Association of Universities (IAU) as equivalent to the corresponding Indian Degrees / Certificates (Foreign Nationals)");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref23.current) {
                    Ref23.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };
    const UploadAffidavit = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Affidavit (NRI Sponsorer) as per MCC notification");
                formData.append('file', file);
                formData.append('registrationNo', localStorage.getItem('registrationNo'));
                await uploadMBBSandBDSregistrationDocs(formData);
                getUploadedDocuments();
                if (Ref24.current) {
                    Ref24.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const viewfile = (filedata) => {
        if (filedata.filePath) {
            window.open(process.env.REACT_APP_SERVICE_URL + "/documents/" + filedata.filePath, "_blank");
        }
        else {
            swal({
                title: "File Not Found.",
                icon: 'info'
            });
        }
    };

    const deletefile = async (filedata) => {
        try {
            setspinner(true);
            await deleteMBBSandBDSregistrationUploadedDocs({ filedata, registrationNo: localStorage.getItem('registrationNo') });
            getUploadedDocuments();
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    const SaveandNext = () => {
        try {
            const uploadedDocNames = [];
            uploadedDoc.forEach((object) => {
                uploadedDocNames.push(object.documentName);
            });
            const areAllDocsUploaded = requiredDoc.every(doc => uploadedDocNames.includes(doc));
            if (areAllDocsUploaded) {
                navigate("/downloadAllDeclarationsinPDF");
            }
            else {
                swal({
                    title: "Please Upload All Required Documents.",
                    icon: 'info'
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                {/*   <Row className='mt-4' style={{ width: "100%" }}>
                    <Col>
                        <Button variant="primary" type="button"
                            style={{
                                alignItems: 'center',
                                width: '150px',
                                borderRadius: '50px',
                                padding: '10px 10px',
                            }}
                            onClick={goToDashboard}
                        >
                            Go To Dashboard
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                        <Button variant="primary" type="button"
                            style={{
                                alignItems: 'center',
                                width: '150px',
                                borderRadius: '50px',
                                padding: '10px 10px',
                            }}
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row> */}
            </Container>
            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-3 mb-4' >
                    <Card style={{ width: "100%", padding: 16, backgroundColor: "#EADDCA" }}>
                        <Form>
                            <Row className="mt-2">
                                <Col><strong>Documents Upload</strong></Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col style={{ color: "red" }}>
                                    Note:<span style={{ paddingLeft: "5px" }}>Photo should be in jpg / jpeg / png format.</span><br />
                                    <span style={{ paddingLeft: "40px" }}>After choosing the file, please click the upload button.</span>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Photo<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handleFileInputChange}
                                        ref={Ref1}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadPhoto} />
                                </Col>
                            </Row>
                            {/*         <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>PH Certificate</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref2}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadPHcertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Online provisional allotment letter issued by MCC<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref3}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadOnlineProvisionalAllotmentLetter} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Community Certificate<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref4}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadCommunityCertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>UG NEET 2023 Admit Card issued by NTA<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref5}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadNEETAdmitCard} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>UG NEET 2023 Result / Rank Letter issued by NTA<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref6}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadNEETResultRankLetter} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Date of Birth Certificate<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref7}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadDateofBirthCertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>12th Mark Sheet<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref8}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={Upload12thMarkSheet} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>10th Mark Sheet</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref9}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={Upload10thMarkSheet} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Transfer / Migration / Conduct Certificate<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref10}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadTMCcertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Photo Identify Proof (Aadhar Card, Voter Id, Pan Card)<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref11}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadIdProof} />
                                </Col>
                            </Row> */}
                            {/*    <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>One photographs of Student with Parent / Guardian<span className='required'>*</span></div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handleFileInputChange}
                                        ref={Ref12}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadFamilyPhoto} />
                                </Col>
                            </Row> */}
                            {/*<Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Candidate's Parents NRI certificate issued by the Embassy of the country complying the Income Tax Rules Candidate's Passport / Visa / Resident Visa</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref13}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadParentNRIcertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Student Signature</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handleFileInputChange}
                                        ref={Ref14}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadStudentSignature} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Father Signature</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handleFileInputChange}
                                        ref={Ref15}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadFatherSignature} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Mother Signature</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handleFileInputChange}
                                        ref={Ref16}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadMotherSignature} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Guardian Signature</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handleFileInputChange}
                                        ref={Ref17}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadGuardianSignature} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={12}><strong>For NRI Candidates Only</strong></Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Citizenship of the Candidate</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref18}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadCitizenshipCertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Income Tax Documents required as per the Income Tax Act 1961</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref19}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadIncomeTaxCertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Valid Foreign Passport (Foreign Nationals / NRI Sponsorer)</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref20}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadPassport} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Valid Visa Requirement (Foreign Nationals / NRI Sponsorer)</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref21}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadVisa} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Valid Residential Permit in India (Foreign Nationals)</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref22}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadResidentialPermit} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>The Degree / Certificates of the candidates must have been recognized and approved by the Association of Indian Universities /
                                        International Association of Universities (IAU) as equivalent to the corresponding Indian Degrees / Certificates (Foreign Nationals)</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref23}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadIAUapprovedDegree} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={5}>
                                    <div>Affidavit (NRI Sponsorer) as per MCC notification</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={handleFileInputChange}
                                        ref={Ref24}
                                    />
                                </Col>
                                <Col xs={12} sm={2}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadAffidavit} />
                                </Col>
                            </Row>*/}
                            <Row className="mt-3">
                                <Col xs={12} sm={12}><strong>Uploaded Documents List</strong></Col>
                            </Row>
                            {
                                uploadedDoc.map((data, index) => (
                                    <Row className="mt-2" key={data._id}>
                                        <Col xs={6} sm={2}>{index + 1}</Col>
                                        <Col xs={6} sm={8}>{data.documentName}</Col>
                                        <Col xs={6} sm={1}>
                                            <input
                                                type="button"
                                                value="view"
                                                onClick={() => viewfile(data)}
                                            />
                                        </Col>
                                        <Col xs={6} sm={1}>
                                            <input
                                                type="button"
                                                value="delete"
                                                onClick={() => deletefile(data)}
                                            />
                                        </Col>
                                    </Row>
                                ))
                            }
                            <Row className='mt-3'>
                                <Col style={{ textAlign: "center" }}>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        style={{ alignItems: 'center', width: '150px' }}
                                        className="w-70"
                                        onClick={SaveandNext}
                                    >
                                        Save & Next
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default MBBSandBDSstudentRegistrationDocumentsUpload