import React from 'react';
import { Form, Button, Container, Row, Col, Card, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Spinner from './Spinner';
import { updateApplicationPosition, getOpen_Close_ApplicationStatus } from './services';
import swal from 'sweetalert';

function ApplicationController() {
    const [applicationName, setApplicationName] = React.useState('');
    const [applicationPosition, setApplicationPosition] = React.useState('');
    const [spinner, setspinner] = React.useState(false);
    const [status, setstatus] = React.useState([]);
    const navigate = useNavigate();

    const sumbit = async () => {
        setspinner(true);
        await updateApplicationPosition(
            {
                "applicationName": applicationName,
                "applicationPosition": applicationPosition
            }
        );
        setspinner(false);
        if (applicationPosition === "Close") {
            swal({
                title: `${applicationName} Closed.`,
                icon: 'success'
            });
        }
        if (applicationPosition === "Open") {
            swal({
                title: `${applicationName} Open.`,
                icon: 'success'
            });
        }
        setApplicationName('');
        setApplicationPosition('');
        getApplicationStatus();
    };

    const getApplicationStatus = async () => {
        try {
            const result = await getOpen_Close_ApplicationStatus();
            setstatus(result);
        } catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        getApplicationStatus();
    }, []);

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>

            <Container className=" justify-content-center align-items-center" style={{ height: 'auto' }}>
                <Row className='mt-3'>
                    <Col className='mb-2' style={{ textAlign: 'center', color: "#ba0af0" }}>
                        <h5>Applications Open and Close</h5>
                    </Col>
                </Row>
                <Card style={{ width: '40%', height: 'auto', padding: "25px", marginLeft: "30%", marginRight: "30%" }}>
                    <Container>
                        <Form>
                            <Row className="mt-3">
                                <strong>Select Application Name</strong>
                            </Row>
                            <Row className="mt-3">

                                <Form.Select name='applicationName' onChange={(e) => setApplicationName(e.target.value)} value={applicationName}>
                                    <option value="">Select Application Name</option>
                                    <option value="AHS Application">UG / PG Programme (Non-NEET) Application to Constituent Units</option>
                                    <option value="Phd Application">Phd Application</option>
                                    <option value="Fellowship Application">Fellowship Application</option>
                                </Form.Select>

                            </Row>
                            <Row className="mt-3">
                                <strong>Select Application Status</strong>
                            </Row>
                            <Row className="mt-3">

                                <Form.Select name='applicationPosition' onChange={(e) => setApplicationPosition(e.target.value)} value={applicationPosition}>
                                    <option value="">Select Application Status</option>
                                    <option value="Open">Open</option>
                                    <option value="Close">Close</option>
                                </Form.Select>

                            </Row>
                            <Row className='mt-3' style={{ justifyContent: "center" }}>
                                <Button
                                    style={{ width: "100px" }}
                                    type='button'
                                    onClick={sumbit}
                                >Submit</Button>
                            </Row>
                        </Form>
                    </Container>
                </Card>
            </Container>
            {status.length > 0 &&
                <Card style={{ width: "80%", margin: "30px 10% 50px 10%" }}>
                    <Table striped bordered hover>
                        <thead>
                            <tr style={{ textAlign: 'center' }}>
                                <th>S.no</th>
                                <th>Application</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {status.map((obj, index) => (
                                <tr
                                    key={obj._id}
                                    style={{ textAlign: 'center' }}>
                                    <td>{index + 1}</td>
                                    <td>{obj.applicationName === "AHS Application" ? "UG / PG Programme (Non-NEET) Application to Constituent Units" : obj.applicationName}</td>
                                    <td
                                        style={{
                                            color: obj.applicationPosition === 'Open' ? '#1EEA10' : '#EA1015',
                                            fontWeight: 'bold'
                                        }}
                                    >{obj.applicationPosition}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </Table>
                </Card>
            }
        </>
    )
}

export default ApplicationController