import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Form, Button, Container, Row, Col, Card, Table, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import SwipeableViews from 'react-swipeable-views';
import * as XLSX from 'xlsx';
import Spinner from './Spinner';
import {
    getAllEDPapplications,
    downloadEDPapplicationForm,
    sentEDPapplicationRemark,
    EDPapplicationAccepted,
    EDPapplicationRejected,
    EDPapplicationNotEligible,
    exportEDPapplications
} from './services';

function EDP_FellowshipAdmissionController() {
    const navigate = useNavigate();
    const [spinner, setspinner] = React.useState(false);
    const [applications, setApplications] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [pageIndex, setPageIndex] = React.useState(0);
    const [isPdf, setIsPdf] = React.useState(false);
    const [filePath, setFilePath] = React.useState('');
    const [showRemarkModal, setshowRemarkModal] = React.useState(false);
    const [remark, setremark] = React.useState('');

    const downloadXL = async () => {
        try {
            setspinner(true);
            const data = await exportEDPapplications();
            const addfilepath = data.result.map((obj) => {
                if (obj.documents.length > 0) {
                    obj.documents.map((file) => {
                        file.filePath = `${process.env.REACT_APP_SERVICE_URL}/documents/` + file.filePath;
                        return file;
                    })
                }
                return obj;
            });
            const xldata = addfilepath.map((object) => {
                if (object.documents.length > 0) {
                    const keyValues = object.documents.reduce((acc, curr) => {
                        acc[curr.documentName] = curr.filePath;
                        return acc;
                    }, {});
                    return { ...object, ...keyValues };
                }
                return object;
            });

            const academicArray = xldata.map((object) => {
                var studMarks = {};
                if (object.academicQualification.length > 0) {
                    object.academicQualification.forEach((mark, index) => {
                        var keys = Object.keys(mark);

                        keys.forEach((key) => {
                            studMarks[key + '_' + index] = mark[key];

                        });
                    })
                    object = { ...object, ...studMarks };
                }
                return object;
            });
            const finalData = academicArray.map((object) => {
                var studMarks = {};
                if (object.experience.length > 0) {
                    object.experience.forEach((mark, index) => {
                        var keys = Object.keys(mark);

                        keys.forEach((key) => {
                            studMarks[key + '_' + index] = mark[key];

                        });
                    })
                    object = { ...object, ...studMarks };
                }
                return object;
            });
            finalData.forEach(object => {
                delete object.academicQualification;
                delete object.documents;
                delete object.experience;
            });
            const worksheet = XLSX.utils.json_to_sheet(finalData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const fileBuffer = await XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

            const blob = new Blob([fileBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "EDP / Fellowship Applications.xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        var role = localStorage.getItem('role');
        if (role === 'admin') {
            GetAllEDPApplications();
        }
        else {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/adminlogin');
            localStorage.clear();
        }
    }, []);

    const GetAllEDPApplications = async () => {
        const result = await getAllEDPapplications();
        setApplications(result);
    };

    const downloadEDPapplication = async (data) => {
        try {
            setspinner(true);
            const response = await downloadEDPapplicationForm({ 'id': data._id });
            const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', data.applicationNo + '.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    }

    const viewApplication = async (_id) => {
        const copyApplications = [...applications];
        const selectedIndex = copyApplications.findIndex((application) => application._id === _id);
        setApplications(copyApplications);
        setPageIndex(selectedIndex);
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
        setFilePath('');
        GetAllEDPApplications();
    };

    const viwdocument = (file) => {
        if (file.filePath) {
            const fileNameArray = file.filePath.split('.');
            const extension = fileNameArray[fileNameArray.length - 1].toLowerCase();
            setFilePath(process.env.REACT_APP_SERVICE_URL + '/documents/' + file.filePath);
            setIsPdf(extension === 'pdf');
        }
    };

    const onNext = () => {
        setPageIndex((prevIndex) => {
            if (prevIndex < applications.length - 1) {
                return prevIndex + 1;
            }

            return prevIndex;
        });
        setFilePath('')
    };

    const onPrevious = () => {
        setPageIndex((prevIndex) => {
            if (prevIndex > 0) {
                return prevIndex - 1;
            }

            return prevIndex;
        });
        setFilePath('')
    };

    const rejected = async () => {
        try {
            const id = applications[pageIndex]._id;
            await EDPapplicationRejected({ "_id": id });
            onNext();
        }
        catch (err) {
            console.log(err);
        }
    };

    const notEligible = async () => {
        try {
            const id = applications[pageIndex]._id;
            await EDPapplicationNotEligible({ "_id": id });
            onNext();
        }
        catch (err) {
            console.log(err);
        }
    };


    const verified = async () => {
        try {
            const id = applications[pageIndex]._id;
            await EDPapplicationAccepted({ "_id": id });
            onNext();
        }
        catch (err) {
            console.log(err);
        }
    };

    const CloseRemarkModal = () => {
        setremark('');
        setshowRemarkModal(false);
    };

    const remarkSubmit = async () => {
        try {
            const mailid = applications[pageIndex].mailId;
            const id = applications[pageIndex]._id;
            const result = await sentEDPapplicationRemark({ "remark": remark, "id": id, "mailid": mailid });
            swal({
                title: "Remark Mail Sent Successfully.",
                icon: 'success'
            });
            CloseRemarkModal();
            onNext();
        }
        catch (err) {
            console.log(err)
        }
    };


    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container className=" justify-content-center align-items-center" style={{ height: 'auto' }}>
                <Row className='mt-3'>
                    <Col className='mb-2' style={{ textAlign: 'center', color: "#ba0af0" }}>
                        <h5>EDP and Fellowship Applications</h5>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='mb-2' style={{ textAlign: 'end' }}>
                        <Button
                            style={{ width: '150px' }}
                            type='primary'
                            onClick={downloadXL}
                        >Export as Excel</Button>
                    </Col>
                </Row>
                <Table style={{ backgroundColor: 'white', borderRadius: '10px' }} striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>S.No</th>
                            <th style={{ textAlign: 'center', }}>Application No</th>
                            <th style={{ textAlign: 'center', }}>Name</th>
                            <th style={{ textAlign: 'center', }}>Application Status</th>
                            <th style={{ textAlign: 'center', }}>Remark</th>
                            <th style={{ textAlign: 'center', }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            applications.map((obj, index) => (

                                <tr key={obj._id}>
                                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                    <td style={{ textAlign: 'center' }}>{obj.applicationNo}</td>
                                    <td style={{ textAlign: 'center' }}>{obj.name}</td>
                                    <td style={{ textAlign: 'center' }}>{obj.applicationStatus}</td>
                                    <td style={{ textAlign: 'center', width: '500px' }}>{obj.remark}</td>
                                    <td style={{ textAlign: 'center', width: '135px' }}>
                                        <Row>
                                            <Col>
                                                <Icon.Eye
                                                    color="blue"
                                                    size={25}
                                                    type="button"
                                                    onClick={() => viewApplication(obj._id)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="View"
                                                />
                                            </Col>
                                            <Col>
                                                <Icon.Download
                                                    color="blue"
                                                    size={25}
                                                    type="button"
                                                    onClick={() => downloadEDPapplication(obj)}
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Download Form"
                                                />
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <Modal
                    show={showModal}
                    fullscreen={true}
                    onHide={() => closeModal()}
                    style={{ height: window.innerHeight }}
                >
                    <Modal.Header closeButton>
                        <Container>
                            <Row>
                                <Col style={{ color: 'blue', textAlign: 'center', fontWeight: 'bold' }}>
                                    APPLICATION VERIFICATION
                                    <Button
                                        style={{ marginLeft: '50px' }}
                                        onClick={() => setshowRemarkModal(true)}
                                    >Remark</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Header>
                    <Modal.Body>
                        <SwipeableViews index={pageIndex}>
                            {
                                applications.map((formdata) => (
                                    <Row key={formdata._id} style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Col style={{ height: window.innerHeight, overflow: 'auto' }} md={6}>

                                            <Row className='mt-3'>
                                                <Col><strong>Application No</strong></Col>
                                                <Col>{formdata.applicationNo}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Name</strong></Col>
                                                <Col>{formdata.name}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Institution</strong></Col>
                                                <Col>{formdata.institute}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Programme</strong></Col>
                                                <Col>{formdata.programme}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Department</strong></Col>
                                                <Col>{formdata.department}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Gender</strong></Col>
                                                <Col>{formdata.gender}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Date of Birth</strong></Col>
                                                <Col>{formdata.dateofbirth}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Community</strong></Col>
                                                <Col>{formdata.community}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Nationality</strong></Col>
                                                <Col>{formdata.nationality}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Religion</strong></Col>
                                                <Col>{formdata.religion}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Name of Father / Guardian</strong></Col>
                                                <Col>{formdata.fatherName}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Residential Address</strong></Col>
                                                <Col>{formdata.residentialaddress}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Office/Institution Address</strong></Col>
                                                <Col>{formdata.officeaddress}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Aadhar No</strong></Col>
                                                <Col>{formdata.aadharNo}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Mobile No</strong></Col>
                                                <Col>{formdata.mobileNo}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Mail Id</strong></Col>
                                                <Col>{formdata.mailId}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Official Mail Id</strong></Col>
                                                <Col>{formdata.officialmailid}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Official Phone No</strong></Col>
                                                <Col>{formdata.officialphoneno}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>Academic Qualification</strong></Col>
                                            </Row>

                                            <Table striped bordered hover className="mt-3 text-center">
                                                <thead>
                                                    <tr>
                                                        <th>Qualification</th>
                                                        <th>School/College</th>
                                                        <th>Board/University</th>
                                                        <th>Month & Year of Passing</th>
                                                        <th>subject</th>
                                                        <th>% of Mark</th>
                                                        <th>Class/Rank</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        formdata.academicQualification.map((obj) => (
                                                            <tr key={obj._id}>
                                                                <td>{obj.qualification}</td>
                                                                <td>{obj.school_college}</td>
                                                                <td>{obj.board_university}</td>
                                                                <td>{obj.month_and_yearofpassing}</td>
                                                                <td>{obj.subject}</td>
                                                                <td>{obj.percentage_of_mark}</td>
                                                                <td>{obj.class_rank}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>

                                            <Row className='mt-3'>
                                                <Col><strong>Professional/Teaching Experience</strong></Col>
                                            </Row>

                                            <Table striped bordered hover className="mt-3 text-center">
                                                <thead>
                                                    <tr>
                                                        <th>Designation</th>
                                                        <th>Institution</th>
                                                        <th>From Date</th>
                                                        <th>To Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        formdata.experience.map((obj) => (
                                                            <tr key={obj._id}>
                                                                <td>{obj.designation}</td>
                                                                <td>{obj.institution}</td>
                                                                <td>{obj.fromDate}</td>
                                                                <td>{obj.toDate}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>

                                            <Row className='mt-3'>
                                                <Col><strong>The subject in which the candidate has qualified for the</strong></Col>
                                                <Col>{formdata.qualifiedDegree}</Col>
                                            </Row>


                                            <Row className='mt-3'>
                                                <Col><strong>The above master's Degree was awarded by</strong></Col>
                                                <Col>{formdata.degreeAwardedBy}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>If Others, Mention degree awarded by</strong></Col>
                                                <Col>{formdata.othersDegreeAwardedBy}</Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col><strong>uploaded Documents List</strong></Col>
                                            </Row>

                                            {
                                                formdata.documents.map((obj) => (
                                                    <Row key={obj._id} className='mt-3'>
                                                        <Col>{obj.documentName}</Col>
                                                        <Col>
                                                            <Icon.Eye
                                                                color="blue"
                                                                size={25}
                                                                type="button"
                                                                onClick={() => viwdocument(obj)}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="View Document"
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))
                                            }

                                        </Col>
                                        <Col style={{ height: window.innerHeight, overflow: 'auto' }} md={6}>

                                            {
                                                isPdf ? <iframe src={filePath} width="630px" height="630px" />
                                                    : <img src={filePath} width="630px" height="630px" alt='Image' />
                                            }

                                        </Col>

                                    </Row>
                                ))
                            }
                        </SwipeableViews>
                    </Modal.Body>
                    <Container>
                        <Row className='mb-2 mt-2'>
                            <Col>
                                <Button type='primary' onClick={onPrevious}>Back</Button>
                            </Col>
                            <Col>
                                <Button type='primary' onClick={onNext}>Next</Button>
                            </Col>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: 'red' }} onClick={rejected}>Rejected</Button>
                            </Col>
                            <Col>
                                <Button type='primary' style={{ backgroundColor: 'brown' }} onClick={notEligible}>Not Eligible</Button>
                            </Col>
                            <Col>
                                <Button style={{ backgroundColor: 'green' }} type='primary' onClick={verified}>Verify & Accept</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
                <Modal
                    show={showRemarkModal}
                    onHide={CloseRemarkModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Remark</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={remark}
                                    onChange={(e) => setremark(e.target.value)}
                                />
                            </Form.Group>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={remarkSubmit}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    )
}

export default EDP_FellowshipAdmissionController